import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { Routes } from './routes';
import { Observable, of, Subject } from 'rxjs';
import { Attachements, ICreateDataResource, ResourceError } from '../interfaces';
import { MyHttpHandler } from './custom-http-handler';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { FINDINGS_MESSAGE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class UploadDatasetService {
  @Input() isHidemission!: boolean;
  downloadList: any[] = [];

  constructor(private httpClient: HttpClient) {}

  getCredentials = (
    resourceType: string,
    missionId: string,
    projectId: string,
  ): Observable<any> => {
    let createDataResUrl =
      Routes.GET_DATA_RESOURCE +
      '/' +
      'credentials?mission_id=' +
      missionId +
      '&resource_type=' +
      resourceType +
      '';
    if (projectId)
      createDataResUrl = createDataResUrl + '&project_id=' + projectId;

    return this.httpClient.get(createDataResUrl).pipe(map((res) => res));
  };

  createDataResources(
    data: ICreateDataResource,
  ): Observable<ICreateDataResource[]> {
    return this.httpClient.post<ICreateDataResource[]>(
      Routes.GET_DATA_RESOURCE,
      data,
    );
  }

  deleteDataResources(dataId: string): Observable<ICreateDataResource[]> {
    return this.httpClient.delete<ICreateDataResource[]>(
      Routes.GET_DATA_RESOURCE + '/' + dataId,
    );
  }
  getDataResourcesById(
    dataId: string,
    preSignedUrl: boolean = false,
  ): Observable<ICreateDataResource[]> {
    return this.httpClient.get<ICreateDataResource[]>(
      `${Routes.GET_DATA_RESOURCE}/${dataId}?pre_signed_url=${preSignedUrl}`,
    );
  }

  getDataResource({
    projectId,
    preSignedUrl,
    workspaceId,
  }: {
    projectId: string | null;
    resourceType?: string;
    preSignedUrl: boolean;
    workspaceId: string;
  }): Observable<any> {
    let queryParams = `?pre_signed_url=${preSignedUrl}&pageLimit=10000`;
    if (projectId) queryParams += `&projectId=${projectId}`;
    if (workspaceId) queryParams += `&workspaceId=${workspaceId}`;
    return this.httpClient.get<any>(Routes.GET_DATA_RESOURCE + queryParams);
  }

  async getArrayBufferFromPreSignedUrl(
    preSignedUrl: string,
    idx: number,
    subjectArray: Subject<number>[],
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', preSignedUrl, true);
      xhr.responseType = 'arraybuffer';
      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          subjectArray[idx].next(percentComplete);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error('Request failed'));
        }
      };
      xhr.onerror = () => {
        reject(new Error('Request failed'));
      };
      xhr.send();
    });
  }

  async uploadFile(
    file: File,
    resourceType: string,
    siteId: string,
    missionId: string,
    workspaceId: string,
    projectId: string,
    isLastFile?: boolean,
  ) {
    const attachements: Attachements = {original: [], duplicate: []};

    try {
      const s3CredentialsInfo = await firstValueFrom(
        this.getCredentials(resourceType, missionId, projectId),
      );

      const httpHandler = new MyHttpHandler();
      const s3Client = new S3Client({
        region: s3CredentialsInfo.credentials.region,
        credentials: {
          accessKeyId: s3CredentialsInfo.credentials.accessKeyId,
          secretAccessKey: s3CredentialsInfo.credentials.secretAccessKey,
          sessionToken: s3CredentialsInfo.credentials.sessionToken,
        },
        requestHandler: httpHandler,
      });

      httpHandler.onProgress$.subscribe((progress) => {
        const uploadedFilePercentage =
          (progress.progressEvent.loaded / progress.progressEvent.total) * 100;
        // Update progress UI if needed
      });

      httpHandler.onComplete$.subscribe((complete) => {
        const etag = complete.etag.match(/etag: "([^"]+)"/);
        const etagValue = etag ? etag[1] : '';
        const input = {
          Body: file,
          Bucket: s3CredentialsInfo.credentials.s3Bucket,
          ETag: etagValue,
          Key: s3CredentialsInfo.credentials.s3Prefix + '/' + file.name,
        };

        this.createDataResource(
          s3CredentialsInfo,
          [input],
          missionId,
          projectId,
          siteId,
          workspaceId,
        ).then((resp) => {
          if (resp?._id) {
            attachements.original.push(resp);
          }
        });
      });

      const param = {
        Body: file,
        Bucket: s3CredentialsInfo.credentials.s3Bucket,
        Key: s3CredentialsInfo.credentials.s3Prefix + '/' + file.name,
      };

      await s3Client.send(new PutObjectCommand(param));
      return attachements;
    } catch (error) {
      const duplicateAttachments = [];
      const {
        error: { message, meta },
      } = error as ResourceError;

      if (message.startsWith(FINDINGS_MESSAGE.ATTACHMENT_DUPLICATE_DATA)) {
        if (meta.resourceId && isLastFile) attachements.duplicate.push(meta.resourceId);

        return attachements;
      } else {
        throw new Error(FINDINGS_MESSAGE.FINDINGS_ATTACHMENT_UPLOAD_ERROR);
      }
    }
  }

  async createDataResource(
    credInfo: any,
    file: any,
    missionId: string,
    projectId: string,
    siteId: string,
    workspaceId: string,
  ) {
    let respFiles: any = [];
    let counter = 0;

    for (const element of file) {
      respFiles.push({
        s3Key: element.Key,
        s3Bucket: element.Bucket,
        sizeBytes: element.Body.size,
        extension: element.Body.name.substr(element.Body.name.indexOf('.') + 1),
        s3Etag: element.ETag,
        origUploadRelativePath: element.tag,
      });
    }

    const payload: ICreateDataResource | any = {
      workspaceId: workspaceId,
      type: 'data_resource',
      projectId: projectId,
      missionId: missionId,
      siteId: siteId,
      resourceType: credInfo.credentials.s3Prefix.split('/').pop() || '',
      tags: file[counter].tagList ? file[counter].tagList : [],
      metadata: {},
      storage: {
        storageType: 's3',
        files: respFiles,
      },
    };
    if (!payload.projectId) delete payload.projectId;
    if (!payload.siteId) delete payload.siteId;

    try {
      let response: any = await lastValueFrom(
        this.createDataResources(payload),
      );
      counter++;

      return response;
    } catch (error) {
      if (error) {
        throw new Error(FINDINGS_MESSAGE.FINDINGS_ATTACHMENT_UPLOAD_ERROR);
      }
    }
  }

  async previewFile(fileUrl: string) {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const file = new File([blob], 'document.pdf', {
        type: 'application/pdf',
      });
      const newBlob = new Blob([file], { type: 'application/pdf' });
      const url = URL.createObjectURL(newBlob);
      window.open(url, '_blank');
    } catch (error) {
        console.log(error)
    }
  }
}