import { Component, Input } from '@angular/core';
import { IAccordionDataProperties } from '../../../interfaces';
import { IAssetHierarchy } from 'projects/digital-twin/src/app/interfaces';

@Component({
  selector: 'app-custom-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss'],
})
export class CustomAccordionComponent {
    @Input() data: IAccordionDataProperties[]
    @Input() label: string
    @Input() heirarchy?: IAssetHierarchy
    accCollapsedStatus = true
    heirarchyString: string = ''

    ngOnInit() {
      this.makeHeirarchyString()
    }

    makeHeirarchyString() {
      if (!this.heirarchy) return;
      const heirarchyList: string[] = Object.values(this.heirarchy).reverse()
      heirarchyList.forEach(val => this.heirarchyString = this.heirarchyString + ' / ' + val)
      this.heirarchyString = this.heirarchyString.substring(2)
    }

}
