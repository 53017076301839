<div class="listing-wrapper" #findingsElement>
  @if (findingsListing.length) {
    <div class="group-by-wrapper">
      <div class="group-by-btn" (click)="toggleGroupBy()">
        <span class="group-by-text">Group by</span>
        <img
          class="group-by-img"
          [src]="'/icon/vector.svg' | assetUrl"
          alt="vector-group-by"
        />
        @if (groupByDropdown) {
          <div class="group-by-container">
            @for (option of GROUP_BY_OPTIONS; track $index) {
              <div
                class="group-by-menu"
                [ngClass]="{ selected: activeGroupByOption === option }"
                (click)="setActiveGroupBy(option)"
              >
                {{ option }}
              </div>
            }
          </div>
        }
      </div>
    </div>
    @switch (activeGroupByOption) {
      @case (FINDINGS_GROUP.ASSET) {
        @for (finding of listingGroup; track $index) {
          <div class="listing-item">
            <div class="listing-main">
              <div class="listing-info">
                <div class="listing-name-section">
                  <img
                    [src]="'/icon/arrow-right.svg' | assetUrl"
                    alt="dropdown"
                  />
                  <div class="listing-name">
                    <span class="listing-name-text">{{
                      finding[0].assetName
                    }}</span>
                  </div>
                </div>
                <div class="listing-description">
                  <img
                    class="coordinates-icon"
                    [src]="'/images/location.svg' | assetUrl"
                    alt="location"
                  />
                  <span>{{
                    finding[0].assetLatitude + ", " + finding[0].assetLongitude
                  }}</span>
                </div>
                @for (find of finding; let i = $index; track find._id) {
                  <div
                    class="listing-description finding"
                    (click)="handleFinding(find._id)"
                  >
                    <div
                      class="severity"
                      [ngClass]="find.severityLabel.name | toKebabCase"
                    ></div>
                    <span class="listing-name-text"
                      >{{ find.findingLabel.name }} -
                    </span>
                    <span class="listing-name-text">{{
                      find.severityLabel.name
                    }}</span>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      }
      @case (FINDINGS_GROUP.FINDINGS) {
        @for (finding of listingGroup; track $index) {
          <div class="listing-item">
            @for (find of finding; let i = $index; track find._id) {
              <div
                class="listing-main finding"
                (click)="handleFinding(find._id)"
              >
                <div class="listing-info">
                  @if (i === 0) {
                    <div class="listing-name-section">
                      <img
                        [src]="'/icon/arrow-right.svg' | assetUrl"
                        alt="dropdown"
                      />
                      <div class="listing-name">
                        <span class="listing-name-text">{{
                          find.findingLabel.name
                        }}</span>
                      </div>
                    </div>
                  }
                  <div class="listing-description">
                    <div
                      class="severity"
                      [ngClass]="find.severityLabel.name | toKebabCase"
                    ></div>
                    <span class="listing-name-text"
                      >{{ find.severityLabel.name }} -
                    </span>
                    <span class="listing-name-text">{{ find.assetName }}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      }
      @case (FINDINGS_GROUP.PRIORITY) {
        @for (priority of listingGroup; track $index) {
          <div class="listing-item">
            @for (prior of priority; let i = $index; track prior._id) {
              <div class="listing-main">
                <div class="listing-info">
                  @if (i === 0) {
                    <div class="listing-name-section">
                      <img
                        [src]="'/icon/arrow-right.svg' | assetUrl"
                        alt="dropdown"
                      />
                      <div
                        class="severity"
                        [ngClass]="prior.severityLabel.name | toKebabCase"
                      ></div>
                      <div class="listing-name">
                        <span class="listing-name-text">{{
                          prior.severityLabel.name
                        }}</span>
                      </div>
                    </div>
                  }
                  <div
                    class="listing-description finding"
                    (click)="handleFinding(prior._id)"
                  >
                    <span class="listing-name-text"
                      >{{ prior.assetName }} -
                    </span>
                    <span class="listing-name-text">{{
                      prior.findingLabel.name
                    }}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      }
      @default {
        @for (finding of findingsListing; track finding._id) {
          <div
            class="listing-item"
            [ngClass]="{ 'disabled-card': isCardDisabled(finding) }"
          >
            <div class="listing-main">
              <div class="listing-info">
                <div class="listing-name-section">
                  <a
                    class="listing-name finding-text"
                    (click)="handleFinding(finding._id)"
                  >
                    <span class="listing-name-text">{{ finding.name }} </span>
                  </a>
                  <a
                    class="delete-btn"
                    (click)="onClickFindingToRemove(finding)"
                    ><img
                      [src]="'/icon/delete.svg' | assetUrl"
                      alt="Remove Findings"
                  /></a>
                </div>
                <div class="description-container">
                  <a
                  class="listing-description"
                  (click)="handleFinding(finding._id)"
                >
                  <div class="d-flex gap-2">
                    <div>
                      <div
                        class="severity"
                        [ngClass]="
                          finding.severityLabel?.name
                            ?.split(' ')
                            ?.join('-')
                            ?.toLowerCase()
                        "
                      ></div>
                    </div>
                    <p class="listing-name-text list-name-color">
                      <span></span> {{ finding.findingLabel.name }}
                      <span class="listing-name-text">
                        -&nbsp;
                        {{ finding.severityLabel?.name ?? "Unknown" }}</span
                      >
                    </p>
                  </div>
                </a>
                  <div class="listing-description">
                    <span class="listing-name-text list-name-color">{{
                      finding.assetName
                    }}</span>
                  </div>
                </div>
                <div class="resource-types">
                  @for (type of finding?.resourceType; track $index) {
                    <div class="resource-type">
                      <span
                        [ngClass]="{
                          'selected-type': currentMode === type.toLowerCase(),
                          'default-type': currentMode !== type.toLowerCase()
                        }"
                        class="type-name-text"
                        >{{ type }}</span
                      >
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      }
    }
  } @else {
    <div class="d-flex justify-content-center align-items-center">
      {{ noRecordFound }}
    </div>
  }
</div>
<app-custom-confirm-popup
  #findingsDeleteConfirmDialog
  propertyWidth="300px"
  [propertyModalConfig]="deleteFindingDialogConfig"
  (confirmAction)="confirmDeleteFindings($event)"
>
</app-custom-confirm-popup>
