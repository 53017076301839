import { Injectable } from "@angular/core";
import { UserSettings } from "projects/digital-twin/src/app/shared/types";

function getLocalStorage(): Storage {
  return localStorage;
}

export const USER_SETTINGS_KEY = 'userSettings';

const defaultState: UserSettings = {
  componentToggles: {}
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private get _localStorage() {
    return getLocalStorage();
  }

  private get _userSettings(): UserSettings {
    let settings = {} as UserSettings;
    try {
      settings = JSON.parse(this._localStorage.getItem(USER_SETTINGS_KEY) || '{}') || defaultState;
    } catch(e) {
      console.log(e);
    }
    return settings;
  }

  private set _userSettings(settings: UserSettings) {
    try {
      this._localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(settings));
    } catch (e) {
      console.log(e);
    }
  }
  
  constructor() {
    if (!this._userSettings) {
      try {
        this._localStorage.setItem(USER_SETTINGS_KEY, '{}');
      } catch (e) {
        console.log(e);
      }
    }
  }

  getUserSettings() {
    return this._userSettings;
  }

  updateUserSettings(settings: Partial<UserSettings>): void {
    this._userSettings = {
      ...this._userSettings,
      ...settings
    };
  }
  
  toggleComponentVisibility(id: string, isVisible: boolean): void {
    const componentToggles = {
      ...this._userSettings.componentToggles,
      [id]: isVisible,
    };
    this._userSettings = { ...this._userSettings, componentToggles };
  }

  getComponentVisibility(id: string) {
    if (this._userSettings.componentToggles?.[id] === undefined)
      return true;
    return this._userSettings.componentToggles?.[id];
  }
}