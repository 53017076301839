<div class="w-100">
  <div class="w-100 d-flex justify-content-between">
    <div></div>
    <button class="btn add-item-property">
      <span (click)="openPropertyModel()"><i class="fa fa-plus" aria-hidden="true"></i> Add Property </span>
    </button>
  </div>
  <form [formGroup]="propertyFormGroup">
    @for (property of properties | keyvalue; track $index) {
    @switch (property.key) {
    @case (canvasComponentConst.SCENEOBJ_PROPERTY_TYPE_EQUPIMENT_REFERENCE_POINT) {
    <div class="input-item-property">
      <span class="reference-item-title">
        <span>{{createDefectPropertiesConst.REFERENCE_POINT}}</span>
        <span><a href="javascript:void(0)" class="clear-btn" (click)="clearProperty(property.key)">clear</a></span>
      </span>
      <div class="mt-1 ip-form-control ip-form-custom ip-form-icon"
        [ngClass]="{'active': selectedPropertyKey === property.key}" (click)="onPropertyClick(property.key)">
        {{property.value}}</div>
    </div>
    }
    @case (canvasComponentConst.SCENEOBJ_PROPERTY_TYPE_EQUPIMENT_REFERENCE_VECTOR) {
    <div class="input-item-property mt-1">
      <span class="reference-item-title">
        <span>{{createDefectPropertiesConst.REFERENCE_DIRECTION}}</span>
        <span><a href="javascript:void(0)" class="clear-btn" (click)="clearProperty(property.key)">clear</a></span>
      </span>
      <div class="mt-1 ip-form-control ip-form-custom ip-form-icon"
        [ngClass]="{'active': selectedPropertyKey === property.key}" (click)="onPropertyClick(property.key)">
        {{property.value}}</div>
    </div>
    }
    @case (canvasComponentConst.SCENEOBJ_PROPERTY_CONFIDENCE) {
    <div class="input-item-property mt-1">
      <span class="reference-item-title">{{createDefectPropertiesConst.AI_CONFIDENCE}}</span>
      <div class="mt-1 ip-form-control ip-form-custom ip-form-icon" (click)="onPropertyClick(property.key)">
        {{property.value}}</div>

    </div>
    }
    @case (canvasComponentConst.SCENEOBJ_PROPERTY_DIM_ORDER) {
    <div class="input-item-property mt-1">
      <span class="reference-item-title">{{createDefectPropertiesConst.DIMENSION_ORDERING}}</span>
      <div class="mt-1 ip-form-control ip-form-custom ip-form-icon" (click)="onPropertyClick(property.key)">
        {{property.value}}</div>
    </div>
    }
    @case (canvasComponentConst.SCENEOBJ_PROPERTY_ANN_REF) {
        <span class="reference-item-title">{{createDefectPropertiesConst.ANNOTATED_SNAPSHOTS}}</span>
        <div class="w-100">
          <app-dropdown [options]="properties.annotation_2d_references!" [selected]="[selectedId]"
          (onSelect)="selectSnapshotId($event)">
          </app-dropdown>
        </div>
    }
    }
    }
  </form>
</div>
<app-custom-property-modal #propertyModal
  (onPropertySelect)="onPropertyTypeSelected($event)"></app-custom-property-modal>
