import { Injectable } from "@angular/core";
import * as Tiff from 'tiff';
import { BehaviorSubject, Observable } from "rxjs";
import * as THREE from 'three'
import { COLOR_SCHEME, DEFAULT_THERMAL_EXIFDATA, THERMAL_ANNOTATION } from "../constants";
import { IExifData, TemperatureType } from "../interfaces";
@Injectable({
  providedIn: 'root'
})
export class ThermalService {
  // remove measurement values:
  activeThermalSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activeThermalSubjectObservable$: Observable<any> = this.activeThermalSubject.asObservable();
  //Show Parameter measurement values:
  activeThermalParametersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activeThermalParametersObservable$: Observable<any> = this.activeThermalParametersSubject.asObservable();

  // Get array of image coordinates by pixel
  getAllImagePixelCoordinates(image: any): { x: number, y: number }[] {
    const pixelCoordinates: { x: number, y: number }[] = [];
    const width = image.width();
    const height = image.height();
    // Convert pixel coordinates to stage coordinates
    const stageCoordinates = image.getAbsolutePosition();
    // Iterate through all pixels in the image
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelX = stageCoordinates.x + x;
        const pixelY = stageCoordinates.y + y;
        pixelCoordinates.push({ x: Math.round(pixelX), y: Math.round(pixelY) });
      }
    }
    return pixelCoordinates;
  }

  async getThermalImageBytes(imageUrl: string) {
    try {
      const response = await fetch(imageUrl);
      const data = await response.arrayBuffer();
      const rawValues = Tiff.decode(data);
      return new Float32Array(rawValues?.[0].data);
    } catch (error) {
      throw false; // Re-throw the error or handle it as needed
    }
  }

  getTemperatureOfPixel(pixel: number, exifDataArg: IExifData): number | null {
    return exifDataArg ? this.rawValueToTemperature(pixel, exifDataArg) : null
  }
  
  getTemperatureOfRange(pixels: number[] | Float32Array, exifDataArg: IExifData) {
    if (!exifDataArg) {
      return {
        max: 0,
        min: 0,
        average: 0
      };
    }
    const temperatures: number[] = [];
    let minTemperature = 0;
    let maxTemperature = 0;
    let sumTemperature = 0;
    pixels.forEach((pixel) => {
      const temperatureValue = this.rawValueToTemperature(pixel, exifDataArg);
      if (!minTemperature) minTemperature = temperatureValue;
      if (temperatureValue < minTemperature) minTemperature = temperatureValue;
      if (temperatureValue > maxTemperature) maxTemperature = temperatureValue;
      if (!isNaN(temperatureValue)) sumTemperature += temperatureValue;
      temperatures.push(temperatureValue)
    })
    const avgtemperature = sumTemperature / temperatures.length;
    return {
      max: maxTemperature,
      min: minTemperature,
      average: avgtemperature,
      temperatures
    };
  }

  // verify array of coordinates by placing pixel red dot to check image covered or not -- Future Reference
  // displayCoordinatesOnStage(coordinates: { x: number; y: number }[]) {
  //     const layer = new Konva.Layer();

  //     coordinates.forEach(coord => {
  //         const circle = new Konva.Circle({
  //             x: coord.x,
  //             y: coord.y,
  //             radius: 1,
  //             fill: 'red', // Set the fill color
  //         });

  //         layer.add(circle);
  //     });

  //     // Add the layer to the stage
  //     this.canvasStage.add(layer);
  //     layer.draw();
  // }

  rawValueToTemperature(rawThermalvalue: number, exifDataArg: IExifData) {
    let exifData = { ...DEFAULT_THERMAL_EXIFDATA, ...exifDataArg }
    const emissWind = 1 - exifData.irWindowTransmission;
    const reflWind = 0;
    const h2o =
      (exifData.relativeHumidity / 100) *
      Math.exp(
        1.5587 +
        0.06939 * exifData.atmTemp -
        0.00027816 * exifData.atmTemp ** 2 +
        0.00000068455 * exifData.atmTemp ** 3,
      );

    const tau1 =
      exifData.atmTransBetaX *
      Math.exp(
        -Math.sqrt(exifData.objectDistance / 2) *
        (exifData.atmTransAlpha1 + exifData.atmTransBeta1 * Math.sqrt(h2o)),
      ) +
      (1 - exifData.atmTransBetaX) *
      Math.exp(
        -Math.sqrt(exifData.objectDistance / 2) *
        (exifData.atmTransAlpha2 + exifData.atmTransBeta2 * Math.sqrt(h2o)),
      );

    const tau2 =
      exifData.atmTransBetaX *
      Math.exp(
        -Math.sqrt(exifData.objectDistance / 2) *
        (exifData.atmTransAlpha1 + exifData.atmTransBeta1 * Math.sqrt(h2o)),
      ) +
      (1 - exifData.atmTransBetaX) *
      Math.exp(
        -Math.sqrt(exifData.objectDistance / 2) *
        (exifData.atmTransAlpha2 + exifData.atmTransBeta2 * Math.sqrt(h2o)),
      );

    const rawRefl1 =
      exifData.planckR1 /
      (exifData.planckR2 *
        (Math.exp(exifData.planckB / (exifData.reflectedTemp + 273.15)) -
          exifData.planckF)) -
      exifData.planckO;
    const rawRefl1Attn =
      ((1 - exifData.emissivity) / exifData.emissivity) * rawRefl1;

    const rawAtm1 =
      exifData.planckR1 /
      (exifData.planckR2 *
        (Math.exp(exifData.planckB / (exifData.atmTemp + 273.15)) -
          exifData.planckF)) -
      exifData.planckO;
    const rawAtm1Attn = ((1 - tau1) / exifData.emissivity / tau1) * rawAtm1;

    const rawWind =
      exifData.planckR1 /
      (exifData.planckR2 *
        (Math.exp(exifData.planckB / (exifData.irWindowTemp + 273.15)) -
          exifData.planckF)) -
      exifData.planckO;
    const rawWindAttn =
      (emissWind / exifData.emissivity / tau1 / exifData.irWindowTransmission) *
      rawWind;

    const rawRefl2 =
      exifData.planckR1 /
      (exifData.planckR2 *
        (Math.exp(exifData.planckB / (exifData.reflectedTemp + 273.15)) -
          exifData.planckF)) -
      exifData.planckO;
    const rawRefl2Attn =
      (reflWind / exifData.emissivity / tau1 / exifData.irWindowTransmission) *
      rawRefl2;

    const rawAtm2 =
      exifData.planckR1 /
      (exifData.planckR2 *
        (Math.exp(exifData.planckB / (exifData.atmTemp + 273.15)) -
          exifData.planckF)) -
      exifData.planckO;
    const rawAtm2Attn =
      ((1 - tau2) /
        exifData.emissivity /
        tau1 /
        exifData.irWindowTransmission /
        tau2) *
      rawAtm2;

    const rawObj =
      rawThermalvalue /
      exifData.emissivity /
      tau1 /
      exifData.irWindowTransmission /
      tau2 -
      rawAtm1Attn -
      rawAtm2Attn -
      rawWindAttn -
      rawRefl1Attn -
      rawRefl2Attn;
    const valToLog =
      exifData.planckR1 / (exifData.planckR2 * (rawObj + exifData.planckO)) +
      exifData.planckF;

    if (valToLog < 0) {
      throw new Error('Image seems to be corrupted');
    }
    return exifData.planckB / Math.log(valToLog) - 273.15;
  }

  getImagePixelColorData({
    temperatures,
    minTemperature,
    maxTemperature,
    colorScheme,
  } : {
    temperatures: number[] | Float32Array
    minTemperature: number
    maxTemperature: number,
    colorScheme: string,
  }) {
    const pixelColorData: string[] = [];
    temperatures.forEach((temperature) => {
      pixelColorData.push(
        this.mapTemperatureToColor({
          temperature,
          minTemperature,
          maxTemperature,
          colorScheme,
        })
      )
    })
    return pixelColorData;
  }

  mapTemperatureToColor ({
    temperature,
    minTemperature,
    maxTemperature,
    colorScheme,
  } : {
    temperature: number;
    minTemperature: number;
    maxTemperature: number;
    colorScheme: string;
  }) {
    const normalizedTemperature = (temperature - minTemperature) / (maxTemperature - minTemperature);
    switch(colorScheme) {
      case COLOR_SCHEME.ARTIC: {
        const blueValue = Math.round(255 * normalizedTemperature);
        return `rgb(0, ${blueValue}, 255)`
      };
      case COLOR_SCHEME.IRON: {
        const ironScaleValue = Math.round(127 * normalizedTemperature);
        return `rgb(${ironScaleValue},${ironScaleValue},${ironScaleValue})`;
      }
      case COLOR_SCHEME.LAVA: {
        const redValue = Math.round(255 * normalizedTemperature);
        return `rgb(255, ${redValue}, 0)`;
      }
      case COLOR_SCHEME.RAINBOW:{
        const hue = Math.round(240 * (1 - normalizedTemperature));
        const { r: red, g: green, b: blue } = new THREE.Color().setHSL(hue / 360, 1, 0.5);
        return `rgb(${Math.round(red * 255)}, ${Math.round(green * 255)}, ${Math.round(blue * 255)})`
      } 
      case COLOR_SCHEME.RAINBOW_HC: {
        const hue = Math.round(360 * (1 - normalizedTemperature));
        const { r: red, g: green, b: blue } = new THREE.Color().setHSL(hue / 360, 1, 0.5);
        return `rgb(${Math.round(red * 255)}, ${Math.round(green * 255)}, ${Math.round(blue * 255)})`
      }
      default: { //grey scale
        const grayscaleValue = Math.round(255 * normalizedTemperature);
        return `rgb(${grayscaleValue},${grayscaleValue},${grayscaleValue})`;
      }
    }
  }

  // Temperature conversion 
  temperatureConversion(celsius: number, convertTo?: TemperatureType) {
    let convertedValue = celsius;
    if (convertTo === TemperatureType.Fahrenheit) {
      convertedValue = (celsius * THERMAL_ANNOTATION.TEMPERATURE_CONVERTION_F_MUL_CONST) + THERMAL_ANNOTATION.TEMPERATURE_CONVERTION_F_SUM_CONST;
    } else if (convertTo === TemperatureType.Kelvin) {
      convertedValue = celsius + THERMAL_ANNOTATION.TEMPERATURE_CONVERTION_K_SUM_CONST;
    }
    return Math.floor(convertedValue * 10) / 10;
  }
}