import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { IDataResourceRecord, IFromAboveExif, IFromAboveViewDataResourceListResponse, IFromAboveViewDataresouece, IFromAbovecalculateImageExtents } from '../interfaces/api-response.interface';
import { IGetAllImagesIframe } from '../interfaces/iframe.interface';
import { Routes } from './routes';
import { AMPLITEL_CUSTOMER_PORTAL, AMPLITEL_ROUTES, FROM_ABOVE_VIEW_CONST, IFRAME_AUTH } from '../constants';
import { CanvasDataService, CommonService, LocalStorageService, MissionService} from './';
import * as utm from 'utm';
import { getQueryByParams } from '../utils/get-query-by-params';

@Injectable({
  providedIn: 'root',
})

export class IframeViewerService {
  sliderImages: string[] = [];
  thumbnailImages: string[] = [];
  originalImagesLoaded = false;
  thumbnailImagesLoaded = false;
  projectId: string = '';
  missionId: string = '';
  authToken: string = '';

  constructor(private httpClient: HttpClient, private missionService: MissionService, private commonService: CommonService, private localStorageService: LocalStorageService, private canvasDataService: CanvasDataService) { }

  async iframeInit(permission: boolean, routeParams: any = {}) {
    let showView = true;
    let projectId = '';
    let missionId = '';
    let siteId = '';
    let assetId = '';
    const isIframeMode = AMPLITEL_ROUTES.includes(window.location.pathname);
    if (!permission) {
      showView = false;
      return { projectId, missionId, siteId, assetId, showView };
    }
    const token = routeParams.queryParamMap.get('token') ?? '';
    projectId = (routeParams.queryParamMap.get('project_id') ?? '');
    missionId = (routeParams.paramMap.get('missionId') ?? '');
    siteId = (routeParams.queryParamMap.get('site_id') ?? '');
    assetId = (routeParams.queryParamMap.get('asset_id') ?? '');
    if (isIframeMode) {
      let digitalTwinResponse: any = (token) ? await this.getDigitalTwinDetails(token) : {};
      if (token && digitalTwinResponse) {
        projectId = digitalTwinResponse?.projectId
        missionId = digitalTwinResponse?.missionId
        siteId = digitalTwinResponse?.siteId
        assetId = digitalTwinResponse?.assetId
      }
    }
    return { projectId, missionId, siteId, assetId, showView };
  }

  private getDigitalTwinDetails = async (token: string) => {
    try {
      const response = await lastValueFrom(this.getDigitalTwinDetailsByToken(token));
      this.localStorageService.setItem(IFRAME_AUTH.AUTH_KEY, response?.token)
      this.localStorageService.setItem(IFRAME_AUTH.WORKSPACEID, response?.workspaceId)
      const siteDetails = await lastValueFrom(this.commonService.getSiteById(response?.siteId))
      const missionDetails = await lastValueFrom(this.missionService.getMissionById(response?.missionId))
      this.commonService.emitEventForTooltipData(siteDetails, missionDetails)
      return response;
    } catch (error) {
      return {};
    }
  }

  getDataResourceFromAboveView = (params: IFromAboveViewDataresouece): Observable<IFromAboveViewDataResourceListResponse> => {
    const queryParams = getQueryByParams( { projectId: params.projectId, missionId: params.missionId, resourceType: params.resource_type, tags: params.tags, pageLimit: params.pageLimit, pre_signed_url: true })
    return this.httpClient
      .get<IFromAboveViewDataResourceListResponse>(`${Routes.GET_DATA_RESOURCE}?${queryParams}`)
      .pipe(take(1));
  };

  // ************************* Recursive api call **************************
  // get all images
  async getAllImages({ projectId, siteId, assetId, missionId, resourceType, tags, pageLimit, nextCursor, isPreSignedUrl, viewerType }: IGetAllImagesIframe) {
    const foundedMissionId = missionId ?? await this.getMissionByAsset(projectId, assetId, siteId);
    let url: string = `${Routes.GET_DATA_RESOURCE}` 
    const queryParams = getQueryByParams({projectId, missionId: foundedMissionId, resourceType, tags, pageLimit})
    url = url + '?' + queryParams
    if (isPreSignedUrl) url += `pre_signed_url=${isPreSignedUrl}`;
    if (nextCursor) url += `&nextCursor=${nextCursor}`;
    this.fetchImages(url, tags.split(',')[0], viewerType);
  }

  private imageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  imageObservable$: Observable<any> = this.imageSubject.asObservable();  

  // get images from api
  private async fetchImages(url: string, tag: string, viewerType: string) {
    try {
      const response = await lastValueFrom(this.httpClient.get<any>(url));
      this.imageSubject.next( {records: response.records, resourceType: tag, viewerType: viewerType})
      if (response.meta.nextCursor) {
        const nextUrl = url.includes('nextCursor=') ? url.replace(/nextCursor=[^&]+/, `nextCursor=${response.meta.nextCursor}`) : `${url}&nextCursor=${response.meta.nextCursor}`;
        this.fetchImages(nextUrl, tag, viewerType);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  // get mission by asset & site id
  async getMissionByAsset(projectId: string, assetId: string, siteId: string) {
    const response = await firstValueFrom(this.missionService.getMission(projectId,assetId, siteId))
    const records = [...response.records];
    // Find the latest record based on the 'updatedAt' property.
    const latestMissionRec = records.length && records.reduce((latest: any, current: any) => {
      if (!latest || current?.['updatedAt'] > latest?.['updatedAt']) { return current; }
      return latest;
    }, null);
    const missionId = (latestMissionRec?._id || "");
    return missionId;
  }

  getDigitalTwinDetailsByToken = (token: string = ''): Observable<any> => {
    const queryParams: string = `token=${encodeURIComponent(token)}`;
    return this.httpClient.get<any>(`${Routes.DIGITAL_TWIN}?${queryParams}`).pipe(take(1));
  };

  getLattitudeLongitudeFromExif(metadataExifRecords: any) {
    if (!metadataExifRecords) {
      return [0, 0];
    }
    const { gpsLatitude, gpsLongitude, gpsLatitudeRef, gpsLongitudeRef } = metadataExifRecords;
    const lat = gpsLatitudeRef === 'N' ? gpsLatitude : -(Math.abs(gpsLatitude));
    const lng = gpsLongitudeRef === 'E' ? gpsLongitude : -(Math.abs(gpsLongitude));
    return [lat ?? 0, lng ?? 0];
  }

  calculateImageExtents( { metadataExifRecords, latitude, longitude } : {metadataExifRecords: IFromAboveExif, latitude: number, longitude: number}): IFromAbovecalculateImageExtents {
    if (!metadataExifRecords) {
      return {
        topLeftLat: 0,
        topLeftLon: 0,
        bottomRightLat: 0,
        bottomRightLon: 0,
      };
    }
    const gimbalYawRad = (metadataExifRecords.gimbalYawDegree * Math.PI) / FROM_ABOVE_VIEW_CONST.degreetoradian;
    const utmCord = utm.fromLatLon(latitude, longitude);
    const utmLat = utmCord.easting;
    const utmLng = utmCord.northing;
    const zoneNumber = utmCord.zoneNum;
    const zoneLetter = utmCord.zoneLetter;
    const flightAltitudeM = metadataExifRecords.flightAltitudeM ?? metadataExifRecords.relativeAltitudeMeters;
    const gsdCmPerPixel = (flightAltitudeM * metadataExifRecords.sensorWidthMM * 100) / (metadataExifRecords.focalLengthMM * metadataExifRecords.imageWidth);
    const imageWidthCm = metadataExifRecords.imageWidth * gsdCmPerPixel;
    const imageHeightCm = metadataExifRecords.imageHeight * gsdCmPerPixel;
    const dxRotatedM = ((imageWidthCm / 2) * Math.cos(gimbalYawRad) + (imageHeightCm / 2) * Math.sin(gimbalYawRad)) / 100
    const dyRotatedM = ((imageHeightCm / 2) * Math.cos(gimbalYawRad) - (imageWidthCm / 2) * Math.sin(gimbalYawRad)) / 100
    const topLeftUtm = [utmLat - dxRotatedM, utmLng + dyRotatedM];
    const bottomRightUtm = [utmLat + dxRotatedM, utmLng - dyRotatedM];
    const topLeftLatLong = utm.toLatLon(
      topLeftUtm[0],
      topLeftUtm[1],
      zoneNumber,
      zoneLetter
    );
    const topLeftLat = topLeftLatLong.latitude;
    const topLeftLon = topLeftLatLong.longitude;
    const bottomRightLatLong = utm.toLatLon(
      bottomRightUtm[0],
      bottomRightUtm[1],
      zoneNumber,
      zoneLetter
    );
    const bottomRightLat = bottomRightLatLong.latitude;
    const bottomRightLon = bottomRightLatLong.longitude;
    return {
      topLeftLat: topLeftLat,
      topLeftLon: topLeftLon,
      bottomRightLat: bottomRightLat,
      bottomRightLon: bottomRightLon,
    };
  }

  updateThumbnailImagesWithMeta(thumbnailImages: IDataResourceRecord[], originalImages: IDataResourceRecord[]) {
    for (const thumbnailImg of thumbnailImages) {
      const origImage = originalImages.find(({_id}) => _id === thumbnailImg.parentResourceId);
      if (origImage) {
        thumbnailImg.metadata = origImage.metadata;
      }
    }
  }

  processImages( {records, list} : { records: IDataResourceRecord[], list: IDataResourceRecord[] }) {
    return [...list, ...records.map((obj: IDataResourceRecord) => ({ ...obj, src: obj.storage.files[0].preSignedUrl }))] 
  }

  isCustomerPortal(token: string) {
  /**
   * Verifies whether the tenant ID in the provided JWT token belongs to the AMPLITEL_CUSTOMER_PORTAL.
   * 
   * The JWT token structure typically resembles: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiO...",
   * where the payload (middle part between two dots) contains a JSON object with fields such as:
   * - "userId": representing the user identifier (MongoDB ObjectId)
   * - "tenantId": representing the tenant identifier (MongoDB ObjectId)
   * - "iat": representing the token's issued-at timestamp
   * - "exp": representing the token's expiration timestamp
   * */
    if (!token) return false;
    const decodedToken = token.split('.')[1];
    const decodedObject = JSON.parse(atob(decodedToken))
    if (!decodedObject.tenantId) return false;
    return decodedObject.tenantId === AMPLITEL_CUSTOMER_PORTAL.CUSTOMER_ID;
  }

  getSceneObjectPrefixForCustomerPortal() {
    const asset = this.canvasDataService.getAssetList()[0];
    const assetName = asset.assetName;
    const structureNo = assetName.split("_")[1];
    return AMPLITEL_CUSTOMER_PORTAL.STRUCTURE_NAME + '_' + structureNo;
  }

}
