import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig } from '../../../interfaces';
@Component({
  selector: 'app-custom-confirm-popup',
  templateUrl: './custom-confirm-popup.component.html',
  styleUrls: ['./custom-confirm-popup.component.scss']
})
export class CustomConfirmPopupComponent {
  diplayModal: boolean = false;
  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  @Input() propertyWidth: string;
  
  openModal() {
    this.diplayModal = true;
  }

  closeModal() {
    this.diplayModal = false;
  }

  onConfirmClick() {
    this.confirmAction.emit(true);
  }
  onCancelClick() {
    this.confirmAction.emit(false);
  }
}
