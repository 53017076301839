import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  effect,
} from '@angular/core';
import {
  API_MESSAGE_EVENTS,
  IRESOURCE_TAG,
  STAGE_2D_CANVAS_COMMON_VIEW,
  THUMBNAIL_2D_VIEW_EVENTS,
  VISUAL_FILTER_ITEMS,
  ARROW_KEYS,
  SHARE_PROPERTIES,
  THUMB_STAGE_HORIZONTAL_VIEW,
  LISTING_MODES,
} from 'projects/annotation-2d/src/app/constants';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  debounceTime,
  of,
  zip,
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  IGeometryAnnotationDto,
  IImageViewerDefaultTagObject,
  IResourceImageRecord,
  IStageResourceRecord,
  ITagResourceGroup,
  IUniqueIds,
  EventTypes,
  IAsset,
  ICanvasQueryParams,
  IPrimaryAllTowerAssets,
  ICurrentSelectedAnnotation,
  IImageSourceTag,
  IThumbnailRenderInstance,
  IEventEmitAnnotation,
} from 'projects/annotation-2d/src/app/interfaces';
import {
  AnnotationDataResourceService,
  AnnotationVisualCanvasService,
  NotificationService,
} from 'projects/annotation-2d/src/app/services';
import * as moment from 'moment-timezone';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AssetUrlGlobePipe } from '../../../asset-url-globe.pipe';
import { VisualCanvasAnnotationComponent } from '../visual-canvas-annotation/visual-canvas-annotation.component';
import { GpsCoordinatePipe } from 'projects/annotation-2d/src/app/pipes/gps-coordinate.pipe';
import { ISceneObject } from 'projects/digital-twin/src/app/interfaces';
import { CanvasDataService } from 'projects/digital-twin/src/app/services/canvas-data.service';

@Component({
  selector: 'app-image-canvas-viewer',
  standalone: true,
  templateUrl: './image-canvas-viewer.component.html',
  styleUrl: './image-canvas-viewer.component.scss',
  providers: [GpsCoordinatePipe],
  imports: [VisualCanvasAnnotationComponent, CommonModule, FormsModule, AssetUrlGlobePipe, GpsCoordinatePipe],
})
export class ImageCanvasViewerComponent implements OnChanges {
  @ViewChild('canvasAnnotationViewer') canvasVisualAnnotationViewerRef: VisualCanvasAnnotationComponent;
  @ViewChild('listScrollContainer', { read: ElementRef }) listScrollContainerRef!: ElementRef;
  @ViewChild('visualPanelContainer', { read: ElementRef }) visualPanelContainerRef!: ElementRef;
  @Input() isMapLayoutMinimize: boolean;
  @Input() galleryCurrentLayoutSize: { width: number, height: number } | null;
  @Input({ required: true }) imageRGB: IImageSourceTag['imageRGB'];
  @Input({ required: true }) annotationList: IGeometryAnnotationDto[];
  @Input({ required: true }) canvasQueryParamsObject: ICanvasQueryParams;
  @Input({ required: true }) isLoadCompletionStatus: boolean;
  @Input({ required: true }) moduleType: string = '';
  @Input() splitScreenViewerMode: boolean = false;
  @Input() modeOfActionClicked: string = '';
  @Input() selectedImageIdByMap: string | null = '';
  @Input() selectedImageIdByImage: string = '';
  @Input() activeSceneAnnotateIds: string[] = []; //DigitalTwin get from scene annotation 2d reference
  activeSceneObject: ISceneObject; //DigitalTwin
  @Input() currentTabClicked: string = LISTING_MODES.ASSETS;
  @Input() findingPanelOpenStatus: boolean = false;
  @Output() selectedImageId: EventEmitter<string> = new EventEmitter();
  @Input() missionId: string = '';
  @Input() dataResourceIds: string[] = []
  @Input() isOrthoMapPreviewMode: boolean = false;  //Annotaion-2d 
  // pgb -----------
  workspaceId = "";
  // ------------------------------- 
  // Observ & subscription:
  thumbnailResourceObservable: Observable<IResourceImageRecord[]> = of([]);
  originFullResourceObservable: Observable<IResourceImageRecord[]> = of([]);
  compressedResourceObservable: Observable<IResourceImageRecord[]> = of([]);
  combinedResourceSubscript!: Subscription;
  requestAnnotationSubscript!: Subscription;
  unSubscribeUpdateDataSource: Subject<string> = new Subject<string>();
  isDataSourceImagesLoadOnceCompleted = false;
  isDataSourceImagesLoadOnceCompletedObservable$ = new BehaviorSubject(false);
  mouseWheelDebounceEvent = new Subject<boolean>();
  onHorizontalScrollLoadMoreMouseEvent = new Subject<boolean>();
  isFirstTimeDataresource = false;
  loadMoreHorzImages: IStageResourceRecord[] = [];
  imagesPerChunk = STAGE_2D_CANVAS_COMMON_VIEW.IMAGE_PER_CHUNK; // Number of images to load per chunk
  invokeInitLoader = true;
  isInsufficientDataResource = false;
  // scroll
  equipmentType: string[] = [STAGE_2D_CANVAS_COMMON_VIEW.ANTENNA]; //payload
  momentTimeZone = moment;
  inlineChangeStatus = true
  missionNewId = '';
  currentPayloadMethod = '';
  //Canvas Input properties[Annotation]:*********
  canvasTitleMsg = STAGE_2D_CANVAS_COMMON_VIEW.IMAGE_LOADING
  hideCanvas = true;
  thumbnailMode = true;
  thumbnail2dViewEvent = THUMBNAIL_2D_VIEW_EVENTS; //* ui thumbnail view
  geometryAnnotationDto: IGeometryAnnotationDto[] = [];
  thumbnailView = THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW;
  isHorizontalView = true;
  showManualAnnotation = true;
  rightSideOffest = STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN;
  showViewCanvasLoader = true;
  // tower storage process: ========
  fetchingFromAssetLoader = true;
  towerCanvasImagesLoader = false;
  imageHorizontalResourceList: IStageResourceRecord[] = [];
  filteredHorizontalResourceList: IStageResourceRecord[] = [];
  primaryAllTowerAssets: IPrimaryAllTowerAssets = {};
  currentSelectedTowerId: string = '';
  currentSelectedCanvas: IStageResourceRecord | null; // current canvasItems
  currentSelectedCanvasIndex = 0;
  defaultSelectedCanvasIndex = 0;
  // global store:
  primaryStoredImageDataSourceList: IStageResourceRecord[] = [];
  primaryStoredAnnotationList: IGeometryAnnotationDto[] = [];
  annotatedImageHorizontalResourceList: IStageResourceRecord[] = [];
  // findings:
  isFindingHeightInitResize = STAGE_2D_CANVAS_COMMON_VIEW.FINDING_HEIGHT_INIT;
  isFindingHeightActual = STAGE_2D_CANVAS_COMMON_VIEW.FINDING_HEIGHT_ACTUAL;
  currentSelectedAnnotation: ICurrentSelectedAnnotation | null = null;
  currentSavedFindingPayload: { _id: string, annotations: string[] } | null;
  // filter:
  imageFolderTagDropdownList: IImageViewerDefaultTagObject[] = [];  //main tag folders:
  secondaryImageFolderTagDropdownList: IImageViewerDefaultTagObject[] = [];  // secondary tag folders:
  showMenuOnOff = false;
  showAllTagFilter = true;
  @Output() closeGalleryLayout: EventEmitter<boolean> = new EventEmitter();
  // image gallery:
  isFindingAnnotatedClicked = false;
  isLoadCompletionSourceSB = new Subject<boolean>();
  selectedAnnotatedIdFromFinding = '';
  filteredHorizontalResourceListSB = new Subject<boolean>();
  initialSelectSeneStatus = false;
  isThumbnailViewer: boolean; //default set viewer both grid ,twin
  activeThumbnailViewerId = '';
  gridModuleType = SHARE_PROPERTIES.MODULE_TYPE_GRID;
  twinModuleType = SHARE_PROPERTIES.MODULE_TYPE_TWIN;
  @Output() isDataRecordFound: EventEmitter<boolean> = new EventEmitter();

  subscriptions: Subscription[] = [];
  activeCanvasBackgroundImgGroup: string;
  autoTowerProjection = false;
  isClickedOpenVisualGallery = false;
  aiSnapShotImages: IStageResourceRecord[] = [] //digital-win
  constructor(
    private dataResourceService: AnnotationDataResourceService,
    private annotationVisualCanvasService: AnnotationVisualCanvasService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private canvasDataService: CanvasDataService,
  ) {
    const userData = localStorage.getItem('user-data');
    if (userData) {
      const parsedData = JSON.parse(userData);
      this.workspaceId = parsedData?.activeWorkspaceId || null;
    }
    this.isThumbnailViewer = this.moduleType === this.twinModuleType;
    this.thumbnailOnMouseScrollEvent();
    // Init Load All Images:
    this.initLoadDataResourceImagesRGB();
    // Integration Bindings Inputs:
    this.getReadyToLoadStageCanvasImageListener();
    // Part Of Attaching with image viewer:
    this.leftSidePanelWithImage2dGalleryBinding(); //Finding Panel
    effect(()=>{
      this.aiSnapShotImages = this.canvasDataService.snapShotImageSignal()?.map(x => { 
        return {
          ...x, 
          stageProperty: {
            id: THUMB_STAGE_HORIZONTAL_VIEW._PREFIX + x._id,
            width: THUMB_STAGE_HORIZONTAL_VIEW.THUMB_VIEW_WIDTH,
            height: THUMB_STAGE_HORIZONTAL_VIEW.THUMB_VIEW_HEIGHT,
          }
        }
      });
    })
  }

  thumbnailOnMouseScrollEvent() {
    // Adjust the debounce time in milliseconds in next move:
    this.mouseWheelDebounceEvent.pipe(debounceTime(500)).subscribe((event) => {
      this.getPreviewMainByClickImg(this.loadMoreHorzImages[this.currentSelectedCanvasIndex], this.currentSelectedCanvasIndex, event)
      if (!this.isFindingAnnotatedClicked && !this.activeScrollToViewLoadMore) this.scrollToView(); //while scroll load more no need to apply scrollTOView 
    });
    // mouse onScroll horizontal:
    this.onHorizontalScrollLoadMoreMouseEvent.pipe(debounceTime(500)).subscribe((event) => {
      if (event) {
        // currently load more scroll activate only-> [digitalTwin][click visual Button]
        if(this.moduleType === this.twinModuleType){
          this.readyToUpdateSettingByRecords(this.filteredHorizontalResourceList);
        }else{ //[image processing]
          this.loadMoreDataSourceRecordImages(STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW, this.filteredHorizontalResourceList);
        }
      }
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    for (const inputItem in changes) {
      const change = changes[inputItem];
      switch (inputItem) {
        case 'imageRGB':
          if (this.autoTowerProjection && this.imageRGB && this.moduleType === this.twinModuleType) this.towerRotateBestViewLogicHandler();
          break;
        case 'isLoadCompletionStatus':
          this.handleLoadCompletionStatus(change);
          break;
        case 'annotationList':
          this.handleAnnotationList(change);
          break;
        case 'selectedImageIdByMap':
          this.handleSelectedGridInput(change);
          break;
        case 'galleryCurrentLayoutSize':
          this.handleGalleryLayoutSize(change);
          break;
        case 'findingPanelOpenStatus':
          this.getClosedFindingPanel();
          break;
      }
    }
  }

  // -----------------------------------[INIT DATASOURCE LOAD]--------------------------------------
  isLoadCompletionDSource = false;
  initLoadDataResourceImagesRGB() {
    this.isFirstTimeDataresource = true;
    this.fetchingFromAssetLoader = true;
    this.isLoadCompletionSourceSB.subscribe((isDone) => {
      if (this.imageRGB && isDone) {
        const originImages = this.imageRGB.originImages;
        const thumbnailImages = this.moduleType === this.twinModuleType ? this.imageRGB.overAllThumbnailImages : this.imageRGB.thumbnailImages;
        const compressedImages = this.imageRGB.compressedImages;
        const collectImages = [originImages, thumbnailImages, compressedImages];
        this.isInsufficientDataResource = false;
        this.isLoadCompletionDSource = isDone;
        collectImages.forEach((images) => {
          if (images?.length === 0) this.isInsufficientDataResource = true;
        })
        if (!this.isInsufficientDataResource && thumbnailImages) this.getVisualCanvasImageLoader(originImages, thumbnailImages, compressedImages);
      }
    });
  }

  // canvas image start to load:
  getVisualCanvasImageLoader(originImages: IResourceImageRecord[], thumbnailImages: IResourceImageRecord[], compressedImages: IResourceImageRecord[]) {
    this.fetchingFromAssetLoader = false // fetch from tower
    this.towerCanvasImagesLoader = true;  // canvas loader shown
    if (thumbnailImages?.length) {
      const imageRecordResource = this.checkStageExistsOrNotInPrimaryTowers();
      if (imageRecordResource?.length) this.imageHorizontalResourceList = imageRecordResource;
      this.startCreatingCanvasImage(thumbnailImages, originImages, compressedImages);
      this.getStoredForCompletionDataResourceRecords(); //global stored for data canvas images only
      this.storedAnnotatedWithEachImageResourceRecords(); //* global stored each annotation with data resource.
      this.getBindAnnotatedWithImageResourceRecords(); // To show annotation with image
    }
    this.towerCanvasImagesLoader = false;  // loader shown off
  }

  checkStageExistsOrNotInPrimaryTowers() {
    return this.primaryAllTowerAssets[this.currentSelectedTowerId] || [];
  }

  startCreatingCanvasImage(thumbnailImages: IResourceImageRecord[], originImages: IResourceImageRecord[], compressedImages: IResourceImageRecord[]) {
    this.getVisualTagImageLocalProps(thumbnailImages, IRESOURCE_TAG.THUMBNAIL_TAG, false);
    this.getVisualTagImageLocalProps(originImages, IRESOURCE_TAG.ORIGINAL_TAG, false);
    this.getVisualTagImageLocalProps(compressedImages, IRESOURCE_TAG.COMPRESSED_FULL_RES, false);
    const combinedResourceObservable: Observable<any[]> = zip(this.thumbnailResourceObservable, this.originFullResourceObservable, this.compressedResourceObservable);
    const combinedResourceSubscript = combinedResourceObservable.subscribe(combinedData => {
      if (combinedData?.length) {
        const thumbnailResourceRecords = combinedData[0] || [];
        const originFullResourceRecords = combinedData[1] || [];
        const compressedFullResourceRecords = combinedData[2] || [];
        this.imageHorizontalResourceList = this.annotationVisualCanvasService.createVisualStagePropertyByThumbnail(thumbnailResourceRecords, originFullResourceRecords, compressedFullResourceRecords); // stageCreation
        this.primaryAllTowerAssets[this.currentSelectedTowerId] = [...this.imageHorizontalResourceList];  // save tower assets:
        // * Store global one primary:**
        this.primaryStoredImageDataSourceList = [...this.imageHorizontalResourceList]; // Currently Use Global: this.filteredHorizontalResourceList
      }
    });
    this.subscriptions.push(combinedResourceSubscript);
  }

  getVisualTagImageLocalProps(recordsOfThumbnail: any[], tag: string, isSnap: boolean) {
    let thumbTemp: IResourceImageRecord[] = [];
    let originFullTemp: IResourceImageRecord[] = [];
    let compressedFullTemp: IResourceImageRecord[] = [];
    for (const record of Object.values(recordsOfThumbnail)) {
      const preSignedUrl = record.storage.files[0].preSignedUrl
      const respData = {
        _id: record._id,
        name: record.recordType,
        dataResourceId: record._id,
        resourceTag: tag,
        url: preSignedUrl,
        parentResourceId: record.parentResourceId ? record.parentResourceId : null,
        gpsLatitude: record.metadata.exif?.gpsLatitude ?? "",
        gpsLongitude: record.metadata.exif?.gpsLongitude ?? "",
        isReviewed: record.metadata?.isReviewed ?? false,
        updatedAt: record.updatedAt,
        record: record,
        fileName: preSignedUrl ? this.getFileNameFromURL(preSignedUrl) : "NA",
        imageName: record?.imageName || '',
        thumbnailTags: [],
      };
      switch (tag) {
        case IRESOURCE_TAG.THUMBNAIL_TAG:
          this.thumbnailResourceObservable = of([]);
          thumbTemp.push({ ...respData, thumbnailTags: record?.tags })
          this.thumbnailResourceObservable = of(thumbTemp);
          break;
        case IRESOURCE_TAG.ORIGINAL_TAG:
          this.originFullResourceObservable = of([]);
          originFullTemp.push({ ...respData, thumbnailTags: record?.tags })
          this.originFullResourceObservable = of(originFullTemp);
          break;

        case IRESOURCE_TAG.COMPRESSED_FULL_RES:
          this.compressedResourceObservable = of([]);
          compressedFullTemp.push({ ...respData, thumbnailTags: record?.tags })
          this.compressedResourceObservable = of(compressedFullTemp);
          break;
      }
    }
  }

  // get fileName from url:
  getFileNameFromURL(url: string) {
    return url.split('?')[0].split('/').pop() ?? '';
  }

  getStoredForCompletionDataResourceRecords() {
    // * Store global one Tower name groupBy array ["T245":[{...}]]:
    this.filteredHorizontalResourceList = [...this.primaryStoredImageDataSourceList]; // Currently Use Global: for all list,tag..
    this.getResetUpdatedDropDownImageList();    // initial Reset DropDown List
    this.getTagsFolderList(this.filteredHorizontalResourceList); // Get unique tag folders drop list from data sources:
    this.filteredHorizontalResourceList = this.filteredHorizontalResourceList.filter(elm => elm.displayTag); //filter with except tag :[thumbnail]
    this.imageFolderTagDropdownList = this.imageFolderTagDropdownList.map((elm) => ({ ...elm, checked: true }));   //Fill DropdownCheckList:
  }
  // [Image Processing] :Ready to load
  getReadyToLoadCanvasWithImageList(filteredHorizontalResourceList: IStageResourceRecord[]) {
    if (filteredHorizontalResourceList?.length) {
      this.getResetUpdatedDropDownImageList();    // initial Reset DropDown List
      this.getTagsFolderList(filteredHorizontalResourceList); // Get unique tag folders drop list from data sources:
      this.getUpdatedDropDownAndThumbnailByChecked(filteredHorizontalResourceList)  // Filter value Dynamic Updation image droplist 
      filteredHorizontalResourceList = filteredHorizontalResourceList.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
      this.loadMoreDataSourceRecordImages(STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW, filteredHorizontalResourceList);
      if (this.loadMoreHorzImages.length > 0) {
        this.mouseWheelDebounceEvent.next(false); // ** Set default/current Image selected:
      } else this.getStatusForNoDataFound();
    } else this.getStatusForNoDataFound();
  }

  // ---------------------------------------------------------------------
  //=======================  FILTER FOLDER IMAGES =======================
  getResetUpdatedDropDownImageList() {
    this.imageFolderTagDropdownList = [];
    const defaultDroplist = [{ displayName: VISUAL_FILTER_ITEMS.SHOW_ALL, actualTag: VISUAL_FILTER_ITEMS.SHOW_ALL, checked: true }];
    this.imageFolderTagDropdownList.push(...defaultDroplist);
  }

  //Start: Tag folders Configuration 
  getTagsFolderList(resourceRecord: IStageResourceRecord[]) {
    resourceRecord.forEach((ele: IStageResourceRecord) => {
      const thumbnailTags: string[] = ele.thumbnailTags ?? [];
      const tagsList: string[] = thumbnailTags && thumbnailTags.length > 0 ? [...thumbnailTags] : [];
      const resourceTagSkippedTagList = tagsList.filter(tl => tl.includes(IRESOURCE_TAG.IMAGE_GROUP_HIERARCHY));
      if (resourceTagSkippedTagList?.length) {
        for (const tag of resourceTagSkippedTagList) {
          const splittedTag: string = tag.split(':').pop() ?? '';
          ele.displayTag = splittedTag;
          ele.actualTag = tag;
          ele.showTag = true;
          ele.showAll = true;
          const filterDuplicatedImageTags = (this.imageFolderTagDropdownList.length && splittedTag) ? this.imageFolderTagDropdownList.filter(imgTag => imgTag?.displayName === splittedTag) : [];
          if (!filterDuplicatedImageTags.length) {
            const imageTags = { displayName: splittedTag, actualTag: tag, checked: true }
            this.imageFolderTagDropdownList.push(imageTags);
          }
        }
      }
    })
    this.imageFolderTagDropdownList = this.imageFolderTagDropdownList?.filter((obj, index, self) => index === self.findIndex(item => item.displayName === obj.displayName));

    this.imageFolderTagDropdownList.sort((a, b) => {
      if (a.displayName < b.displayName) {
          return -1;
      }
      if (a.displayName > b.displayName) {
          return 1;
      }
      return 0;
  });
  const showAllIndex = this.imageFolderTagDropdownList.findIndex(item => item.displayName === "Show All");
  if (showAllIndex !== -1) {
      const showAllItem = this.imageFolderTagDropdownList.splice(showAllIndex, 1)[0];
      this.imageFolderTagDropdownList.unshift(showAllItem);
  }
  }

  onShowHideToggle() {
    this.showMenuOnOff = !this.showMenuOnOff;
  }

  // onCheck toggle Trigger in droplist -------
  folderOnChangeSelectedDropListEvent(option: IImageViewerDefaultTagObject) {
    // 1.onchange/updating value to dropdown list:
    this.updatedDropdownListAndHorzontalDataSourceByTag(option, this.filteredHorizontalResourceList, STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW);
    // 2.Stored global latest filter folder: 
    this.secondaryImageFolderTagDropdownList = [...this.imageFolderTagDropdownList];
    if (this.moduleType === this.twinModuleType) {
      this.currentSelectedCanvasIndex = 0;
      this.resetNdUpdateCanvas2dThumbnail(this.filteredHorizontalResourceList);
    } else { //grid
      this.getStartLoadMoreTowerCanvasImages();
      if (this.loadMoreHorzImages && this.loadMoreHorzImages.length > 0) this.getPreviewMainByClickImg(this.loadMoreHorzImages[0], this.defaultSelectedCanvasIndex);
    }
  }

  updatedDropdownListAndHorzontalDataSourceByTag(option: IImageViewerDefaultTagObject, resourceRecord: IStageResourceRecord[], type: string) {
    if (option.displayName === VISUAL_FILTER_ITEMS.SHOW_ALL) {
      this.toggleSelectAllFilter(option, resourceRecord);
      return;
    }
    for (const item of resourceRecord || []) {
      if (item.displayTag === option.displayName) {
        item.showTag = !option.checked;
        if (!item.showTag) {
          this.uncheckShowAllTag();
          item.showAll = false;
        }
      }
    }
  }

  uncheckShowAllTag() {
    const tagShowAllIndex = this.imageFolderTagDropdownList.findIndex(elm => elm.displayName === VISUAL_FILTER_ITEMS.SHOW_ALL);
    this.imageFolderTagDropdownList[tagShowAllIndex].checked = false;
    this.showAllTagFilter = false;
    this.currentSelectedCanvas = null;
  }

  toggleSelectAllFilter(option: IImageViewerDefaultTagObject, resourceRecord: IStageResourceRecord[]) {
    this.showAllTagFilter = !this.showAllTagFilter;
    this.currentSelectedCanvas = null;
    // updated in dropDown:
    this.imageFolderTagDropdownList = this.imageFolderTagDropdownList.map(elm => {
      return { ...elm, checked: !option.checked }
    })
    // updated in image records:
    for (const item of resourceRecord) {
      item["showAll"] = !option.checked;
      item.showTag = !option.checked;
    }
  }

  // filter tag check and updation:
  getUpdatedDropDownAndThumbnailByChecked(filteredHorizontalResourceList: IStageResourceRecord[]) {
    //1.Clone the imageDropdownData and existing and show checked list:
    const getExistCheckedOnlyFilterTags = this.secondaryImageFolderTagDropdownList.filter((tags) => tags.checked) || [];
    //2. Filter value Dynamic Updation image droplist *************************:
    // -----------------------------------------
    // I.While rotate check availability in current tag folder list, whether checked or not :updateDropdown box         
    this.imageFolderTagDropdownList = this.imageFolderTagDropdownList.filter((elm) => elm.displayName);
    for (const item of this.imageFolderTagDropdownList) {
      const updateVal: any = getExistCheckedOnlyFilterTags.find((elm) => elm.displayName === item.displayName)
      if (updateVal) item.checked = updateVal.checked;
      else item.checked = false
    }
    // II.while tower rotate without clicked checkboxes:
    const isDropNotCheckedAll = this.imageFolderTagDropdownList.every((elm) => elm.checked === false);
    if (isDropNotCheckedAll) this.imageFolderTagDropdownList = this.imageFolderTagDropdownList.map((elm) => ({ ...elm, checked: true }));
    // -------------------------------------------
    // 3.apply checkbox value to record *********************************************
    // II.loadmore - updation by dropdown filter to vertical/horizontal:
    return this.updateVisualHorzImagesByCheckedFilterTag(filteredHorizontalResourceList, this.imageFolderTagDropdownList);
  }

  updateVisualHorzImagesByCheckedFilterTag(resourceImagRecord: IStageResourceRecord[], getCheckedOnlyFilterDropList: IImageViewerDefaultTagObject[]) {
    const filterRcd = resourceImagRecord.filter((record) => getCheckedOnlyFilterDropList.some((item) => item?.displayName === record?.displayTag))
    for (const item of filterRcd) {
      const updateVal = getCheckedOnlyFilterDropList.find((elm) => elm.displayName === item.displayTag)
      if (updateVal) {
        item.showTag = updateVal.checked; //if value one of false means.
        if (!item.showTag) item.showAll = false;  //update value to showAll is false
      }
    }
    return filterRcd;
  }

  getStatusForNoDataFound() {
    this.annotationVisualCanvasService.setOpenImageviewer(false);
    this.annotationVisualCanvasService.setActiveMarkedAnnotation(null); // active annoation null & finding panel false hide
    this.isInsufficientDataResource = true;
    this.isFindingAnnotatedClicked = false;
    if (this.canvasVisualAnnotationViewerRef) this.canvasVisualAnnotationViewerRef.drawBox = null;
    this.notificationService.showToast({
      type: EventTypes.error,
      message: `${API_MESSAGE_EVENTS.GENERIC_ERR_FINDING_IMAGE_MESSAGE.toLowerCase()}`,
    });
  }

  initialFirstTimeDataResourceLoad() {
    if (this.isFirstTimeDataresource) {  //First time load scenario:
      // this.isDataSourceImagesLoadOnceCompletedObservable$.next(true);
      this.isFirstTimeDataresource = false;
      //  temp initial closed-------------------------------------
      if (this.selectedImageIdByMap) {
        if (!this.isFindingAnnotatedClicked) this.scrollToView();
      } else {
        this.getPreviewMainByClickImg(this.loadMoreHorzImages[0], this.defaultSelectedCanvasIndex);
      }
    }
  }

  getStartLoadMoreTowerCanvasImages() {
    this.loadMoreHorzImages = [];
    this.loadMoreDataSourceRecordImages(STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW, this.filteredHorizontalResourceList);
  }

  // ************************************LoadMOre Horizontal Canvas Images ***********************************
  //Binding Html: Load more images into the loadMoreHorzImages array
  startIndexLoad!: number;
  endIndexLoad!: number;
  loadMoreImgs: IStageResourceRecord[] = [];
  filteredResourceList: IStageResourceRecord[] = [];
  loadMoreImagsCompletionStatus: boolean = false;
  activeScrollToViewLoadMore = false;
  loadMoreDataSourceRecordImages(type: string, filteredHorizontalResourceList: IStageResourceRecord[]) {
    if (type === STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW) {
      this.startIndexLoad = this.loadMoreHorzImages.length;
      this.endIndexLoad = this.startIndexLoad + this.imagesPerChunk;
      this.loadMoreImgs = this.loadMoreHorzImages;
      this.filteredResourceList = filteredHorizontalResourceList;
    }
    // Use a temporary object to keep track of seen IDs --------------- 
    const seenIds: IUniqueIds = {};
    const uniqueFilteredResourceRecord = this.filteredResourceList.filter(item => {
      if (!seenIds[item._id]) {
        seenIds[item._id] = true;
        return true;
      }
      return false;
    });
    // filteredResource Records --------------
    const resultFilteredUnique = uniqueFilteredResourceRecord.filter(elm => (elm.showTag) === true) || [];

    // scroll more: apply on twin :while clicked visual button only
    if(this.isClickedOpenVisualGallery && this.moduleType === this.twinModuleType){
      this.activeScrollToViewLoadMore = true;
      if (resultFilteredUnique.length > this.loadMoreImgs.length) {
        if (this.startIndexLoad < resultFilteredUnique.length) {
          this.loadMoreImgs.push(...resultFilteredUnique.slice(this.startIndexLoad, this.endIndexLoad));
        }
      }
    } else { //[twin][image-processing]
      this.loadMoreImgs.push(...resultFilteredUnique); // for finding 
    }

    this.invokeInitLoader = false;
    // unique loadmore Records --------------
    const uniqueLoadMoreImgs = this.loadMoreImgs.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
    //in ui binding need to assign loadmore input   
    if (type === STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW) this.loadMoreHorzImages = uniqueLoadMoreImgs;
    if(this.moduleType === this.twinModuleType && this.isClickedOpenVisualGallery){
      if (this.loadMoreHorzImages.length === filteredHorizontalResourceList.length) this.loadMoreImagsCompletionStatus = true;
    } 
  }

  getLoadMoreUnique(loadMoreImgs: IStageResourceRecord[]) {
    const loadIds: IUniqueIds = {};
    loadMoreImgs.filter((item: { _id: string; }) => {
      if (!loadIds[item._id]) {
        loadIds[item._id] = true;
        return true; // Include the first occurrence of each unique ID
      }
      return false; // Skip duplicates
    });
    return loadMoreImgs;
  }

  // ------------------------------------------------------------------------------------------------
  //=======================  CANVAS THUMBNAIL CLICK && SCROLL PREV/NEXT IMAGES =======================
  initClkLoader: boolean = true;
  // OnClick Veritical-horizontal thumbnail Image
  getPreviewMainByClickImg(canvasItem: IStageResourceRecord, currentIndexItem: number, closeFindingPanel = false) {
    this.showViewCanvasLoader = true;
    this.isHorizontalView = true;
    this.hideCanvas = false;
    this.currentSelectedCanvasIndex = currentIndexItem;
    if (canvasItem?.originFulId) this.selectedImageId.emit(canvasItem.originFulId);
    if (this.currentSelectedCanvas?._id === canvasItem._id && this.initClkLoader) {
      this.currentSelectedCanvas = canvasItem;
      this.initClkLoader = !this.initClkLoader;
    } else {
      this.currentSelectedCanvas = canvasItem;
      if (this.canvasVisualAnnotationViewerRef) this.canvasVisualAnnotationViewerRef.onSelectDrawBoxReset(); // init reset
    }
    if (this.canvasVisualAnnotationViewerRef) this.canvasVisualAnnotationViewerRef.selectedItem = this.currentSelectedCanvas;
    // onEach time focus on the content & selected items:
    const elmViewer = document.getElementById("viewer") as HTMLDivElement;
    if (elmViewer) elmViewer.focus();
    // init reset
    this.showViewCanvasLoader = false;
    const openFindingPanelStatus = this.annotationVisualCanvasService.getActiveFindingPanelStatus(); //check activeStatus : true
    if (openFindingPanelStatus && closeFindingPanel) this.annotationVisualCanvasService.setActiveFindingPanel(false); //default closed find panel
  }
  
  onClickExpandInThumbnailViewer(canvasItem: IStageResourceRecord, currentIndexItem: number) {
    this.showViewCanvasLoader = false;
    this.isHorizontalView = true;
    this.hideCanvas = false;
    this.currentSelectedCanvas = canvasItem;
    this.autoTowerProjection = false;
    if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN && this.isThumbnailViewer) this.annotationVisualCanvasService.setImageThumbnailViewerMode({ isThumbnailViewer: false, activeId: this.currentSelectedCanvas._id }); //scene image select
  }

  /* #canvasImg scroll bar */
  canvasLoadMoreImgScrollRight(): void {
    const element: any = document.getElementById('canvas-all-canvas-img');
    const adjustableWidth = element.children[0].clientWidth;
    element.scrollTo({ left: element.scrollLeft + adjustableWidth, behavior: 'smooth' });
  }

  canvasLoadMoreImgScrollLeft(): void {
    const element: any = document.getElementById('canvas-all-canvas-img');
    const adjustableWidth = element.children[0].clientWidth;
    element.scrollTo({ left: element.scrollLeft - adjustableWidth, behavior: 'smooth' });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === ARROW_KEYS.ARROW_LEFT) {
      // Handle left arrow key press
      this.onThumbNailNavClick('left');
    } else if (event.key === ARROW_KEYS.ARROW_RIGHT) {
      // Handle right arrow key press
      this.onThumbNailNavClick('right');
    }
  }

  // on thumbnail image nav click
  onThumbNailNavClick(direction: 'left' | 'right') {
    if (this.loadMoreHorzImages?.length) {
      this.currentSelectedCanvasIndex = direction == 'right' ? Math.min(this.currentSelectedCanvasIndex + 1, this.loadMoreHorzImages.length - 1) : Math.max(this.currentSelectedCanvasIndex - 1, 0);
      this.scrollToView();
      this.mouseWheelDebounceEvent.next(true);
    }
  }

  scrollToView() {
    if (this.loadMoreHorzImages?.length) {
      const el = document.getElementById(`canvasannotationthumbnail-${this.currentSelectedCanvasIndex}`);
      if (el) {
        const container = el.parentNode as HTMLElement;
        if (container) {
          const scrollAmount = el.offsetLeft - (container.scrollLeft + 20);
          container.scrollBy({
            left: scrollAmount,
            behavior: 'smooth'
          });
        }
      }
    }
  }

  // Mouse Scroll Event:
  onHorizontalScroll(event: WheelEvent): void {
    this.listScrollContainerRef.nativeElement.scrollLeft += event.deltaY;
    // Check for horizontal scrolling && !this.loadMoreImagsCompletionStatus
    if (event.deltaY !== 0 && event.deltaY > 0) {
      // Check if the user has scrolled to the end (adjust 10 to a suitable threshold)
      const element = event.target as HTMLElement;
      if (element.scrollLeft + element.clientWidth + 100 >= element.scrollWidth && !this.loadMoreImagsCompletionStatus) {
        // currently load more scroll activate only-> [digitalTwin][click visual Button]
        if(this.moduleType === this.twinModuleType && this.isClickedOpenVisualGallery) this.onHorizontalScrollLoadMoreMouseEvent.next(true);
      }
    }
  }

  //======================= CANVAS DRAGGER =======================
  isResizingRightLayout: boolean = false;
  initialRightLayoutX!: number;
  // On layout drag (resize canvas and right layout)
  onResizerClick(event: MouseEvent) {
    this.isResizingRightLayout = true;
    this.initialRightLayoutX = event.clientX;
  }

  // canvas layout template: 
  @HostListener('document:mousemove', ['$event'])
  onResizeRightLayout(event: MouseEvent) {
    if (this.isResizingRightLayout) {
      const mouseX = event.clientX;
      const deltaX = mouseX - this.initialRightLayoutX;
      const newOffset = this.rightSideOffest - deltaX;
      // Check if the new offset exceeds the maximum width
      if (newOffset >= STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN && newOffset <= STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MAX) {
        this.changeRightSideOffset(newOffset);
        this.initialRightLayoutX = mouseX;
      } else if (newOffset < STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN) {
        // Limit the offset to the minimum width
        this.changeRightSideOffset(STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN);
        this.initialRightLayoutX = mouseX + this.rightSideOffest - STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN;
      } else if (newOffset > STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MAX) {
        // Limit the offset to the maximum width
        this.changeRightSideOffset(STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MAX);
        this.initialRightLayoutX = mouseX + this.rightSideOffest - STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MAX;
      }
      this.onResizeDOM();
    }
  }

  onResizeDOM() {
    if (this.canvasVisualAnnotationViewerRef && this.galleryCurrentLayoutSize) this.canvasVisualAnnotationViewerRef.updateStageWidth(this.galleryCurrentLayoutSize);
  }

  resizeStage() {
    this.canvasVisualAnnotationViewerRef.resizeStage()
  }

  @HostListener('document:mouseup')
  onResizerRelease() {
    this.isResizingRightLayout = false;
  }

  @HostListener('document:keydown.escape')
  onEscape() {
    if (this.isResizingRightLayout) this.onResizerRelease()
  }

  onCloseManualAnnotation() {
    if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) {
      this.canvasDataService.isImageGalleryOpen = false;
      if(!this.isClickedOpenVisualGallery){
        this.isThumbnailViewer = true;
        this.autoTowerProjection = false;
        this.annotationVisualCanvasService.setImageThumbnailViewerMode({ isThumbnailViewer: true, activeId: '' }); // scene image select
      }
    }
    this.annotationVisualCanvasService.removeAllThumbnailBox();
    this.annotationVisualCanvasService.destroyAllThumbViewStageCanvas();
    this.closeGalleryLayout.emit(true);
  }

  // MInimize Canvas viewer:
  getTriggerToMaxmizedViewer(eventItem: any) {
    if (eventItem.item && eventItem.method) {
      if (eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_MAXMIZE) {
        this.rightSideOffest = STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MAX;
      } else this.rightSideOffest = STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_DRAGGABLE_MIN;
    }
  }

  getTriggedisMapLayoutMinimize() {
    this.onResizeDOM();
  }

  // canvas image shown reset
  resetPrevCanvasShown() {
    this.thumbnailView = THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW;
    this.hideCanvas = true;
    this.thumbnailMode = true;
    this.isHorizontalView = true;
  }

  trimImageName(text: string, maxLength: number): string {
    if (text && text.length <= maxLength) {
      return text;
    }
    return text ? text.substring(0, maxLength - 3) + '...' : "";
  }

  //======================= ANNOTATION API GET  =======================
  storedAnnotatedWithEachImageResourceRecords() {
    const annotatedImageHorizontalResource: any[] = []; //global stored. each Annotation with each Data resource record
    if (this.primaryStoredAnnotationList && this.primaryStoredAnnotationList.length > 0) {
      this.primaryStoredAnnotationList.forEach(annotatedRecord => {
        const getImageStageRecord = this.filteredHorizontalResourceList.filter((elm) => elm.dataResourceId === annotatedRecord.dataResourceId);
        if (getImageStageRecord) annotatedImageHorizontalResource.push(...getImageStageRecord);
      });
      this.annotatedImageHorizontalResourceList = annotatedImageHorizontalResource;
    }
  }

  getBindAnnotatedWithImageResourceRecords() {
    // show annotation with image
    this.getBindVisualHorzImageWithAnnotate(this.filteredHorizontalResourceList); //Binding annotation Record
    const filteredAnnotations = this.filteredHorizontalResourceList.filter((elm) => {
      return elm.annotationRecords.some((ele: IGeometryAnnotationDto) => ele.geometry.coordinates.length > 0 && ele.geometry.coordinates[0].length > 0);
    });
    const tempWitOutAnnotaedAr = this.filteredHorizontalResourceList.filter((elm) => elm.annotationRecords.length === 0)
    this.filteredHorizontalResourceList = [...filteredAnnotations, ...tempWitOutAnnotaedAr];
    this.filteredHorizontalResourceListSB.next(true);
  }

  // Loaded annotation record to update horizontal images
  // Combined Data[ImageResource + Annotation] 
  getBindVisualHorzImageWithAnnotate(imageThumbnailList: IStageResourceRecord[]) {
    if (imageThumbnailList?.length) {
      imageThumbnailList.forEach((stageRecord, index) => {
        let filteredAnnotationDto: IGeometryAnnotationDto[] = [];
        // Assuming that `primaryStoredAnnotationList` is an array of annotations
        if (this.primaryStoredAnnotationList && this.primaryStoredAnnotationList.length > 0) {
          filteredAnnotationDto = this.primaryStoredAnnotationList.filter(element => element.dataResourceId === stageRecord.dataResourceId);
        } else {
          // Assuming `removeSelectedStageAnnotation` is a method that removes annotations
          this.annotationVisualCanvasService.removeSelectedStageAnnotation(stageRecord);
        }
        stageRecord.annotationRecords = filteredAnnotationDto;
      });
    }
  }

  // ------------------------------------------------------------------------------------------------
  //======================= ANNOTATION API CRUD INTEGERATIONS =======================
  // Emit Output Value:
  async getTriggerToUpdatedcanvas(eventItem: any) {
    eventItem.payload.missionId = this.missionId ? this.missionId : this.activatedRoute.snapshot.paramMap.get('missionId');
    eventItem.payload.workspaceId = this.workspaceId;
    if (this.moduleType !== SHARE_PROPERTIES.MODULE_TYPE_GRID) {
      eventItem.payload.classLabels = this.equipmentType;
      eventItem.payload.collectionId = STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_COLLECTION_TELCO;
    }
    await this.eventItemForVisualShapeAnnotation(eventItem);
  }

  async getTriggerToDeletecanvas(eventItem: any) {
    await this.getUpdatedToCurrentVisualCanvasImages(eventItem);
  }

  async eventItemForVisualShapeAnnotation(eventItem: any) {
    this.currentPayloadMethod = eventItem.method;
    if (eventItem.keyId && eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_UPDATE) {
      await this.getUpdatedToCurrentVisualCanvasImages(eventItem);
    } else if (eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_ADD) {
      await this.postAnnotationCanvasSave(eventItem);
    }
  }

  async getUpdatedToCurrentVisualCanvasImages(eventItem: any) {
    const event = eventItem.item;
    const thumbId = event._id;
    const annotateId = eventItem.keyId;
    // Annotation updated to horizontal thumbnail list:
    if (this.inlineChangeStatus) {
      this.annotationUpdateToDataResourceRecords(event);  // primary update To all record here.
      this.annotationUpdateStoredAnnotationRecords(event, annotateId); //primary stored annotation
    }
    // Update Canvas By Event Method:
    if (annotateId && eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_UPDATE) {
      await this.putAnnotationCanvasUpdate(eventItem);
    } else if (eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_ADD) {
      if (!this.isFindingAnnotatedClicked) await this.renderAnnotationToThumbnailCanvas(eventItem); //not shown click finding update thumbnail
    } else if (eventItem.method == STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_REMOVE) {
      const filteredAnnotateRecordIndex = this.currentSelectedCanvas?.annotationRecords.findIndex((elem: IGeometryAnnotationDto) => elem._id === annotateId);
      if (filteredAnnotateRecordIndex > -1) {
        // Use splice() to delete the element at the specified index
        this.currentSelectedCanvas?.annotationRecords.splice(filteredAnnotateRecordIndex, 1);
        // Delete the annotated element at the image resource record
        this.removeAnnotationFromDataResourceRecords(thumbId, annotateId);
        this.annotationRemoveStoredAnnotationRecords(annotateId); //primary stored annotation - remove
        this.removeIdFromAnnotationIds(annotateId);
        this.annotationVisualCanvasService.setActiveFindingPanel(false); //close finding panel
        await this.removeAnnotationCanvas(eventItem);
      }
    }
    this.get2DAnnotateReferenceFromStageDataResource(); //id Only Updated
  }

  // Function to handle annotation addition and update
  processAnnotation(event: any, callback: (record: any) => void) {
    const imageResourceRecords = [this.loadMoreHorzImages, this.annotatedImageHorizontalResourceList, this.filteredHorizontalResourceList, this.primaryStoredImageDataSourceList];
    imageResourceRecords.forEach(records => {
      const loadCanvasHIndex = records.findIndex(elem => elem._id === event._id);
      if (loadCanvasHIndex !== -1) {
        const record = records[loadCanvasHIndex];
        callback(record);
      }
    });
  }

  // Annotation add to Horizontal thumbnail list:
  annotationAddToDataResourceRecords(event: any, annotateId: string, newResponse: any) {
    const addAnnotation = (record: any) => {
      const filteredAnnotateRecord = record.annotationRecords.find((elem: IGeometryAnnotationDto) => elem._id === annotateId);
      if (!filteredAnnotateRecord) { //add new
        record.annotationRecords.push(newResponse);
        // unique Records :
        record.annotationRecords = this.getLoadMoreUnique(record.annotationRecords);
      }
    };
    this.processAnnotation(event, addAnnotation);
  }

  // Annotation update to Horizontal thumbnail list:
  annotationUpdateToDataResourceRecords(event: any) {
    const updateAnnotation = (record: any) => {
      record.annotationRecords = event.annotationRecords;
      record.isAnnotated = event.annotationRecords.length > 0;
    };
    this.processAnnotation(event, updateAnnotation);
  }

  removeAnnotationFromDataResourceRecords(dataResourceId: string, annotateId: string) {
    const imageResourceRecords = [this.filteredHorizontalResourceList, this.loadMoreHorzImages, this.primaryStoredImageDataSourceList, this.annotatedImageHorizontalResourceList]
    imageResourceRecords.forEach(record => {
      const foundRecord = record.find(elem => elem._id == dataResourceId);
      if (foundRecord) {
        const foundAnnotationRecordIndex = foundRecord.annotationRecords.findIndex((elem: { _id: string }) => elem._id == annotateId);
        if (foundAnnotationRecordIndex > -1) {
          foundRecord.annotationRecords.splice(foundAnnotationRecordIndex, 1);
        }
      }
    })
  }

  // [global annotation stored] : updated
  annotationUpdateStoredAnnotationRecords(event: any, annotateId: string) {
    const horzSlectedIndex = this.loadMoreHorzImages.findIndex((elm) => elm._id === this.currentSelectedCanvas?._id);
    if (horzSlectedIndex > -1) {
      const filteredAnnotateRecord = this.loadMoreHorzImages[horzSlectedIndex].annotationRecords.find((elem: IGeometryAnnotationDto) => elem._id === annotateId);
      if (filteredAnnotateRecord) { // check if annotation record exists
        // update primaryStoredAnnotationList
        const index = this.primaryStoredAnnotationList.findIndex((elem) => elem._id === annotateId);
        if (index !== -1) this.primaryStoredAnnotationList[index] = filteredAnnotateRecord;
        else this.primaryStoredAnnotationList.push(filteredAnnotateRecord);
      }
    }
  }

  // [global annotation stored] : removed
  annotationRemoveStoredAnnotationRecords(annotateId: string) {
    const imageResourceRecords = [this.primaryStoredAnnotationList]
    imageResourceRecords.forEach(record => {
      const annoIndex = record.findIndex((elem) => elem._id === annotateId);
      if (annoIndex > -1) {
        record.splice(annoIndex, 1);
      }
    })
  }

  removeIdFromAnnotationIds(keyId: string) {
    if (this.activeSceneAnnotateIds.includes(keyId)) {
      const indexToRemove = this.activeSceneAnnotateIds.indexOf(keyId);
      if (indexToRemove !== -1) this.activeSceneAnnotateIds.splice(indexToRemove, 1);
    }
  }
  // Save Annotations:
  async postAnnotationCanvasSave(eventItem: any) {
    this.annotationVisualCanvasService.saveAnnotation2d(eventItem.payload).subscribe({
      next: (response) => {
        if (response?._id) {
          if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) this.activeSceneAnnotateIds.push(response._id)
          // Set the ID of the polygon/rectangle using the setAttr method
          const currentActiveBoxResp = this.getChildCurrentBoxIdUpdate(eventItem, response)
          if (currentActiveBoxResp?.currentActiveShapeId && currentActiveBoxResp?.drawBox) {
            currentActiveBoxResp.drawBox.setAttr('draggable', true);
          }
          this.notificationService.showToast({
            type: EventTypes.success,
            message: response?.['message'] || `${STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_MSG_TITLE} ${API_MESSAGE_EVENTS.GENERIC_CREATE_SUCCESS_MESSAGE.toLowerCase()}`,
          });
        }
      },
      error: (error) => {
        console.error(error);
        this.notificationService.showToast({
          type: EventTypes.error,
          message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
        });
      },
      complete: () => {
        this.getUpdatedToCurrentVisualCanvasImages(eventItem);  // rendering parts
        if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) {
          this.get2DAnnotateReferenceFromStageDataResource(); //id Only Updated
          this.sceneObjectPropertyByAnnotation(); // Save To Scene
          if(this.currentSelectedCanvas) this.onCheckStatus(false, this.currentSelectedCanvas) //reset verify if new
        }
      },
    });
  }

  // ViewChild Ref Rect box updated:
  getChildCurrentBoxIdUpdate(eventItem: any, response: IGeometryAnnotationDto) {
    const annotate_id = response?._id ?? '';
    if (this.canvasVisualAnnotationViewerRef && annotate_id) {
      //1.Current DrawBox property update canvas:
      this.canvasVisualAnnotationViewerRef.currentActiveShapeId = annotate_id;
      this.canvasVisualAnnotationViewerRef?.drawBox?.setAttr('id', annotate_id);
      // this.canvasVisualAnnotationViewerRef.drawBox.id(annotate_id)
      this.canvasVisualAnnotationViewerRef.isAddNewRecord = false;
      // #tooltip updation id:-
      if (this.canvasVisualAnnotationViewerRef.drawBox.getAttr("aiTag")) this.annotationVisualCanvasService.updateIdToCurrentActiveToolTip(annotate_id);
      this.canvasVisualAnnotationViewerRef?.stage.batchDraw();
      //2.Current record update to All:
      eventItem.rectCoordinate.id = annotate_id;
      eventItem.keyId = annotate_id;
      response['rectCoordinate'] = eventItem.rectCoordinate;
      response['assetFindings'] = null;
      response['findingId'] = null;
      response['isClickedFinding'] = false;
      this.currentSelectedCanvas?.annotationRecords.push(response); // add new Current
      this.annotationAddToDataResourceRecords(eventItem, annotate_id, response); //add new all
      this.annotationAddToPrimaryAnnotation(annotate_id, response); //add new into annotation primary store
      // #activeAnnotation & open findingPanel -----------
      this.canvasVisualAnnotationViewerRef.currentActiveFindingId = ''; // new Finding creation:
      this.canvasVisualAnnotationViewerRef.currentActiveMarkedAnnotation();
      this.canvasVisualAnnotationViewerRef.disableClickEvents = false;
    }
    return this.canvasVisualAnnotationViewerRef;
  }

  annotationAddToPrimaryAnnotation(annotateId: string, newResponse: any) {
    const imageResourceRecords = [this.primaryStoredAnnotationList, this.geometryAnnotationDto]
    imageResourceRecords.forEach(record => {
      const annoValue = record.find((elem) => elem._id === annotateId);
      if (!annoValue) record.push(newResponse);
    })
  }

  // Update Annotations:
  async putAnnotationCanvasUpdate(eventItem: any) {
    this.annotationVisualCanvasService.updateAnnotation2d(eventItem.keyId, eventItem.payload).pipe(debounceTime(500)).subscribe({
      next: (response) => {
        const horzSlectedIndex = this.loadMoreHorzImages.findIndex((elm) => elm._id === this.currentSelectedCanvas?._id)
        if (horzSlectedIndex > -1) this.loadMoreHorzImages[horzSlectedIndex].isLoaded = false;  //loader updated on currentcanvas
        // notification msg
        this.notificationService.showToast({
          type: EventTypes.success,
          message: response?.['message'] || `${STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_MSG_TITLE} ${API_MESSAGE_EVENTS.GENERIC_UPDATE_SUCCESS_MESSAGE.toLowerCase()}`,
        });
      },
      error: (error) => {
        console.error(error);
        this.notificationService.showToast({
          type: EventTypes.error,
          message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
        });
      },
      complete: () => {
        if (!this.isFindingAnnotatedClicked) this.renderAnnotationToThumbnailCanvas(eventItem);  //not shown click finding update thumbnail
        if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) this.sceneObjectPropertyByAnnotation(); // Save To Scene
      },
    });
  }

  // Remove Annotations:
  async removeAnnotationCanvas(eventItem: any) {
    this.annotationVisualCanvasService.deleteAnnotation2dById(eventItem.keyId).subscribe({
      next: (response) => {
        this.notificationService.showToast({
          type: EventTypes.success,
          message: response?.['message'] || `${STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_MSG_TITLE} ${API_MESSAGE_EVENTS.GENERIC_DELETE_SUCCESS_MESSAGE.toLowerCase()}`,
        });
      },
      error: (error) => {
        console.error(error);
        this.notificationService.showToast({
          type: EventTypes.error,
          message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
        });
      },
      complete: () => {
        if (!this.isFindingAnnotatedClicked) this.renderAnnotationToThumbnailCanvas(eventItem);  //not shown click finding update thumbnail
        if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) this.sceneObjectPropertyByAnnotation(); // Save To Scene
      },
    });
  }
  // in future use
  async updateValidateCanvas(eventItem: any) {
    this.annotationVisualCanvasService.updateAnnotation2d(eventItem.keyId, eventItem.payload).subscribe({
      next: (response) => {
        if (response?._id) {
          // notification msg
          this.notificationService.showToast({
            type: EventTypes.success,
            message: response?.['message'] || `${STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_MSG_TITLE} ${API_MESSAGE_EVENTS.GENERIC_UPDATE_SUCCESS_MESSAGE.toLowerCase()}`,
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  // -------------------------------------------------------------------------------------
  // ========================= RERENDER LIST =============================================
  async renderAnnotationToThumbnailCanvas(eventItem: any) {
    const annotateId = eventItem.keyId;
    let currentAnnotationLatestData: any = {}; //add new Record
    // Get Annotation Record From Current thumbnail(existing):--------------
    const loadHzIndex = this.loadMoreHorzImages.findIndex((elm: { _id: string; }) => elm._id === this.currentSelectedCanvas?._id);
    if (loadHzIndex > -1) {  // Get current updated annotation record in updated loadHorzImage:
      if (this.loadMoreHorzImages[loadHzIndex]?.annotationRecords?.length) {
        const annoIdx = this.loadMoreHorzImages[loadHzIndex]?.annotationRecords.findIndex((elm: { _id: string; }) => elm._id === annotateId);
        // exist in loadmorz list: 
        if (annoIdx > -1) currentAnnotationLatestData = this.loadMoreHorzImages[loadHzIndex]?.annotationRecords[annoIdx]; // find current annotated record
      }
      // ----------------------------------------------------------------------
      // get Current Thumbnail property Delete/Add/Updated in  thumb canvas:>>>
      if (currentAnnotationLatestData) await this.renderAnnotationBySelectedThumbnailCanvas(currentAnnotationLatestData, eventItem);
      this.loadMoreHorzImages[loadHzIndex].isLoaded = true;
    }
  }

  // get current thumbnail property canvas:
  async renderAnnotationBySelectedThumbnailCanvas(latestAnnotatedRecord: IGeometryAnnotationDto, eventItem: any) {
    await this.annotationVisualCanvasService.invokeCurrentThumbnailInstance(); //init invoke
    // Here get current thumbnail property for render purpose:
    const thumbnailStageId = eventItem.item.stageProperty.id;
    const drawBoxId = eventItem.keyId;
    const thumbnailStageProps = this.annotationVisualCanvasService.getCurrentStageBackgroundImageGroup(thumbnailStageId);
    // ---------------------------------------------------
    if (thumbnailStageProps) { //required
      //Render Remove Annotation:
      if (eventItem.method === STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_REMOVE) {
        this.annotationVisualCanvasService.renderRemovedAnnotationToThumbnailCanvas(thumbnailStageProps, drawBoxId, this.canvasVisualAnnotationViewerRef)
      } else {
        //Render Update/Add Annotation:
        this.renderUpdateOrAddAnnotationToThumbnailCanvas(latestAnnotatedRecord, eventItem, drawBoxId, thumbnailStageProps)
      }
    } else console.error("Thumbnail canvas stage not found")
  }

  renderUpdateOrAddAnnotationToThumbnailCanvas(latestAnnotatedRecord: IGeometryAnnotationDto, eventItem: IEventEmitAnnotation, drawBoxId: string, thumbnailStageProps: IThumbnailRenderInstance) {
    // Get latest db cooridnate
    const polygonPoints = latestAnnotatedRecord.geometry.coordinates; //Latest from DB
    if (eventItem.method === STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_ADD) { //Render Add:
      this.renderBoxInThumbnailWhileAdding({ thumbnailStageProps, drawBoxId, polygonPoints });
    } else if (eventItem.method === STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_UPDATE) {  //Render Update:
      this.annotationVisualCanvasService.renderUpdatePointsToThumbnailCanvas(polygonPoints, drawBoxId, thumbnailStageProps)
    }
  }

  renderBoxInThumbnailWhileAdding(thumbData: { thumbnailStageProps : IThumbnailRenderInstance, drawBoxId: string, polygonPoints: string[] }){
    const currentShapGroupFromMainView = this.annotationVisualCanvasService.shapeGroupIdSetUpByCurrentActiveShapeGroupFromMainView(this.canvasVisualAnnotationViewerRef, thumbData.drawBoxId)
    // get currentShape from main view:
    const newAddShapeGroup = currentShapGroupFromMainView.clone(); //get clone from main viewer:
    // thumbnail viewer property:
    const thumbnailDrawBox = this.annotationVisualCanvasService.renderAddPointsToThumbnailCanvas({ dbCoordinates: thumbData.polygonPoints, drawBoxId: thumbData.drawBoxId, thumbnailStageProps: thumbData.thumbnailStageProps, newAddShapeGroup, thumbnailBgGroup: thumbData?.thumbnailStageProps?.bgGroup })
    //dependings imageRGB thumbnail : (0 1 scale conversion)
    this.annotationVisualCanvasService.setScaleAndUpdateDrawBox(thumbData.polygonPoints, thumbData.thumbnailStageProps, thumbnailDrawBox)
  }

  // rerendering
  //************ */ Load Mor horizontal images:******************
  rerenderAllLoadMoreImagesHorizontal() {
    const annotatedOverAllRecord = this.filteredHorizontalResourceList.filter(elm => elm.annotationRecords.length > 0);
    const tempHroz = this.loadMoreHorzImages.map(elm => {
      return { ...elm, annotationRecords: annotatedOverAllRecord.find(e => e._id === elm._id)?.annotationRecords || elm.annotationRecords }
    });
    this.loadMoreHorzImages = tempHroz;
  }

  // ========================= REVIEW STATUS =============================================
  // onChange Reviewed CheckBox On/Off :
  onChangeReviewedDataResourceStatus(event: any) {
    if (this.currentSelectedCanvas) {
      this.currentSelectedCanvas.isReviewed = event;
      if (this.currentSelectedCanvas.originRecord) {
        const payloadVisualRecord = {
          ...this.currentSelectedCanvas,
          metadata: {
            ...(this.currentSelectedCanvas.originRecord.metadata || {}),
            isReviewed: event
          }
        } as IStageResourceRecord;
        const paramsObject = {
          _id: this.currentSelectedCanvas.dataResourceId,
          body: payloadVisualRecord,
          reviewedStatus: event
        };
        const subscribeUpdateDataSource = this.dataResourceService.updateReviewedStatusToDataResourceApi(paramsObject);
        this.subscriptions.push(subscribeUpdateDataSource);
        this.reviewUpdateToDataResourceRecords(paramsObject);
        // getRegister -> total imagesource count - total reviewed count => 0 means "Completed"
      }
    }
  }

  // Reviewed update to Data Resource list:
  reviewUpdateToDataResourceRecords(event: {_id: string, reviewedStatus: boolean}) {
    const imageResourceRecords = [this.loadMoreHorzImages, this.filteredHorizontalResourceList, this.primaryStoredImageDataSourceList]
    imageResourceRecords.forEach(record => {
      const loadCanvasHIndex = record.findIndex((elem) => elem._id == event._id);
      if (loadCanvasHIndex !== -1) {
        record[loadCanvasHIndex]['isReviewed'] = event.reviewedStatus;
      }
    })
  }

  // ==================================== END API =============================================
  // groupBy tags
  getPrimaryResourceGroupByAssetName(resourceRecord: IStageResourceRecord[], assetName: keyof IStageResourceRecord): ITagResourceGroup {
    return resourceRecord.reduce((acc: ITagResourceGroup, props: IStageResourceRecord) => {
      const objectKey = props[assetName];
      if (!acc[objectKey]) acc[objectKey] = [];
      acc[objectKey].push(props);
      return acc;
    }, {})
  }


  handleLoadCompletionStatus(change: SimpleChange) {
    if (change.currentValue) {
      this.isLoadCompletionSourceSB.next(this.isLoadCompletionStatus);
    }
  }

  handleAnnotationList(change: SimpleChange) {
    if (change.currentValue) {
      this.primaryStoredAnnotationList = change.currentValue;
    }
  }

  handleSelectedGridInput(change: SimpleChange) {
    if (this.selectedImageIdByMap) {
      this.currentSelectByMap(); // link with asset map
      this.selectedImageIdByMap = null; // Reset Property
    }
  }

  handleGalleryLayoutSize(change: SimpleChange) {
    if (change.currentValue && !this.findingPanelOpenStatus) {
      this.galleryCurrentLayoutSize = change.currentValue;
      this.getTriggedisMapLayoutMinimize();
    }
  }


  // --------------------------[  INPUT PROJECTS ]--------------------------
  getReadyToLoadStageCanvasImageListener() {
    this.filteredHorizontalResourceListSB.subscribe((horzCanvasStageRecord) => {
      if (horzCanvasStageRecord) {
        if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) {
          this.getLoadedImageGalleryAllDigitalTwinModule();
        } else if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_GRID) { //pgb-Grid
          this.getLoadedImageGalleryAllAnnotation2dModule();
        }
      }
    });
  }

  // -------------- [DIGITAL TWIN]- ---------------
  getLoadedImageGalleryAllDigitalTwinModule() {
    this.integrationWithActiveSceneDoc();  // Twin : on Click Scence Tab:
  }

  // Scene Docmention:[feature]
  integrationWithActiveSceneDoc() {
    this.canvasDataService.activeSceneObject$.subscribe(activeSceneObject => {
      if (activeSceneObject) {
        this.resetThumbnailSceneAnnotationRef();
        this.activeSceneObject = activeSceneObject;
        const sceneObjectProperty = { ...activeSceneObject?.['properties'] };
        this.activeSceneAnnotateIds = sceneObjectProperty?.['annotation_2d_references'] ? [...sceneObjectProperty['annotation_2d_references']] : [];
        this.isClickedOpenVisualGallery = this.canvasDataService.isImageGalleryOpen
        // clicked on visual button:
        if (this.activeSceneObject && this.isClickedOpenVisualGallery) {
          this.onClickedVisualGalleryButton();  // set as associate-thumbnailViewer false
        } else {
          // clicked on scene component:
          if (this.activeSceneAnnotateIds?.length && this.activeSceneObject) {
            this.getMergeAnnotationConfigureBeforeLoadCanvas();
            this.annotationVisualCanvasService.setImageThumbnailViewerMode({ isThumbnailViewer: true, activeId: '' }); //Set scene image mode thumbnail viewer true
          }else{
            this.resetThumbnailSceneAnnotationRef();
          } 
          this.isDataRecordFound.emit(!this.noDataRecordFound)
          this.initialSelectSeneStatus = true;
        }
      }
    });
  }

  onClickedVisualGalleryButton() {
    this.showViewCanvasLoader = false;
    this.isHorizontalView = true;
    this.hideCanvas = false;
    this.isThumbnailViewer = false;
    this.currentSelectedCanvasIndex = 0;
    this.getSceneAnnotationRecordFromExistingSource(); // Get Annotation records 
    this.annotationVisualCanvasService.setImageThumbnailViewerMode({ isThumbnailViewer: false, activeId: '' }); //scene image select
  }

  setDefaultIntegrateWithViewerModeOfImageCanvas() {
    this.annotationVisualCanvasService.imageThumbnailViewerModeObservable$.subscribe((ImageThumbnailViewerMode => {
      if (ImageThumbnailViewerMode) {
        this.isThumbnailViewer = ImageThumbnailViewerMode.isThumbnailViewer;
        this.activeThumbnailViewerId = ImageThumbnailViewerMode.activeId ?? '';
        this.thumbnailHeightSettingBasedOnViewerMode(); // height setting config
        this.onClickSceneTabAnnotationReference();
      }
    }));
  }

  thumbnailHeightSettingBasedOnViewerMode() {
    if (this.isThumbnailViewer) this.isFindingHeightActual = STAGE_2D_CANVAS_COMMON_VIEW.SCENE_HEIGHT_THUBNAIL;
    else {
      this.isFindingHeightActual = STAGE_2D_CANVAS_COMMON_VIEW.FINDING_HEIGHT_ACTUAL;
      const selectedIndex = this.filteredHorizontalResourceList.findIndex((elem) => elem._id === this.activeThumbnailViewerId);
      this.currentSelectedCanvasIndex = selectedIndex !== -1 ? selectedIndex : this.defaultSelectedCanvasIndex;
    }
    this.filteredHorizontalResourceList.forEach(elem => {
      elem.stageProperty.width = this.isThumbnailViewer ? THUMB_STAGE_HORIZONTAL_VIEW.THUMB_VIEW_WIDTH : THUMB_STAGE_HORIZONTAL_VIEW.WIDTH
    });
  }

  // onCLick Annotated References ['scence tab' or 'visual Button']
  noDataRecordFound = true;
  onClickSceneTabAnnotationReference() {
    this.resetThumbnailSceneAnnotationRef();
    this.resetNdUpdateCanvas2dThumbnail(this.filteredHorizontalResourceList);
  }

  // Tower rotate Handler auto projection:[feature]
  //****** After Tower Rotation Best logic viewer : new Thumbnail Images arrived: *********
  towerRotateBestViewLogicHandler() {
    this.currentSelectedCanvas = null;
    this.resetThumbnailSceneAnnotationRef();
    const thumbnailImages = this.imageRGB.thumbnailImages; //best view logic images:
    this.currentSelectedCanvasIndex = 0;
    //1. get exist data canvas images from without annotation:
    const stageImageRecords = [...this.primaryStoredImageDataSourceList.map(elm => ({ ...elm, annotationRecords: [] }))];
    //2. fetch only current tower thumbnail and bind with existing record resource:
    const shortThumbnails: IStageResourceRecord[] = [];
    thumbnailImages.forEach((elm) => {
      let newStageRecord = stageImageRecords.find((data) => data._id === elm._id);
      if (newStageRecord) {
        shortThumbnails.push(newStageRecord)
      }
    });
    let filteredHorizontalResourceList = shortThumbnails.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
    if (filteredHorizontalResourceList?.length === 0) console.error("Current best view doesn't have camera images")
    // If best view logic image is empty from tower canvas. then only showing all site views.
    filteredHorizontalResourceList = (filteredHorizontalResourceList?.length) ? filteredHorizontalResourceList.slice(0, 10) : [...this.filteredHorizontalResourceList].slice(0, 10);
    //3. filter droplist updation -----------
    this.readyToUpdateSettingByRecords(filteredHorizontalResourceList)
  }

  readyToUpdateSettingByRecords(filteredHorizontalResourceList: IStageResourceRecord[]){
    this.getResetUpdatedDropDownImageList();    // initial Reset DropDown List
    this.getTagsFolderList(filteredHorizontalResourceList); // Get unique tag folders drop list from data sources:
    this.getUpdatedDropDownAndThumbnailByChecked(filteredHorizontalResourceList)  // Filter value Dynamic Updation image droplist 
    filteredHorizontalResourceList = filteredHorizontalResourceList.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
    // 4. Ready to load ----------------------
    this.resetNdUpdateCanvas2dThumbnail(filteredHorizontalResourceList);
  }

  // [digital twin] :Final Data Ready to load more
  resetNdUpdateCanvas2dThumbnail(filteredHorizontalResourceList: IStageResourceRecord[]) {
    const tempFilteredHorizontalResourceList = this.autoTowerProjection ? [...filteredHorizontalResourceList] : this.getModeViewerOfImageList(filteredHorizontalResourceList);
    this.noDataRecordFound = !tempFilteredHorizontalResourceList?.length;
    if (!this.noDataRecordFound) {
      this.loadMoreDataSourceRecordImages(STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW, tempFilteredHorizontalResourceList);  // start load canvas
      if (this.loadMoreHorzImages?.length) {
        // thumbnail stage resize inputs
        if (!this.autoTowerProjection) this.loadMoreHorzImages.sort((a: IStageResourceRecord, b: IStageResourceRecord) => b.annotationRecords.length - a.annotationRecords.length);
        this.loadMoreHorzImages.forEach(elem => {
          elem.stageProperty = {
            ...elem.stageProperty,
            width: this.isThumbnailViewer ? THUMB_STAGE_HORIZONTAL_VIEW.THUMB_VIEW_WIDTH : THUMB_STAGE_HORIZONTAL_VIEW.WIDTH,
            height: this.isThumbnailViewer ? THUMB_STAGE_HORIZONTAL_VIEW.THUMB_VIEW_HEIGHT : THUMB_STAGE_HORIZONTAL_VIEW.HEIGHT,
            scale: elem.verify_checked
          }
        });
        // ** Set default/current Image selected:
        if (!this.isThumbnailViewer) {
          this.mouseWheelDebounceEvent.next(true); // ** Set default/current Image selected:
        }
      }
    }
  }

  resetThumbnailSceneAnnotationRef() {
    this.loadMoreHorzImages = [];
    this.noDataRecordFound = true;
  }
  getMergeAnnotationConfigureBeforeLoadCanvas() {
    this.filteredHorizontalResourceList = [...this.primaryStoredImageDataSourceList]; //Reset & backup Record
    //1. Fetch the api annotation record from annotation ids
    this.getSceneAnnotationRecordFromExistingSource(); // Get Annotation records 
    //2. Get annotation records <-> Binding to exist data canvas images  horizontal.
    this.getCombinedVerticalHorzImageWithAnnotate(this.filteredHorizontalResourceList);
    this.updateAnnotatedSegmentFilterOnly();    // vertical/horizontal thumbnail  // set flag isAnnotated
    this.annotatedSortDataBasedOnHorizontalDataResource(); //sortBy UpdatedAt Date : Annotation
  }

  getModeViewerOfImageList(filteredHorizontalResourceList: IStageResourceRecord[]) {
    const { thumbnailCanvasRecords, mainViewerCanvasRecords } = this.getSplitCanvasViewerImages(filteredHorizontalResourceList);  //only validate annotated records
    return this.isThumbnailViewer ? thumbnailCanvasRecords : mainViewerCanvasRecords;
  }

  getSplitCanvasViewerImages(filteredHorizontalResourceList: IStageResourceRecord[]) {
    const validatedRecords = filteredHorizontalResourceList.filter((elm) => elm["verify_checked"]); //show saved scene only
    // "thumbnailCanvasRecords" used for saved  associate scene , mainViewerCanvasRecords - used for mainviewer
    return { thumbnailCanvasRecords: validatedRecords, mainViewerCanvasRecords: this.isClickedOpenVisualGallery ? [...filteredHorizontalResourceList] : [...filteredHorizontalResourceList.filter((el)=>(el.annotationRecords.length > 0))] }
  }

  getSceneAnnotationRecordFromExistingSource() {
    this.geometryAnnotationDto = [];
    const currentSelectedAnnotatedList = this.primaryStoredAnnotationList.filter((elm) => this.activeSceneAnnotateIds.includes(elm._id ?? ''));
    this.geometryAnnotationDto = [...currentSelectedAnnotatedList];
    // Sort the array by updatedAt using moment in descending order
    this.geometryAnnotationDto.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
    this.geometryAnnotationDto = this.geometryAnnotationDto.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
  }

  //Combined Data[ImageResource + Annotation] 
  getCombinedVerticalHorzImageWithAnnotate(imageThumbnailList: IStageResourceRecord[]) {
    if (imageThumbnailList?.length > 0) {
      for (let i = 0; i < imageThumbnailList.length; i++) {
        let filteredAnnotationDto: IGeometryAnnotationDto[] = [];
        if (this.geometryAnnotationDto) {
          filteredAnnotationDto = this.geometryAnnotationDto.filter(element => element.dataResourceId === imageThumbnailList[i].dataResourceId);
        } else {
          this.annotationVisualCanvasService.removeSelectedStageAnnotation(imageThumbnailList[i]);
        }
        imageThumbnailList[i] = this.updateValidateChecked(imageThumbnailList[i], filteredAnnotationDto);
        imageThumbnailList[i]['annotationRecords'] = filteredAnnotationDto;
      }
    }
  }

  updateValidateChecked(stageRecord: IStageResourceRecord, filteredAnnotationDto: IGeometryAnnotationDto[]) {
    if (filteredAnnotationDto && filteredAnnotationDto.length > 0) {
      let isValid = filteredAnnotationDto.every((element) => element["tags"]?.length && element["tags"].includes(STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG))
      stageRecord["verify_checked"] = isValid;
      stageRecord["verify_removed"] = !isValid;
    } 
    return stageRecord
  }

  updateAnnotatedSegmentFilterOnly() {
    const dataSourceLoadMoreList = [this.filteredHorizontalResourceList];
    dataSourceLoadMoreList.forEach(imageRecord => {
      imageRecord.forEach(elem => {
        elem["isAnnotated"] = elem.annotationRecords.length > 0;
      });
    });
  }

  // DESC dataImage :Sort the array based on the latest annotation record '_id' in each child array
  annotatedSortDataBasedOnHorizontalDataResource() {
    const dataSourceLoadMoreList = [this.filteredHorizontalResourceList];
    dataSourceLoadMoreList.forEach(imageRecord => {
      imageRecord.sort((a: IStageResourceRecord, b: IStageResourceRecord) => {
        const aLatestDate = moment.max(a.annotationRecords.map((record: { updatedAt: moment.MomentInput; }) => moment(record.updatedAt)));
        const bLatestDate = moment.max(b.annotationRecords.map((record: { updatedAt: moment.MomentInput; }) => moment(record.updatedAt)));
        let result = 0;
        if (bLatestDate.isAfter(aLatestDate)) {
          result = 1;
        } else if (bLatestDate?.isBefore(aLatestDate)) {
          result = -1;
        }
        return result;
      });
    });
  }

  // -------------- [IMAGE PROCESSING]- ---------------
  getLoadedImageGalleryAllAnnotation2dModule() {
    if (this.splitScreenViewerMode || this.isOrthoMapPreviewMode) { //4.thermal split scren visual
      this.getStartLoadMoreTowerCanvasImages(); //* load more canvas thumbnails
      this.initialFirstTimeDataResourceLoad();
    }else{
      this.onCheckExistRemovedFindings();// reset finding If removed
      this.onSelectiveAssetTabFromLeftPanel() // 1.Binding with assets: listener props
      this.onSelectiveAssetAnnotationFromLeftPanel(); // 2.Annotation with finding inputs:
      this.getActiveBackgroundImageGroup();  // getActive current image main viewer: To use active annotation to open finding panel auto
      // this.onSelectiveCurrentImageGalleryResource(); [feature] //3.Select/onClick image gallery name from left asset panel Observ ***:
    }
    this.initialPropertyStatusUpdation();
  }

  //  SET ACTIVE ASSET/ANNOTATION FROM LEFT PANEL
  onSelectiveAssetTabFromLeftPanel() {
    const activeTowerAssetSubscription = this.dataResourceService.activeTowerAssetObserv$.subscribe({
      next: (currentAssetObject) => {
        if (currentAssetObject && this.currentTabClicked === LISTING_MODES.ASSETS) {
          this.currentTabClicked = LISTING_MODES.ASSETS;
          this.currentActiveTowerAssetDataResourceImages(currentAssetObject);
        }
      }
    });
    this.subscriptions.push(activeTowerAssetSubscription);
  }

  //II. onClick Set Active/Select annotation:
  onSelectiveAssetAnnotationFromLeftPanel() {
    const activeFindingSubscription = this.dataResourceService.activeAnnotatedCanvasImageObserv$.subscribe({
      next: (currentAnnotatedObjectId) => {
        if (currentAnnotatedObjectId) {
          this.currentAnnotatedTabClickedResetMode();
          this.currentActiveAnnotatedCanvasImageFromFindings(currentAnnotatedObjectId)
        }
      }
    });
    this.subscriptions.push(activeFindingSubscription);
  }

  currentAnnotatedTabClickedResetMode() {
    this.selectedImageIdByMap = null;
    this.currentTabClicked = LISTING_MODES.FINDINGS;
    this.dataResourceService.setActiveTowerAsset(null);
  }

  // Get active drawbox & show finding panel as well as
  getActiveBackgroundImageGroup() {
    // get active backgroupImage:
    const backGroupImageSubscription = this.annotationVisualCanvasService.activebackgroundImgGroupObservable$.subscribe((backgroundImgGroup) => {
      if (backgroundImgGroup) {
        this.activeCanvasBackgroundImgGroup = backgroundImgGroup;
        // while onClick active annotation box as well as open finding panel:
        if (this.activeCanvasBackgroundImgGroup && this.isFindingAnnotatedClicked) {
          this.setActiveDrawBoxAndOpenFindingPanel(this.activeCanvasBackgroundImgGroup);
        }
      }
    })
    this.subscriptions.push(backGroupImageSubscription);
  }

  // get active drawbox & show finding panel as well as
  setActiveDrawBoxAndOpenFindingPanel(backgroundImageGroup: string) {
    const drawBox = this.annotationVisualCanvasService.getCurrentDrawBoxProperty(this.selectedAnnotatedIdFromFinding, backgroundImageGroup);
    if (drawBox) this.canvasVisualAnnotationViewerRef.onDrawBoxCurrentActive(null, drawBox); // it will be open findings panel
  }

  onSelectiveCurrentImageGalleryResource() {
    const unSubscriptionActiveGalleryImage = this.dataResourceService.seActiveCurrentGalleryImageObservable$.pipe(debounceTime(500)).subscribe({
      next: (dataResourceId) => {
        if (dataResourceId) {
          this.selectedImageIdByImage = dataResourceId;
          this.currentActiveTowerAssetByMap();
        }
      }
    });
    this.subscriptions.push(unSubscriptionActiveGalleryImage);
  }

  initialPropertyStatusUpdation() {
    this.initialSelectMapStatus = true;
    this.initialSelectAssetStatus = true;
    this.initialSelectedAnnotationIdByFindingStatus = true;
    this.initialSelectedImageIdByImageStatus = true;
  }

  // I. onClick Set Active/Select Asset: [INPUTS] 
  initialSelectAssetStatus = false;
  currentActiveTowerAssetDataResourceImages(currentAssetObject: IAsset) {
    this.resetActiveResourceImages();
    // Process sortImages by asset Lat, Lng:
    const getAssetwithNearestImages = this.dataResourceService.processSortImageResourceBySelectedAsset(currentAssetObject, this.filteredHorizontalResourceList) //this.primaryStoredImageDataSourceList
    this.filteredHorizontalResourceList = getAssetwithNearestImages['nearestImages'];
    this.getReadyToLoadCanvasWithImageList(this.filteredHorizontalResourceList);
    this.currentSelectByMap(); // link with asset map
  }
  onCheckExistRemovedFindings() {
    const unSubscriptionRemovedFindings = this.annotationVisualCanvasService.removedFindsSubjectObservable$.pipe().subscribe({
      next: (latestFindingsAnnotationList) => {
        if (latestFindingsAnnotationList?.length) {
          latestFindingsAnnotationList.forEach((element) => {
            this.filteredHorizontalResourceList.forEach(item => {
              item.annotationRecords?.forEach((record: IGeometryAnnotationDto) => {
                if (record.findingId === element.findingId && record._id === element.annotationId) {
                  record.assetFindings = null;
                  record.findingId = '';
                }
              });
            });
          });
        }
      }
    });
    this.subscriptions.push(unSubscriptionRemovedFindings);
  }

  resetActiveResourceImages() {
    this.isInsufficientDataResource = false;
    this.currentSelectedCanvas = null;
    this.isFindingAnnotatedClicked = false;
    this.loadMoreHorzImages = [];
    this.selectedAnnotatedIdFromFinding = '';
    this.annotationVisualCanvasService.setActivebackgroundImgGroup(null);
    if(this.currentTabClicked === LISTING_MODES.ASSETS) this.dataResourceService.setActiveAnnotatedCanvas(null); //reset activeFindings
    if(this.currentTabClicked === LISTING_MODES.FINDINGS) this.dataResourceService.setActiveTowerAsset(null);
  }

  // II. onClick Set Active/Select Map: [INPUTS] 
  initialSelectMapStatus = false;
  currentActiveTowerAssetByMap() {
    this.resetActiveResourceImages();
    this.getReadyToLoadCanvasWithImageList(this.filteredHorizontalResourceList);
    this.loadMoreHorzImages.forEach((canvasItem, idx) => {
      const { originFulId } = canvasItem;
      if (originFulId && this.selectedImageIdByMap === originFulId) {
        const openFindingPanel = this.annotationVisualCanvasService.getActiveFindingPanelStatus()
        this.getPreviewMainByClickImg(canvasItem, idx, !openFindingPanel);
        if (!this.isFindingAnnotatedClicked) this.scrollToView();
      }
    })
  }

  currentSelectByMap() {
    if (this.currentTabClicked === LISTING_MODES.ASSETS) {
      this.selectMapFromAssetTab();
    } else {
      this.selectMaFromFindingTab();
    }
  }

  selectMapFromAssetTab() {
    if (this.selectedImageIdByMap) {
      this.resetActiveResourceImages();
      this.getReadyToLoadCanvasWithImageList(this.filteredHorizontalResourceList);
      this.loadMoreHorzImages.forEach((canvasItem, idx) => {
        const { originFulId } = canvasItem;
        if (originFulId && this.selectedImageIdByMap === originFulId) {
          const openFindingPanel = this.annotationVisualCanvasService.getActiveFindingPanelStatus()
          this.getPreviewMainByClickImg(canvasItem, idx, !openFindingPanel);
          if (!this.isFindingAnnotatedClicked) this.scrollToView();
        }
      })
      this.selectedImageIdByMap = null; // Reset Property
    }
  }

  selectMaFromFindingTab() {
    const imageRecordsWithFindings = this.filteredHorizontalResourceList.filter(elm =>
      elm.annotationRecords.some((data: { findingId: null | string; }) => data.findingId !== null)
    );
    if (imageRecordsWithFindings?.length) {
      const existingRecord = imageRecordsWithFindings.find((record) => record.dataResourceId === this.selectedImageIdByMap)
      if (existingRecord) {
        const annotationRecords = existingRecord.annotationRecords || [];
        if (annotationRecords?.length) {
          const getFindingExist = annotationRecords.find((data: { findingId: string | null; }) => data.findingId);
          if (getFindingExist) {
            this.annotationVisualCanvasService.setOpenImageviewer(true);
            this.dataResourceService.setActiveAnnotatedCanvas(getFindingExist?._id)
          } else this.getStatusForNoDataFound()
        }
      } else this.getStatusForNoDataFound()
    } else this.getStatusForNoDataFound()
  }

  // II. onClick Annotation Findings:Feature use 
  initialSelectedAnnotationIdByFindingStatus = false;
  currentActiveAnnotatedCanvasImageFromFindings(annotatedId: string) {
    this.selectedAnnotatedIdFromFinding = annotatedId;
    this.resetImageViewerForFindingClicked();
    // Filter to  global stored annotation with imageresource:
    const newAnnotatedFindingRecord: IStageResourceRecord[] = [];
    const getAnnotatedData: IGeometryAnnotationDto[] = this.primaryStoredAnnotationList.filter((elm) => elm._id === annotatedId) || [];
    if (getAnnotatedData?.length) {
      const imageResourceRecord = this.primaryStoredImageDataSourceList.find((elm) => elm.dataResourceId === getAnnotatedData[0].dataResourceId) || null;
      this.currentSelectedCanvasIndex = this.defaultSelectedCanvasIndex;
      if (imageResourceRecord) {
        newAnnotatedFindingRecord.push({ ...imageResourceRecord, annotationRecords: getAnnotatedData })
        const filteredHorizontalResourceList = newAnnotatedFindingRecord.filter((obj, index, self) => index === self.findIndex(item => item._id === obj._id));
        this.getReadyToLoadCanvasWithImageList(filteredHorizontalResourceList);
        // Get Listen active background images & Get active annotation ,open finding panel 
      } else this.getStatusForNoDataFound();
    } else this.getStatusForNoDataFound();
  }

  resetImageViewerForFindingClicked(){
    this.isInsufficientDataResource = false;
    this.isFindingAnnotatedClicked = true;
    this.loadMoreHorzImages = [];
    this.annotationVisualCanvasService.setActivebackgroundImgGroup(null);
  }

  // III. onClick Images :Feature use 
  initialSelectedImageIdByImageStatus = false;
  // Gallery onSelect image name from left panel gallery
  currentActiveImageGalleryResource() {
    this.isInsufficientDataResource = false;
    this.isFindingAnnotatedClicked = false;
    this.annotationVisualCanvasService.setActiveFindingPanel(false); //default close;
    const chunkedArrays = this.dataResourceService.chunkArray(this.filteredHorizontalResourceList, this.imagesPerChunk);   // get chunk 10 from imageGalleryesourceId // [20,21,22,..25]
    const foundArrayObjectHorz = this.dataResourceService.findObjectById(chunkedArrays, this.selectedImageIdByImage);
    if (foundArrayObjectHorz) {
      const temploadMoreHorzImages = chunkedArrays[foundArrayObjectHorz['key']];  // stored as loadMore[1:[]..2:[]..10:[]]
      this.loadMoreHorzImages = temploadMoreHorzImages;
      const currentSelectedItemFromGallery = foundArrayObjectHorz.item || {};
      this.currentSelectedCanvasIndex = this.loadMoreHorzImages.findIndex((elm => elm._id === currentSelectedItemFromGallery._id));
    } else {
      this.loadMoreHorzImages = [];
      this.currentSelectedCanvasIndex = 0;
    }
    this.loadMoreDataSourceRecordImages(STAGE_2D_CANVAS_COMMON_VIEW.TAG_HORIZONTAL_VIEW, this.filteredHorizontalResourceList);
    this.mouseWheelDebounceEvent.next(true); // ** Set default/current Image selected:
  }

  // -------------- [IMAGE PROCESSING]- Attached with [FINDING - PANEL]---------------
  // OnListener From left Panel 
  leftSidePanelWithImage2dGalleryBinding() {
    this.integrationWithFindingPanel(); // PGB
    this.setDefaultIntegrateWithViewerModeOfImageCanvas() // twin: default viewer:
  }

  integrationWithFindingPanel() {
    //Get active annotation & open finding panel:
    this.getCurrentActiveAnnotationAndSetFindingPanel();
    //  After Saved Finding Emitter:
    this.afterSavedFindingEmittedData();
    // Closed Finding Panel Status
    this.getClosedFindingPanel();
  }

  // To Open finding panel while create or select Annotation from canvas
  getCurrentActiveAnnotationAndSetFindingPanel() {
    const unsubscribeActiveAnnotated = this.annotationVisualCanvasService.activeAnnotationMarkedObservable$.subscribe((activeAnnotation: any) => {
      this.currentSelectedAnnotation = activeAnnotation;
      const isOpenFindingPanel = !!activeAnnotation;
      this.annotationVisualCanvasService.setActiveFindingPanel(isOpenFindingPanel);
    })
    this.subscriptions.push(unsubscribeActiveAnnotated);
  }

  afterSavedFindingEmittedData() {
    const saveFindUnSubcript = this.annotationVisualCanvasService.saveFindingActivateObservable$.subscribe((data => {
      if (data) {
        this.currentSavedFindingPayload = data; //Emitting Finding Data:
        if (this.currentSelectedAnnotation) {
          const drawBox = this.currentSelectedAnnotation.drawBox || null; // if Ai annotation means api updation to validated tag 
          // updated finding response save to dataresource records:
          this.findingResponseUpdateToResourceRecord(this.currentSelectedCanvas?._id, this.currentSelectedAnnotation.annotationId, this.currentSavedFindingPayload)
          // api and ai update to dataresource record part 1:
          if (drawBox?.getAttr('aiTag')) this.afterSaveFindingUpdationToDataImageRecords();  // Item changes To DataResource Records:
          // image canvas viewer updation part 2:
          this.afterSaveFindingUpdationToCanvasViewer();  // Item changes To canvas Image Viewer:
        }
      }
    }));
    this.subscriptions.push(saveFindUnSubcript);
  }

  findingResponseUpdateToResourceRecord(dataResourceId: string, annotateId: string, findingResp: any) {
    const imageResourceRecords = [this.filteredHorizontalResourceList, this.loadMoreHorzImages, this.annotatedImageHorizontalResourceList]
    imageResourceRecords.forEach(record => {
      const recordIndex = record.findIndex((elem) => elem._id == dataResourceId);
      if (recordIndex > -1) {
        const filteredAnnotateRecordIndex = record[recordIndex].annotationRecords.findIndex((elem: IGeometryAnnotationDto) => elem._id == annotateId);
        if (filteredAnnotateRecordIndex > -1) {
          record[recordIndex].annotationRecords[filteredAnnotateRecordIndex]['findingId'] = findingResp._id;
          record[recordIndex].annotationRecords[filteredAnnotateRecordIndex]['assetFindings'] = findingResp;
        }
      }
    })
  }

  async afterSaveFindingUpdationToCanvasViewer() {
    // current Bg group image: change made viewer :update blue and hide tooltips
    const drawBox = this.currentSelectedAnnotation?.drawBox ?? null
    if (drawBox) drawBox.setAttr('findingId', this.currentSavedFindingPayload?._id);
    if (this.currentSavedFindingPayload?.['_id']) this.canvasVisualAnnotationViewerRef.currentActiveFindingId = this.currentSavedFindingPayload._id; // updated Finding id:
    if (this.currentSelectedAnnotation && this.canvasVisualAnnotationViewerRef.drawBox.id() === this.currentSelectedAnnotation.drawBox.id()) this.canvasVisualAnnotationViewerRef.drawBox.setAttr('findingId', this.currentSavedFindingPayload?._id);
    const backgroundImgGroup = this.canvasVisualAnnotationViewerRef.backgroundImgGroup; //[Image, Line, Group, Line, Group]
    if (this.currentSelectedAnnotation) this.annotationVisualCanvasService.findingSavedAfterSelectiveAnnotationConfigure(backgroundImgGroup, this.currentSelectedAnnotation.drawBox);
    //  mark as stroke primary: in thumnail //  thumbnai canvas render update:
    await this.annotationVisualCanvasService.invokeCurrentThumbnailInstance(); //init invoke
    this.annotationVisualCanvasService.aiAnnotationMakedAsValidateInThumbnailCanvas(this.currentSelectedCanvas)
  }

  afterSaveFindingUpdationToDataImageRecords() {
    if (this.currentSavedFindingPayload) {
      const getSavedFindAnnotateId = this.currentSavedFindingPayload?.annotations?.length ? this.currentSavedFindingPayload?.annotations[0] : ''
      const listDatasource = [this.loadMoreHorzImages]; //contains only 
      listDatasource.forEach(resourceList => {
        const currentSelectedCanvasId = this.currentSelectedCanvas?._id;
        const selectedResource = resourceList.find(resource => resource._id === currentSelectedCanvasId);
        if (selectedResource) {
          const annotatedRecord = selectedResource.annotationRecords.find((record: { _id: string; }) => record._id === getSavedFindAnnotateId);
          if (annotatedRecord) {
            annotatedRecord.tags = [STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG];
            //Add class Label:--> annotatedRecord.classLabels = [classLabels]; 
            // Update API call:
            const payloadItem = { ...annotatedRecord, tags: [STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG] } as IGeometryAnnotationDto;
            this.annotationVisualCanvasService.updateAiTagCanvas(getSavedFindAnnotateId, payloadItem); // ai tag to change validate
          }
        }
      });
    }
  }

  getClosedFindingPanel() {
    if (!this.findingPanelOpenStatus && this.canvasVisualAnnotationViewerRef) {
      this.canvasVisualAnnotationViewerRef.onResetDrawBoxWithClosedFindingPanel();
    }
  }
  // ========================== [ DIGITAL TWIN ] =============================
  // save Annotation To Scence Documentation :
  annotation_2d_references: string[] = [];
  get2DAnnotateReferenceFromStageDataResource() {
    this.annotation_2d_references = [];
    if (this.filteredHorizontalResourceList?.length) {
      this.annotation_2d_references = this.filteredHorizontalResourceList.map(stageRecord => {
        const annotationRecords = stageRecord?.annotationRecords;
        return annotationRecords?.map((elm: { _id: string }) => elm._id) ?? [];
      }).flat();
    }
  }

  //I . Scence Object property updated:
  sceneObjectPropertyByAnnotation() {
    const sceneObject: ISceneObject = this.canvasDataService.getSceneDefintion().sceneObjects.find((sceneObj: ISceneObject) => sceneObj.id === this.activeSceneObject?.id);
    // Convert the array to a Set to remove duplicates
    const uniqueAnnotateIds = [...new Set(this.activeSceneAnnotateIds)];
    sceneObject.properties['annotation_2d_references'] = [...uniqueAnnotateIds];
    this.canvasDataService.updateSceneObjectById(sceneObject?.id, sceneObject);
    this.activeSceneAnnotateIds = [...uniqueAnnotateIds];
  }

  private changeRightSideOffset(value: number) {
    this.rightSideOffest = value;
  }

  // II . canvas image ---- validate status
  async onCheckStatus(verifyStatus: boolean,event: IStageResourceRecord) {
      // update to current record:
      if(event.annotationRecords?.length > 0){
        event.annotationRecords = event.annotationRecords.map((record: IGeometryAnnotationDto)=>({
        ...record,
        tags : verifyStatus ? [...(record.tags || []), STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG] : [STAGE_2D_CANVAS_COMMON_VIEW.MANUAL_TAG]
      }))
      event.verify_checked = verifyStatus;
      event.verify_removed = !verifyStatus;
      // update to api:
      event.annotationRecords.forEach(async (annotation: { _id: string; }) => {
        await this.updateValidateCanvas({ keyId: annotation._id, payload: annotation });  
      });
      // Update to datasource record:
      const annotatedIds =  event.annotationRecords.map((record: { _id: string }) => record._id);
      this.checkValidateUpdateToAllRecords(annotatedIds, verifyStatus);
    }
  }

  checkValidateUpdateToAllRecords(annotatedIds: string[], verifyStatus: boolean){
    this.primaryStoredAnnotationList = this.primaryStoredAnnotationList.map((record: IGeometryAnnotationDto)=>( annotatedIds.includes(record._id as string) ? {
      ...record,
      tags : verifyStatus ? [...(record.tags || []), STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG] : [STAGE_2D_CANVAS_COMMON_VIEW.MANUAL_TAG]
    } : { ...record }));
    this.primaryStoredImageDataSourceList = this.annotationVisualCanvasService.updateVerifyToDataResourceList(this.primaryStoredImageDataSourceList, annotatedIds, verifyStatus) as IStageResourceRecord[];
    this.filteredHorizontalResourceList = this.annotationVisualCanvasService.updateVerifyToDataResourceList(this.filteredHorizontalResourceList, annotatedIds, verifyStatus) as IStageResourceRecord[];
    this.loadMoreHorzImages = this.annotationVisualCanvasService.updateVerifyToDataResourceList(this.loadMoreHorzImages, annotatedIds, verifyStatus) as IStageResourceRecord[];
  }

  // on Change Auto Projection:
  onChangeAutoProjection(event: Event) {
    this.autoTowerProjection = (event.target as HTMLInputElement).checked;
  }
  // -------------------------------------------------------------
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.annotationVisualCanvasService.setImageThumbnailViewerMode({ isThumbnailViewer: true, activeId: '' }); 
  }

}