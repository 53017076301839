<div class="version-info-save-wrap">
  <div class="header-wrap">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <span class="title-wrap">
          <h6 class="header-title">Save New Version</h6>
          <p class="header-sub-title">{{existingVersionName}}</p>
        </span>
        <span class="close-btn-wrap" (click)="onCloseVersion()">
          <img class="close-img" [src]="'/icon/close.svg' | assetUrl" alt="close">
        </span>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="content-wrap">
    <form [formGroup]="versionInfoFormGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <span class="header-title">
            Version Information
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="input-wrap">
            <label for="versionName">Version Name<span class="required-star">*</span></label>
            <input id="versionName" type="text" class="ip-form-custom" placeholder="Version Name" formControlName="versionName">
            @if (versionInfoFormGroupControls['versionName'].errors?.['required']
            && versionInfoFormGroupControls['versionName'].touched) {
            <p class="warning-block">
              {{requiredText}}
            </p>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" class="btn version-btn save">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>