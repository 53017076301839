@if (displayModal) {
<div class="component-container">
  <div class="component-container-layout">
    <div class="component-container-layout-header">
      <div class="d-flex justify-content-between">
        <span class="component-container-layout-header-title">
          DELETE {{ confirmDialog }}
        </span>
        <span
          class="component-container-layout-header-close"
          (click)="closeModal()"
        >
          <img [src]="'/icon/close.svg' | assetUrl" alt="round" />
        </span>
      </div>
      <div class="component-container-layout-header-divider"></div>
    </div>
    <div class="component-container-layout-content">
      <div class="text-center warning-container">
        @if (loadingInfo) {
        <div
          class="loader-wrapper d-flex justify-content-center align-items-center"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        } @else {
        <img class="p-2" [src]="'/icon/alert.svg' | assetUrl" alt="alert" />
        }
        <h2 class="component-warning-title p-1">
          Are you sure you want to delete this {{ confirmDialog }}?
        </h2>
        <p class="component-warning-sub-title">
          You won’t be able to revert this action.
        </p>
        <div>
          <button class="warning-cancel-btn mr-4" (click)="onCancelClick()">
            Cancel
          </button>
          <button
            class="warning-delete-btn"
            (click)="onConfirmClick()"
            [disabled]="loadingInfo"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" (click)="closeModal()"></div>
}
