import { IDataResourceRecord } from './index';

export interface IAsset {
  _id: string;
  assetHierarchy: { [key: string]: string };
  assetName: string;
  assetLatitude: number;
  assetLongitude: number;
  lngDMS?: string;
  latDMS?: string;
  nearbyAsset?: IDataResourceRecord[];
  metadata?: IAttachedAssetMetaData;
}

export interface IImage {
  _id: string;
  name: string;
  lng: number;
  lat: number;
  angle: number;
}

export interface IMapZoom {
  lng: number;
  lat: number;
  zoom?: number;
  padding?: { top: number; bottom: number; left: number; right: number };
}

export interface IAttachedAssetMetaData {
  [key: string]: string;
}

export interface GeoJSONPoint {
  title: string;
  type: string;
  coordinates: [number, number];
  bbox?: number[];
}
export interface ResourceError {
  error: { message: string; meta: { resourceId: string } };
}

export interface Attachements {
  original: IDataResourceRecord[];
  duplicate: string[];
}

export interface MarkerState {
  state: string;
}
