import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Routes } from "./routes";
import { Observable, take ,Subject, BehaviorSubject} from "rxjs";
import { IFindingsListing, IInspectionFindingsResponse, ISaveAnnotation } from "../interfaces";
import { getQueryByParams } from "../utils/get-query-by-params";

@Injectable({
  providedIn: 'root',
})
export class FindingsService {
  constructor(private httpClient: HttpClient) {}

  private findingsCreatedSubject = new Subject<any>();

  editingTextFields = false;
  selectedFindingId = new BehaviorSubject('');
  findingsList: IFindingsListing[] = [];

  // Get Components List
  getComponents(options: any): Observable<any> {
    const { workspaceId, projectId, siteId, missionId, nextCursor } = options;
    const queryParams = getQueryByParams({
      projectId,
      siteId,
      missionId,
      workspaceId,
      labelType: 'Component',
      ...(nextCursor ? { nextCursor } : {}),
    });
    return this.httpClient
      .get<any>(`${Routes.LABELS}?${queryParams}`)
      .pipe(take(1));
  }

  // Get Finding Types
  getFindingTypes(options: any): Observable<any> {
    let url = `${Routes.FINDING_LABELS}?`;
    url += `pageLimit=50&sortBy=name&`;
    if (options.searchText) url += `name=${options.searchText}&`;
    if(options.nextCursor) url+=`nextCursor=${options.nextCursor}&`
    return this.httpClient.get<any>(url).pipe(take(1));
  }

  // save new findings
  saveNewFindings(data: ISaveAnnotation) {
    return this.httpClient
      .post<ISaveAnnotation>(`${Routes.FINDING_SAVE}`, data)
      .pipe(take(1));
  }

  getAssetList({ workspaceId }: { workspaceId: string }): Observable<any> {
    let url = `${Routes.GET_ASSETS_LIST}?`;
    if (workspaceId) url += `&workspaceId=${workspaceId}`;
    return this.httpClient.get<any>(url).pipe(take(1));
  }

  // update new findings
  updateFindings({
    findingId,
    data,
    softUpdate,
    association,
  }: {
    findingId: string;
    data: any;
    softUpdate?: boolean;
    association?: boolean;
  }) {
    const endpoint = `${Routes.FINDING_SAVE}/${findingId}${
      softUpdate ? '/dissociate-annotations' : ''
    }${association ? '/associate-annotations' : ''}`;
    return this.httpClient.patch<any>(endpoint, data).pipe(take(1));
  }

  // Get Finding by id
  getFindingById(findingId: string) {
    return this.httpClient
      .get<any>(`${Routes.FINDING_SAVE}/${findingId}`)
      .pipe(take(1));
  }

  getFindingsByMissionId(params: {
    missionId: string;
    pageLimit?: number;
    nextCursor?: string;
  }): Observable<IInspectionFindingsResponse> {
    let queryParams = '';
    Object.keys(params).forEach((paramKey) => {
      if (!params[paramKey as keyof typeof params]) return;
      queryParams += `${paramKey}=${params[paramKey as keyof typeof params]}&`;
    });
    return this.httpClient.get<IInspectionFindingsResponse>(
      `${Routes.GET_FINDINGS}?${queryParams}`,
    );
  }

  getFindingsAnnotationsoPopulated(params: {
    missionId: string;
    pageLimit?: number;
    nextCursor?: string;
  }) {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((paramKey) => {
      const value = params[paramKey as keyof typeof params];
      if (value !== undefined && value !== null) {
        httpParams = httpParams.set(paramKey, value.toString());
      }
    });
    httpParams = httpParams.set('populateAnnotations', 'true');
    return this.httpClient
      .get<IInspectionFindingsResponse>(`${Routes.GET_FINDINGS}`, {
        params: httpParams,
      })
      .pipe(take(1));
  }

  deleteFindingById(findingId: string) {
    return this.httpClient
      .delete<any>(`${Routes.GET_FINDINGS}/${findingId}`)
      .pipe(take(1));
  }

  emitFindingsCreated(data: any) {
    this.findingsCreatedSubject.next(data);
  }

  onFindingsCreated() {
    return this.findingsCreatedSubject.asObservable();
  }
}
