import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { FileNameFromUrllPipe, AssetUrlPipe, removeWhiteSpacePipe, ToClassNamePipe, GpsCoordinatePipe, TemperatureConversionPipe, PropertyDataMappinglPipe } from '../pipes';
import { IconsModule } from './icons/icons.module';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { CustomConfirmPopupComponent } from './components/custom-confirm-popup/custom-confirm-popup.component';
import { ToasterNotifyComponent } from './components/toaster-notify/toaster-notify.component';
import { CustomNotificationComponent } from './components/custom-notification/custom-notification.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomAccordionComponent } from './components/custom-accordion/custom-accordion.component';
import { FindingsListingComponent } from './components/findings-listing/findigs-listing.component';
import { AnnotationTabComponent } from './components/annotation-tab/annotation-tab.component';
import { PropertiesFormComponent } from './components/properties-form/properties-form.component';
import { VersionInfoListComponent } from './components/version-info-list/version-info-list.component';
import { VersionInfoSaveComponent } from './components/version-info-save/version-info-save.component';
import { CustomComponentDeleteModalComponent } from './components/custom-component-delete-modal/custom-component-delete-modal.component';
import { CustomComponentImageModalComponent } from './components/custom-component-image-modal/custom-component-image-modal.component';
import { AnnotationCardComponent } from './components/annotation-card/annotation-card.component';
import { CustomDropdownComponent } from 'projects/digital-twin/src/app/shared/components/custom-dropdown/dropdown.component';
import { CustomPropertyModalComponent } from 'projects/digital-twin/src/app/shared/components/custom-property-modal/custom-property-modal.component';
import { TrimFilterPipe } from 'projects/digital-twin/src/app/shared/pipes/trim-filter.pipe';
import { permissionGuardDirective } from 'projects/digital-twin/src/app/directives/permission.directive';
import { ImageCanvasViewerComponent } from "./components/image-canvas-viewer/image-canvas-viewer.component";

const COMPONENTS = [
  HttpLoaderComponent,
  CustomConfirmPopupComponent,
  ToasterNotifyComponent,
  CustomNotificationComponent,
  ModalComponent,
  PaginationComponent,
  CustomAccordionComponent,
  FindingsListingComponent,
  AnnotationTabComponent,
  PropertiesFormComponent,
  VersionInfoListComponent,
  VersionInfoSaveComponent,
  CustomComponentDeleteModalComponent,
  CustomComponentImageModalComponent,
  AnnotationCardComponent
];

const PIPES = [
  AssetUrlPipe,
  FileNameFromUrllPipe,
  removeWhiteSpacePipe,
  ToClassNamePipe, 
  TemperatureConversionPipe,
  PropertyDataMappinglPipe,
]

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...PIPES
    ],
    exports: [
        ...COMPONENTS,
        ...PIPES,
        GpsCoordinatePipe,
        permissionGuardDirective
    ],
    imports: [
        CommonModule,
        IconsModule,
        FormsModule,
        GpsCoordinatePipe,
        ReactiveFormsModule,
        BsDatepickerModule,
        CustomDropdownComponent,
        CustomPropertyModalComponent,
        TrimFilterPipe,
        permissionGuardDirective,
        ImageCanvasViewerComponent
    ]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
