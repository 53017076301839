import { HttpClient } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { Routes } from "./routes";
import { Observable, lastValueFrom, take } from "rxjs";
import { ILabel, ILabelManagementData, ILabelManagementItem, ILabelTypeItem, IPostInspectionLabel, IPostLabelTemplate } from "../interfaces/label-management.interface";
import { INSPECTION_LABEL_NAME, LABEL_MANAGEMENT_TABS, TABLE_DATA } from "../constants";
@Injectable({
  providedIn: 'root'
})
export class LabelManagementService {
  constructor(
    private httpClient: HttpClient,
  ) {    
    this.fetchLabels(LABEL_MANAGEMENT_TABS.INSPECTION_FINDING)
    this.fetchLabels(LABEL_MANAGEMENT_TABS.COMPONENT)
    this.fetchLabels(LABEL_MANAGEMENT_TABS.MEASUREMENT)
    this.fetchLabels(LABEL_MANAGEMENT_TABS.SEVERITY)
  }

  inspectionLabelList = signal<ILabelManagementData>([])
  measurementList = signal<ILabelTypeItem[]>([])
  componentTypeList = signal<ILabelTypeItem[]>([])
  severityList = signal<ILabelTypeItem[]>([])

  appendAsFirstElement(data: ILabelManagementItem) {
    this.inspectionLabelList.update((val) => [data, ...val])
  } 

  appendAsFirstElementLabel(data: ILabelTypeItem, type: string) {
    switch (type) {
      case LABEL_MANAGEMENT_TABS.COMPONENT:
        this.componentTypeList.update((val) => [data, ...val])
        break;
      case LABEL_MANAGEMENT_TABS.MEASUREMENT:
        this.measurementList.update((val) => [data, ...val])
        break;
      case LABEL_MANAGEMENT_TABS.SEVERITY:
        this.severityList.update((val) => [data, ...val])
        break;
    }
  }

  updateLabelList(data: ILabelTypeItem, type: string) {
    switch (type) {
      case LABEL_MANAGEMENT_TABS.COMPONENT:
        this.componentTypeList.update((val) => val.map((item) => item._id === data._id ? data : item))
        this.updateInspectionLabel(data, INSPECTION_LABEL_NAME.COMPONENT_LABEL);
        break;
      case LABEL_MANAGEMENT_TABS.MEASUREMENT:
        this.measurementList.update((val) => val.map((item) => item._id === data._id ? data : item))
        break;
      case LABEL_MANAGEMENT_TABS.SEVERITY:
        this.severityList.update((val) => val.map((item) => item._id === data._id ? data : item))
        this.updateInspectionLabel(data, INSPECTION_LABEL_NAME.SEVERITY_LABEL);
        break;
    }
  }

  updateInspectionLabelList(data: ILabelManagementItem) {
    this.inspectionLabelList.update((val) => val.map((item) => item._id === data._id ? data : item))
  }

  fetchLabels(labelManagementTab: string) {
    let list: WritableSignal<(ILabelTypeItem| ILabelManagementItem)[]>;
    let apiCall: (nextCursor?: string) => Observable<any>;
    switch(labelManagementTab) {
      case LABEL_MANAGEMENT_TABS.COMPONENT:
        list = this.componentTypeList;
        apiCall = this.getlabels.bind(this, LABEL_MANAGEMENT_TABS.COMPONENT);
        break;
      case LABEL_MANAGEMENT_TABS.INSPECTION_FINDING:
        list = this.inspectionLabelList;
        apiCall = this.getLabelList.bind(this);
        break;
      case LABEL_MANAGEMENT_TABS.MEASUREMENT:
        list = this.measurementList;
        apiCall = this.getlabels.bind(this, LABEL_MANAGEMENT_TABS.MEASUREMENT);
      break;
      case LABEL_MANAGEMENT_TABS.SEVERITY:
        list = this.severityList;
        apiCall = this.getlabels.bind(this, LABEL_MANAGEMENT_TABS.SEVERITY);
        break;
      default:
        return;
    }
    const recursiveApiCall = async (nextCursor?: string) => {
      const res = await lastValueFrom(apiCall(nextCursor))
      list?.update(value => [...value, ...res.records])
      if(res.meta.nextCursor) recursiveApiCall(res.meta.nextCursor)
    }
    recursiveApiCall();
  }

  deleteLabelFromList(id: string, type: string) {
    switch (type) {
      case LABEL_MANAGEMENT_TABS.COMPONENT:
        this.componentTypeList.update((val) => val.filter((item) => item._id !== id));
        this.removeInspectionLabel(id, INSPECTION_LABEL_NAME.COMPONENT_LABEL);
        return this.componentTypeList();
      case LABEL_MANAGEMENT_TABS.MEASUREMENT:
        this.measurementList.update((val) => val.filter((item) => item._id !== id));
        return this.measurementList();
      case LABEL_MANAGEMENT_TABS.SEVERITY:
        this.severityList.update((val) => val.filter((item) => item._id !== id));
        this.removeInspectionLabel(id, INSPECTION_LABEL_NAME.SEVERITY_LABEL);
        return this.severityList();
      case LABEL_MANAGEMENT_TABS.INSPECTION_FINDING:
        this.inspectionLabelList.update((val) => val.filter((item) => item._id !== id));
        return this.inspectionLabelList();
      default:
        return [];
    }

  }

  getLabelList(nextCursor: string | null= null): Observable<any> {
    let queryParams = []
    if (nextCursor) queryParams.push(`&nextCursor=${nextCursor}`);
    queryParams.push(`pageLimit=${TABLE_DATA.FETCH_LIMIT}`);
    const queryString = queryParams.join('&');
    return this.httpClient.get<any>(`${Routes.GET_LABEL_MANAGEMENT}?${queryString}`).pipe(take(1)); 
  }

  getFindingLabelById(id: string): Observable<any> {
    return this.httpClient.get<any>(`${Routes.GET_LABEL_MANAGEMENT}/${id}`).pipe(take(1)); 
  } 

  getTotalItems(type: string) {
    switch (type) {
      case LABEL_MANAGEMENT_TABS.COMPONENT:
        return this.componentTypeList().length;
      case LABEL_MANAGEMENT_TABS.MEASUREMENT:
        return this.measurementList().length;
      case LABEL_MANAGEMENT_TABS.SEVERITY:
        return this.severityList().length;
      case LABEL_MANAGEMENT_TABS.INSPECTION_FINDING:
        return this.inspectionLabelList().length;
      default:
        return 0;
    }
  }

  postLabelList(data: IPostLabelTemplate): Observable<any> {
    return this.httpClient.post<any>(Routes.LABEL_TEMPLATE, data).pipe(take(1));
  }

  getLabelTemplateList(){
    return this.httpClient.get<any>(Routes.LABEL_TEMPLATE).pipe(take(1));
  }
  
  getLabelFindingTypesList(){
    return this.httpClient.get<any>(Routes.GET_LABEL_MANAGEMENT + '?withCounts=true').pipe(take(1));
  }

  getlabels(query: string | null = null, nextCursor: string | null = null): Observable<any> {
    let queryParams: string[] = [];
    if (nextCursor) queryParams.push(`nextCursor=${nextCursor}`);
    if (query) queryParams.push(`labelType=${query}`);
    queryParams.push(`pageLimit=${TABLE_DATA.FETCH_LIMIT}`)
    const queryString = queryParams.join('&');
    return this.httpClient.get<any>(`${Routes.LABEL_COMPONENTS}?${queryString}`).pipe(take(1)); 
  }
  

  postLabels(data: ILabel): Observable<any> {
    return this.httpClient.post<any>(Routes.LABEL_COMPONENTS, data).pipe(take(1));
  }

  patchLabels(data: ILabel, id: string | undefined): Observable<any> {
    return this.httpClient.patch<any>(`${Routes.LABEL_COMPONENTS}/${id}`, data).pipe(take(1));
  }

  deleteLabels(id: string): Observable<any> {
    return this.httpClient.delete<any>(`${Routes.LABEL_COMPONENTS}/${id}`).pipe(take(1));
  }

  postFindingLabel(data: IPostInspectionLabel): Observable<any> {
    return this.httpClient.post<any>(Routes.FINDING_LABELS, data).pipe(take(1));
  }

  deleteFindingLabel(id: string): Observable<any> {
    return this.httpClient.delete<any>(`${Routes.FINDING_LABELS}/${id}`).pipe(take(1));
  }

  patchFindingLabel(data: IPostInspectionLabel, id: string): Observable<any> {
    return this.httpClient.patch<any>(`${Routes.FINDING_LABELS}/${id}`, data).pipe(take(1));
  }

  // Updated to inspection findings while edit the components (or) severity tab
  updateInspectionLabel(data: ILabelTypeItem, type: string) {
    this.inspectionLabelList.update((inspFindings) => {
      return inspFindings.map((inspection) => {
        if (type === INSPECTION_LABEL_NAME.COMPONENT_LABEL && inspection.componentLabel) {
          return {
            ...inspection,
            componentLabel: {
              ...inspection.componentLabel,
              name: data._id === inspection.componentLabel._id ? data.name : inspection.componentLabel.name
            }
          };
        } else if (type === INSPECTION_LABEL_NAME.SEVERITY_LABEL) {
          return {
            ...inspection,
            severityLabels: inspection.severityLabels.map((severity) => ({
              ...severity,
              name: severity._id === data._id ? data.name : severity.name
            }))
          };
        }
        return inspection;
      });
    });
  }

  // Updated to inspection findings while removing the components (or) severity tab
  removeInspectionLabel(id: string, type: string) {
    this.inspectionLabelList.update((inspFindings) => {
      return inspFindings.map((inspection) => {
        if (type === INSPECTION_LABEL_NAME.COMPONENT_LABEL && inspection.componentLabel) {
          return {
            ...inspection,
            componentLabel: (id === inspection.componentLabel._id) ? null : {...inspection.componentLabel}
          };
        } else if (type === INSPECTION_LABEL_NAME.SEVERITY_LABEL && inspection.componentLabel) {
          return {
            ...inspection,
            severityLabels: inspection.severityLabels.filter((severity)=>(severity._id !== id))
          }
        } 
        return inspection;
      });
    });
  }
}
