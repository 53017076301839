import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Routes } from "./routes";
import { BehaviorSubject, Observable, Subject, take } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  // Assests 
  assetsListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  assetsList$: Observable<any> = this.assetsListSubject.asObservable();

  assetId = new Subject<string>();

  constructor(
    private httpClient: HttpClient,
  ) { }

  getMissionList(): Observable<any> {
    return this.httpClient.get<any>(Routes.GET_MISSIONS).pipe(take(1));
  }

  getMissionById(id: string): Observable<any> {
    let url = `${Routes.GET_MISSIONS}/${id}`;
    return this.httpClient.get<any>(url).pipe(take(1));
  }

  getAssetsListByIds = (assetIds: string[]): Observable<any> => {
    const payload = {
      assetIds
    };
    return this.httpClient.post<any>(`${Routes.GET_ASSETS_LIST_BY_IDS}`, payload).pipe(take(1));
  }

  lngLatToDMS = ({ lng, lat }: { lng: number; lat: number }) => {
    const DMSLngLat: {
      [key: string]: string;
    } = {
      lng: '',
      lat: '',
    };
    const origLngLat: {
      [key: string]: number;
    } = { lng, lat };
    const keysArr = Object.keys(origLngLat);
    keysArr.forEach((prop: string) => {
      let position;
      if(origLngLat[prop]< 0) {
        position = prop === 'lng' ? 'W' : 'S';
      } else {
        position = prop === 'lng' ? 'E': 'N';
      }
      const absVal = Math.abs(origLngLat[prop]);
      const degrees = Math.floor(absVal);
      const decimalMinutes = (absVal - degrees) * 60;
      const minutes = Math.floor(decimalMinutes);
      const seconds = (decimalMinutes - minutes) * 60;
      DMSLngLat[prop] = `${degrees}° ${minutes}' ${seconds.toFixed(3)}" ${position}`;
    });
    return DMSLngLat;
  };

  setAssetList(data: any) {
    this.assetsListSubject.next(data)
  }
  getAssestList() {
    return this.assetsListSubject.getValue();
  }
}
