import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Routes } from "./routes";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {}

  getParam(param:string){
    this.activatedRoute.params.subscribe(param => {
      return param;
    })
    return;
  }
}
