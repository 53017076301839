export const getQueryByParams = (params: {
  assetIds?: string[];
  assetId?: string;
  pageLimit?: number;
  pre_signed_url?: boolean;
  nextCursor?: string;
  siteId?: string;
  tags?: string | string[];
  projectId?: string;
  missionId?: string;
  resourceType?: string;
  workspaceId?: string;
  labelType?: string;
}) => {
  let queryParams = '';

  Object.keys(params).forEach((paramKey) => {
    let value: number | boolean | string | string[] =
      params[paramKey as keyof typeof params] ?? '';
    if (typeof value === 'object' && Array.isArray(value)) {
      value = encodeURIComponent(value.join(','));
    }
    if (value) {
      queryParams += `${paramKey}=${value}&`;
    }
  });
  return queryParams;
};
