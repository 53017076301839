import { Pipe, PipeTransform } from '@angular/core';
import { TitleCaseConvertorPipe } from 'projects/digital-twin/src/app/shared/pipes/titlecase-convertor.pipe';
import { IAccordionDataProperties, IAsset } from '../interfaces';
import { META_PROPERTY_TYPE, NAMING_CONVENTIONS } from 'projects/digital-twin/src/app/constants';

@Pipe({ name: 'propertyDataMapping'} )
export class PropertyDataMappinglPipe implements PipeTransform {
  titleCaseConvertorPipe = new TitleCaseConvertorPipe();

  transform(attachedAssetDetail: IAsset | null): IAccordionDataProperties[] {
    if (attachedAssetDetail?._id && attachedAssetDetail?.metadata) {
      let attachedAssetProperties: IAccordionDataProperties[] = [];
      for (const [key, value] of Object.entries(attachedAssetDetail.metadata)) {
        attachedAssetProperties.push({
          displayName:
            this.titleCaseConvertorPipe.transform(
              key,
              NAMING_CONVENTIONS.CAMEL_CASE,
              META_PROPERTY_TYPE.ATTACHED_ASSET,
            ) || '',
          actualKey: key,
          actualValue: value ?? 'NA',
        });
      }
      return attachedAssetProperties;
    } else {
      return [];
    }
  }
}
