import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IDataResourceListResponse, ISceneDefinitionDocumentSingleVersionResponse, IComponentRecord, ISceneDefinitionDocumentVersionRecord, ISiteDetailResponse, ISiteAttachedAssetEntity, ISceneObject } from '../interfaces/api-response.interface';
import { IAnnotationImageResp, IAssociationAccordionList, IComponentAssociationFilterStatus, IComponentMetaFilter, IEstimatedCuboidGeometry, INewSceneObject, IStageResourceRecord } from '../interfaces';
import { CANVAS_COMPONENT } from '../constants';
import { CommonService, AwsService } from 'projects/digital-twin/src/app/services';
@Injectable({
  providedIn: 'root'
})
export class CanvasDataService {
  toggleSceneObject$ = new Subject<{ selectedSceneObjectId: string, isVisible: boolean }>();
  triggerGetAllComponents$ = new Subject<void>();
  constructor(private commonService: CommonService,private awsService: AwsService) {
  }

  /* #region scene definition */
  initialSceneDefinitionDocument!: ISceneDefinitionDocumentSingleVersionResponse;

  private initialSceneDefDocSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  initialSceneDefDocObservable$: Observable<any> = this.initialSceneDefDocSubject.asObservable();

  private sceneDefinitionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sceneDefinitionData$: Observable<any> = this.sceneDefinitionSubject.asObservable();

  // Assets 
  private assetsListSubject: BehaviorSubject<ISiteAttachedAssetEntity[]> = new BehaviorSubject<ISiteAttachedAssetEntity[]>([]);
  assetsList$: Observable<ISiteAttachedAssetEntity[]> = this.assetsListSubject.asObservable();

  private sceneObjectNameChangeSubject: BehaviorSubject<ISceneObject | null> = new BehaviorSubject<ISceneObject | null>(null);
  sceneObjectNameChangeObservable$: Observable<ISceneObject | null> = this.sceneObjectNameChangeSubject.asObservable();
  snapShotImageSignal = signal<IStageResourceRecord[]>([]);
  updateSceneObjectOnNameChange(sceneObjectId:string){
    const sceneObject = this.getSceneObjectById(sceneObjectId, 'id')
    this.sceneObjectNameChangeSubject.next(sceneObject)
  }

  updateInitialSceneDefDoc(data: ISceneDefinitionDocumentSingleVersionResponse) {
    this.initialSceneDefinitionDocument = { ...data };
    this.initialSceneDefDocSubject.next(data);
  }

  // get current scene defintion
  getSceneDefintion() {
    return this.sceneDefinitionSubject.getValue();
  }

  // set / update whole object of scene definition
  updateSceneDefinition(data: ISceneDefinitionDocumentSingleVersionResponse) {
    if (data?.sceneDetails) {
      data.sceneDetails.gravityVector = data.sceneDetails.gravityVector?.length ? data.sceneDetails.gravityVector : null;
      data.sceneDetails.groundPlane = data.sceneDetails.groundPlane?.length ? data.sceneDetails.groundPlane : null;
    }
    this.sceneDefinitionSubject.next(data);
  }

  // add scene object to scene defintion
  addSceneObject(data: any) {
    const sceneDefintion: any = this.getSceneDefintion();
    sceneDefintion.sceneObjects.push({ ...data, sceneDocId: sceneDefintion._id });
    this.updateSceneDefinition(sceneDefintion)
  }

  // update scene object by id in scene defintion
  async updateSceneObjectById(sceneObjectId: string, data: any) {
    const sceneDefintion: any = this.getSceneDefintion();
    const foundSceneObjectIndex = await sceneDefintion.sceneObjects.findIndex((o: any) => o.id === sceneObjectId);
    if (foundSceneObjectIndex > -1) {
      sceneDefintion.sceneObjects[foundSceneObjectIndex] = { ...sceneDefintion.sceneObjects[foundSceneObjectIndex], ...data };
      this.updateSceneDefinition({ ...sceneDefintion })
    }
  }

  // remove scene object by id
  async removeSceneObjectById(sceneObjectId: string) {
    const sceneDefintion: any = this.getSceneDefintion();
    sceneDefintion.sceneObjects = await sceneDefintion.sceneObjects.filter((o: any) => o.id !== sceneObjectId);
    this.updateSceneDefinition({ ...sceneDefintion })
  }

  // get sceneobject by id from scene definition
  getSceneObjectById(sceneObjectId: string, compareProperty: string = '_id' ) {
    const sceneDefintion: any = this.getSceneDefintion();
    const foundSceneObject = sceneDefintion.sceneObjects.find((o: any) => o[compareProperty] === sceneObjectId);
    return foundSceneObject;
  }
  /* #endregion scene definition */

  isImageGalleryOpen = false;

  /* #region active scene object */
  activeSceneObject!: ISceneObject | INewSceneObject | null;
  private activeSceneObjectSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activeSceneObject$: Observable<any> = this.activeSceneObjectSubject.asObservable();

  // set active sceneobject - id
  setActiveSceneObject(data: ISceneObject | INewSceneObject) {
    this.activeSceneObject = data;
    this.activeSceneObjectSubject.next(data);
  }

  clearActiveSceneObject() {
    this.activeSceneObject = null;
    this.activeSceneObjectSubject.next(null)
  }

  // get active sceneobject id
  getActiveSceneObject() {
    return this.activeSceneObjectSubject.getValue();
  }
  /* #region active scene object */

  /* #region data resource */
  private dataResourceSubject: BehaviorSubject<IDataResourceListResponse | null> = new BehaviorSubject<IDataResourceListResponse | null>(null);
  dataResource$: Observable<any> = this.dataResourceSubject.asObservable();

  // set / update whole object of data resource
  updateDataResource(data: any) {
    this.dataResourceSubject.next(data)
  }

  getDataResource() {
    return this.dataResourceSubject.getValue();
  }

  dataResuorceObservable() {
    return this.dataResourceSubject.asObservable();
  }
  /* #endregion data resource */

  /* #region components */
  private componentListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  componentListData$: Observable<any> = this.componentListSubject.asObservable();

  updateComponentList(data: IComponentRecord[] = []) {
    this.componentListSubject.next(data);
  }
  getComponentStatusById(id: string | undefined) {
    if (!id) return null
    //@ts-ignore
    const foundComponent = this.componentListSubject.getValue().find(obj => obj._id === id);
    return foundComponent?.metadata?.status ?? null;
  }
  /* #endregion components */

  /* #region scene definition document version */
  initialSceneDefDocVersionList: ISceneDefinitionDocumentVersionRecord[] = [];
  private sceneDefDocVersionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sceneDefDocVersionListData$: Observable<any> = this.sceneDefDocVersionSubject.asObservable();

  updateSceneDefDocVersionList(data: ISceneDefinitionDocumentVersionRecord[] = []) {
    this.sceneDefDocVersionSubject.next(data);
  }

  updateInitialSceneDefDocVersionList(data: ISceneDefinitionDocumentVersionRecord[] = []) {
    this.initialSceneDefDocVersionList = data;
  }
  /* #endregion scene definition document version */

  /* #region workflow run */
  private workflowRunSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  workflowRunData$: Observable<any> = this.workflowRunSubject.asObservable();

  updateWorkflowRun(data: any) {
    this.workflowRunSubject.next(data)
  }

  getWorkflowRun() {
    return this.workflowRunSubject.getValue();
  }

  async updateWorkflowStepById(workflowStepId: string, workflowStepObject: any) {
    let workflowRun: any = this.getWorkflowRun();
    const foundWorkflowObjectIndex = await workflowRun['workflowSteps'].findIndex((o: any) => o._id === workflowStepId);
    if (foundWorkflowObjectIndex > -1) {
      workflowRun['workflowSteps'][foundWorkflowObjectIndex] = { ...workflowRun['workflowSteps'][foundWorkflowObjectIndex], ...workflowStepObject };
      this.updateWorkflowRun({ ...workflowRun })
    }
  }
  /* #endregion workflow run */

  // compare objects 1 & 2
  compareObject(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
  }

  // 2d Annotation Service
  private annotationImagesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  annotationImages$: Observable<any> = this.annotationImagesSubject.asObservable();

  // set / update 2d annotation
  updateAnnotationImages(data: any) {
    this.annotationImagesSubject.next(data);
  }

  getAnnotationImages() {
    return this.annotationImagesSubject.getValue();
  }

  annotationImagesObservable() {
    return this.annotationImagesSubject.asObservable();
  }

  private dataResourceExistsSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  dataResourceExistsStatus$: Observable<any> = this.dataResourceExistsSubject.asObservable();

  // set / update whole object of data resource
  updateDataResourceExistsStatus(status: boolean = false) {
    this.dataResourceExistsSubject.next(status)
  }

  private missionExistsSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  missionExistsStatus$: Observable<any> = this.missionExistsSubject.asObservable();

  // set / update whole object of data resource
  updateMissionExistsStatus(status: boolean = false) {
    this.missionExistsSubject.next(status)
  }

  // Canvas filter service to hide/show bounding box
  private boundingBoxFiltersSubject: BehaviorSubject<any> = new BehaviorSubject<string[]>([]);
  boundingBoxFilters$: Observable<string[]> = this.boundingBoxFiltersSubject.asObservable();

  // bounding bo filter set
  setBoundingBoxFilter(data: string[]) {
    this.boundingBoxFiltersSubject.next(data);
  }
  // get active sceneobject id
  getBoundingBoxFilter() {
    return this.boundingBoxFiltersSubject.getValue();
  }

  // Telco equipment bbox estimation
  private telcoEquipmentBboxEstimationSubject: BehaviorSubject<IEstimatedCuboidGeometry[]> = new BehaviorSubject<IEstimatedCuboidGeometry[]>([]);
  telcoEquipmentBboxEstimationObservable$: Observable<IEstimatedCuboidGeometry[]> = this.telcoEquipmentBboxEstimationSubject.asObservable();

  telcoEquipmentBboxEstimationData(data: IEstimatedCuboidGeometry[] = []) {
    this.telcoEquipmentBboxEstimationSubject.next(data);
  }
  // get current Telco equipment bbox estimation
  getTelcoEquipmentBboxEstimation() {
    return this.telcoEquipmentBboxEstimationSubject.getValue();
  }

  // Nearest view port images array
  private nearestViewPortImagesSubject = new Subject<any>();
  nearestViewPortImagesData$: Observable<any> = this.nearestViewPortImagesSubject.asObservable();

  updateNearestViewPortImagesData(data: IAnnotationImageResp) {
    this.nearestViewPortImagesSubject.next(data);
  }

  // Canvas service to trigger updated property value of scene object in viewer
  private triggerPropertySelectSubject: BehaviorSubject<any> = new BehaviorSubject<string>('');
  triggerPropertySelect$: Observable<string> = this.triggerPropertySelectSubject.asObservable();
  private triggerPropertySelectHighlightSubject: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  triggerPropertySelectHighlight$: Observable<boolean> = this.triggerPropertySelectHighlightSubject.asObservable();
  private triggerPropertyClearSubject: BehaviorSubject<any> = new BehaviorSubject<string>('');
  triggerPropertyClear$: Observable<string> = this.triggerPropertyClearSubject.asObservable();
  
  // set property need to  update
  setPropertyTypeTrigger(data: string) {
    this.triggerPropertySelectSubject.next(data);
    this.setPropertyTypeTriggerHighlight(true);
  }
  setPropertyTypeTriggerHighlight(data: boolean) {
    this.triggerPropertySelectHighlightSubject.next(data);
  }
  clearPropertyTrigger(data: string) {
    this.triggerPropertyClearSubject.next(data);
  }

  // Canvas service to hold grouped component list length
  private groupedCompCountbyTypeSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  groupedCompCountbyType$: Observable<any> = this.groupedCompCountbyTypeSubject.asObservable();

  // set property need to  update
  updateGroupCompCountByType(type: string, count: number) {
    const currentCount = this.groupedCompCountbyTypeSubject.getValue();
    currentCount[type] = count;
    this.groupedCompCountbyTypeSubject.next(currentCount);
  }
  getGroupedCompCountByType(type: string) {
    const currentCount = this.groupedCompCountbyTypeSubject.getValue();
    return currentCount[type] ?? 0;
  }

  // Canvas service to hold grouped component list length
  private groupedCompbyTypeSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  groupedCompbyType$: Observable<any> = this.groupedCompbyTypeSubject.asObservable();

  // set property need to  update
  updateGroupCompByType(groupedList: any) {
    this.groupedCompbyTypeSubject.next(groupedList);
  }
  getGroupedCompbyType(type: string) {
    const currentGroup = this.groupedCompbyTypeSubject.getValue();
    return currentGroup[type] ? currentGroup[type].childrenProperties : [];
  }
  getNameByLastComp(type: string) {
    const group = this.getGroupedCompbyType(type);
    let tempMaxDigit = 0;
    for (const element of group) {
      let lastDigit = element.name.split('_').pop();
      if (lastDigit) lastDigit = parseInt(lastDigit);
      if (lastDigit && lastDigit > tempMaxDigit) tempMaxDigit = lastDigit;
    }
    return tempMaxDigit ? tempMaxDigit + 1 : 1;
  }
  // Components group list:
  private componentGroupListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  componentGroupListData$: Observable<any> = this.componentGroupListSubject.asObservable();

  // updated Group list data
  updateComponentGroupList(data: string[] = []) {
    this.componentGroupListSubject.next(data);
  }

  sortingAssociationByComponents(associationList: IAssociationAccordionList[], sortedComponentTags: string[]) {
    let getCompFilterData = this.orderingAssociationByComponents(associationList, sortedComponentTags);
    getCompFilterData.sort((a: any, b: any) => a["componentOrderNo"] - b["componentOrderNo"]);
    return getCompFilterData;
  }

  private orderingAssociationByComponents(associationList: IAssociationAccordionList[], sortedComponentTags: string[]) {
    return associationList.filter((elm) => sortedComponentTags.includes(elm.accComponentType)).map((elmData) => {
      return { ...elmData, componentOrderNo: Number(sortedComponentTags.findIndex((elm) => elm === elmData.accComponentType)) + 1 }
    })
  }

  /* #region association meta field filter object */
  private associationMetaFieldFilterObjectSubject: BehaviorSubject<IComponentMetaFilter | null> = new BehaviorSubject<IComponentMetaFilter | null>(null);
  associationMetaFieldFilterObjectObservable$: Observable<IComponentMetaFilter | null> = this.associationMetaFieldFilterObjectSubject.asObservable();
  // set association meta field filter object
  setAssociationMetaFieldFilterObject(data: IComponentMetaFilter) {
    this.associationMetaFieldFilterObjectSubject.next(data);
  }
  // get association meta field filter object
  getAssociationMetaFieldFilterObject() {
    return this.associationMetaFieldFilterObjectSubject.getValue();
  }
  /* #region association meta field filter object */

  /* #region site detail object */
  private siteDetailSubject: BehaviorSubject<ISiteDetailResponse | null> = new BehaviorSubject<ISiteDetailResponse | null>(null);
  siteDetailObservable$: Observable<ISiteDetailResponse | null> = this.siteDetailSubject.asObservable();
  // set site detail object
  setSiteDetail(data: ISiteDetailResponse) {
    this.siteDetailSubject.next(data);
  }
  // get site detail object
  getSiteDetail() {
    return this.siteDetailSubject.getValue();
  }
  /* #region site detail object */

  /* #region site attached asset object */
  private siteAttachedAssetSubject: BehaviorSubject<ISiteAttachedAssetEntity | null> = new BehaviorSubject<ISiteAttachedAssetEntity | null>(null);
  siteAttachedAssetObservable$: Observable<ISiteAttachedAssetEntity | null> = this.siteAttachedAssetSubject.asObservable();
  // set site detail object
  setSiteAttachedAssetDetail(data: ISiteAttachedAssetEntity) {
    this.siteAttachedAssetSubject.next(data);
  }
  // get site attached asset object
  getSiteAttachedAssetDetail() {
    return this.siteAttachedAssetSubject.getValue();
  }
  /* #region site attached asset object */

  /* #region association filter status */
  associationFilterStatus: IComponentAssociationFilterStatus[] = [];
  updateAssociationFilterStatus(status: IComponentAssociationFilterStatus[]  = []) {
    this.associationFilterStatus = [...status];
  }
  /* #endregion association filter status */
  collapsedPanelStatusSignal = signal(false);
  showFilterPanelSignal = signal(false);
  showWorkflowPanelSignal = signal(false);

  boundingBoxFilterStatus: IComponentAssociationFilterStatus[] = [];
  makeFilterStatusObject({
    filters,
    boundingBoxFilter = false,
    checkedFilters = []
  }: {
    filters: string[];
    boundingBoxFilter?: boolean;
    checkedFilters?: string[]
  }): IComponentAssociationFilterStatus[] | void {    
    const filter: IComponentAssociationFilterStatus[] = filters.map((status) => ({ 
      value: status,
      label: status.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()), //capitalize first letter
      checked: checkedFilters.length ? checkedFilters.includes(status) : true,
      colorCode: this.commonService.digitalTwinSettings[status]?.color ?? CANVAS_COMPONENT.DEFAULT_COLOR, //color code mapping
    }));
    if (!boundingBoxFilter) return filter;
    this.boundingBoxFilterStatus = filter;
    this.setBoundingBoxFilter(filters); 
  }

  // Loading Nexus Links
  async processNxzResource(resource: any, nexusModelLinks: string[] = []) {
    if (!resource?.storage?.files) return;
    for (const file of resource.storage.files) {
      const modelUrl = file.preSignedUrl ?? await this.getSignedUrl(resource.projectId, resource.missionId, resource.resourceType, file.s3Key);
      nexusModelLinks.push(modelUrl);
    }
    return nexusModelLinks;
  }

  async getSignedUrl (projectId: string, missionId: string, resourceType: string, s3Key: string) {
    return await this.awsService.getSignedURL({ projectId, missionId, type: resourceType, objectKey: s3Key }) ;
  };

  /* show the selected mearsurment */
  private selectedMeasurementSubject: BehaviorSubject<ISceneObject | null> = new BehaviorSubject<ISceneObject | null>(null);
  selectedMeasurement$: Observable<ISceneObject | null> = this.selectedMeasurementSubject.asObservable();
  // set measurement select
  setSelectedMeasurement(data: ISceneObject) {
    this.selectedMeasurementSubject.next(data);
  }
  // update measurement position 
  async updateSelectedMeasurementPos(coordinates: number[][]) {
    const measurement = this.selectedMeasurementSubject.getValue();
    if (measurement) {
      //@ts-ignore
      measurement.coordinates = [...coordinates];
      this.updateSceneObjectById(measurement.id, measurement)
    }
  }

  private deleteMeasurementSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  deleteMeasurement$: Observable<string> = this.deleteMeasurementSubject.asObservable();
  // clear measurement from sceneobject and scene
  deleteMeasurement(measurementId: string) {
    this.deleteMeasurementSubject.next(measurementId)
    this.removeSceneObjectById(measurementId);
  }

  // Set Assets
  setAssetList(assetList: ISiteAttachedAssetEntity[]) {
    this.assetsListSubject.next(assetList);
  }

  // Get assets list of mission
  getAssetList() {
    return this.assetsListSubject.getValue();
  }

  private associateDisassociateComponentSubject: BehaviorSubject<ISceneObject> = new BehaviorSubject<any>({});
  associateDisassociateComponent$: Observable<ISceneObject> = this.associateDisassociateComponentSubject.asObservable();
  onAssociateDisassociateComponent(component: ISceneObject) {
    this.associateDisassociateComponentSubject.next(component);
  }
}
