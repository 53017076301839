// interface & status code:
export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};
export const RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
};
export const DEFAULT_MESSAGE = {
  ERROR: 'Something went wrong',
  SUCCESS: 'Done',
};
export interface IResponse {
  responseMessage?: string;
  responseCode?: number;
  response?: any;
}

export interface IListingData {
  expanded?: boolean;
  assetId?: string;
  name: string;
  description?: string;
  severity?: string;
  folders?: {
    expanded?: boolean;
    description: string;
    severity?: string;
    subFolder?: {
      description: string;
      severity?: string;
    }[];
  }[]
}

export interface ICreateDataResource {
  workspaceId: string,
  type: string;
  projectId?: string;
  missionId: string;
  siteId?: string;
  resourceType: string;
  tags: Array<any>;
  parentResourceId?: string;
  metadata: Object;
  storage: {
    storageType: string;
    files: [
      {
        s3Key: string;
        s3Bucket: string;
        sizeBytes: number;
        extension: string;
        s3Etag: string;
        origUploadRelativePath: string | null;
      }
    ];
  }
}

export interface IExifData {
  imageName: string;
  dateTimeOriginal: string;
  cameraModel: string;
  fieldOfViewDegree?: number | null;
  focalLengthMM: number;
  focalLength35Efl: number;
  imageWidth: number;
  imageHeight: number;
  gpsLatitude: number;
  gpsLongitude: number;
  relativeAltitudeMeters: number;
  absoluteAltitudeMeters: number;
  flightPitchDegree: number;
  flightYawDegree: number;
  flightRollDegree: number;
  gimbalPitchDegree: number;
  gimbalYawDegree: number;
  gimbalRollDegree: number;
  sensorWidthMM: number;
  sensorHeightMM: number;
  gpsMapDatum?: string | null;
  gpsLatitudeRef: string;
  gpsLongitudeRef: string;
  imageType?: string;
  emissivity?: number;
  objectDistance?: number;
  reflectedTemp?: number;
  relativeHumidity?: number;
  atmTemp?: number;
}

export interface IAccordionDataProperties{
  displayName: string;
  actualKey: string;
  actualValue: string;
}

export interface IFindingObject{
  finding: string;
  priority: string;
  assetId: string;
  component: string;
}