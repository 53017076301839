import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import Konva from 'konva';
import { ICanvasQueryParams, IGeometryAnnotationDto, IImageSourceTag } from '../../../interfaces';
import { SHARE_PROPERTIES } from '../../../constants';

@Component({
  selector: 'app-custom-component-image-modal',
  templateUrl: './custom-component-image-modal.component.html',
  styleUrl: './custom-component-image-modal.component.scss',
})
export class CustomComponentImageModalComponent {
  currentModuleType = SHARE_PROPERTIES.MODULE_TYPE_GRID;
  canvasQueryParamsObject: ICanvasQueryParams;
  isLoadedDataImageStatus: boolean;
  primaryStoredAnnotationList: IGeometryAnnotationDto[];
  isLoadedAnnotation: boolean = false;
  displayModal = false;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  imageUrl: string;
  @ViewChild('container', { read: ElementRef }) containerRef!: ElementRef;
  layer: Konva.Layer;
  stage: Konva.Stage;
  collectImages: IImageSourceTag['imageRGB'];
  async openModal(collectImages:  IImageSourceTag['imageRGB'], annotationList: IGeometryAnnotationDto[]) {
    this.displayModal = true;
    this.collectImages = collectImages;
    this.primaryStoredAnnotationList = annotationList;
    this.isLoadedDataImageStatus = true;
    this.isLoadedAnnotation = true;
  }

  closeModal() {
    this.displayModal = false;
  }

  onConfirmClick() {
    this.confirmAction.emit(true);
  }
  onCancelClick() {
    this.displayModal = false;
    this.confirmAction.emit(false);
  }
}
