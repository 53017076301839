import Konva from "konva";

export interface CustomKonvaGroup extends Konva.Group {
    isNewBox?: boolean;
    referenceId?: string;
}

export enum TemperatureType {
    Celsius = 'C',
    Fahrenheit = 'F',
    Kelvin = 'K'
}