import {
  Component,
  OnInit,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EventTypes,
  ToastEvent,
} from 'projects/digital-twin/src/app/interfaces/notification.interface';
import { fromEvent, take } from 'rxjs';
import * as bootstrap from 'bootstrap'
// declare const bootstrap: any;
@Component({
  selector: 'app-custom-2d-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss'],
  animations: [],
})
export class CustomNotificationComponent {
  @Output() disposeEvent = new EventEmitter();
  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;
  @Input() type!: EventTypes;
  @Input() title!: any;
  @Input() message!: string;
  toast!: any;

  constructor() { }

  ngOnInit() {
    this.show();
  }

  show() {
    this.toast = new bootstrap.Toast(
      this.toastEl.nativeElement,
      this.type === EventTypes.sticky ? { autohide: false, delay: 3000 } : { delay: 3000 }
    );
    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide());
    this.toast.show();
  }

  hide() {
    this.toast.dispose();
    this.disposeEvent.emit();
  }
}
