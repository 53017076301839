<div class="canvas-viewer-wrap">
  <div class="canvas-viewer-container">
    <div class="image-viewer">
      <div id="canvas-parent" ref="canvas-parent-wrap" class="image-canvas" #thumbCanvasContainer>
        <ng-container *ngIf="thumbnailMode && stageProperty; else noThumbMode">
          <div [id]="stageProperty.id"></div>
        </ng-container>
        <ng-template #noThumbMode>
          <div id="canvas-container" class="canvas-container" #canvasContainer></div>
          <div class="spin-loader" id="spin-loader" #canvasLoaderContainer title="Canvas image is loading..">
            <span><i class="fas fa-spinner fa-pulse fa-lg spin-icon-loader" aria-hidden="true"></i></span>
          </div>
          <div *ngIf="selectedItem && progressivePrecentLoad"  [style.left]="isExpanded ? '45.5%':'43%'" class="progressive-percentage">{{progressivePrecentage}} % </div>
        </ng-template>
      </div>
      @if(!thumbnailMode && backgroundImgGroup && !splitScreenViewerMode && !isOrthoMapPreviewMode){
      <div  class="canvas-transform-controls">
        <a class="control" (click)="minMaxmizedCanvasViewer()"> 
          <img [src]="(isExpanded ? '/icon/contract.svg' : '/icon/maximize.svg') | assetUrl" [alt]="isExpanded ? 'minimize' : 'maximize'">
        </a>
        <a class="control"><img [src]="'/icon/minus.svg' | assetUrl" alt="zoom-out"
            (click)="zoomOnClick('out')"></a>
        <div class="control range-wrap"><input type="range" [min]="minZoomValue" [max]="maxZoomValue" [(ngModel)]="zoomValue" (ngModelChange)="updateZoomLevel()"
            [style]="'--bg-size :' + zoomValue + '%'" /></div>
        <a class="control">
          <img [src]="'icon/add.svg' | assetUrl" alt="zoom-in" (click)="zoomOnClick('in')">
        </a>
        <p class="control percentage"> {{zoomValue}}% </p>
      </div>
      }
      @if(!isIframeMode && !thumbnailMode && backgroundImgGroup && !splitScreenViewerMode && !isOrthoMapPreviewMode){
        <ul  class="canvas-controller">
          <li>
            <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Draw Box"
              [ngClass]="{ active: controlClicked === ANNOTATION_2D_CONTROL.RECT}" (click)="drawRectBox()"
              title="Draw Box">
              <img [src]="'/icon/polygon.svg' | assetUrl" alt="RECT" />
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Draw Polygon"
              [ngClass]="{ active: controlClicked===ANNOTATION_2D_CONTROL.POLYLINE }" (click)="onClickedPolygon()"
              title="Draw Polygon">
              <img [src]="'/icon/programming-arrow.svg' | assetUrl" alt="Polygon" />
            </a>
          </li>
        </ul>
      }
      <!-- action handler -->
        @if(!isIframeMode && !thumbnailMode && drawBox && drawBox?.id() && backgroundImgGroup && !splitScreenViewerMode && !isOrthoMapPreviewMode){
          <ul class="canvas-action-controller">
            <li [style.display]="showModeStatus ? 'block' : 'none'">
              <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Resize & Rotate Box"
                [ngClass]="{ active: controlActionClicked === ANNOTATION_2D_CONTROL_ACTION.ROTATE}"
                (click)="onSelectDrawBoxRotateShape()" title="Resize & Rotate Box">
                <img [src]="'/icon/resize.svg' | assetUrl" alt="Resize & Rotate Box" />
              </a>
            </li>
            <li [style.display]="showModeStatus ? 'block' : 'none'"  *ngIf="drawBox.name() =='poly'">
              <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Drag Vertices"
                [ngClass]="{ active: controlActionClicked === ANNOTATION_2D_CONTROL_ACTION.VERTICE}" title="Drag Vertices"
                (click)="onSelectDrawBoxVerticeShape()">
                <img [src]="'/icon/drag-3d.svg' | assetUrl" alt="Drag Vertices" />
              </a>
            </li>
            <li [style.padding-left]="!showModeStatus ? '5px' : ''">
              <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Copy Annotation"
                 title="Copy Annotation"
                (click)="onCopyAnnotationBox(drawBox)">
                <img [src]="'/icon/copy-icon.svg' | assetUrl" alt="Copy Annotation" />
              </a>
            </li>
            @if(isUndo){
              <li>
                <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Undo"
                  title="Undo" (click)="onClickUndo(drawBox)">
                  <img [src]="'/icon/undo-icon.svg' | assetUrl" alt="Undo" />
                </a>
              </li>
            }
          </ul>
        }
      <!-- action handler -->
      @if(!isIframeMode && !thumbnailMode && drawBox && drawBox?.id() && backgroundImgGroup && !splitScreenViewerMode && !isOrthoMapPreviewMode){
      <ul class="canvas-action-delete-controller">
        <li [style.display]="showModeStatus ? 'block' : 'none'">
          <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Deselect Box"
            [ngClass]="{ active: controlActionClicked === ANNOTATION_2D_CONTROL_ACTION.RESET}"
            (click)="onResetDrawBoxWithClosedFindingPanel()" title="Deselect Box">
            <img [src]="'/icon/round-refresh.svg' | assetUrl" alt="Deselect" />
          </a>
        </li>
        <ng-container>
          <li [style.padding-left]="!showModeStatus ? '5px' : ''">
            <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left"
              data-bs-title="Remove Annotation"
              [ngClass]="{ active: controlActionClicked === ANNOTATION_2D_CONTROL_ACTION.DELETE}"
              title="Remove Annotation" (click)="onSelectDrawBoxRemove()">
              <img [src]="'/icon/delete.svg' | assetUrl" alt="Remove Annotation" />
            </a>
          </li>
        </ng-container>
      </ul>
      }
    </div>
  </div>
</div>
<!-- standalone confirmation window -->
<app-globe-confirm-popup-window 
  #annotationBoxDeleteConfirmDialog 
  [propertyModalConfig]="deleteAnnotationBoxDialogConfig"
  (confirmAction)="confirmDeleteVisualAnnotateObject($event)">
</app-globe-confirm-popup-window>