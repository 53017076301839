<div class="modal" [ngStyle]="{ 'backdrop-filter': 'blur(' + blur + 'px)' }">
  <div class="modal-content">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="modal-title">{{ modalTitle }}</h2>
      <span class="close" (click)="onClose()">
        <img
          [src]="'/icon/close.svg' | assetUrl"
          alt="close-icon"
          class="close-icon"
        />
      </span>
    </div>
    <ng-content></ng-content>
  </div>
</div>
