import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig } from 'projects/annotation-2d/src/app/interfaces/annotation-canvas.interface';
import { CommonModule } from '@angular/common';
import { AssetUrlGlobePipe } from '../../../asset-url-globe.pipe';
@Component({
    selector: 'app-globe-confirm-popup-window',
    standalone: true,
    templateUrl: './globe-confirm-popup.component.html',
    styleUrl: './globe-confirm-popup.component.scss',
    imports: [CommonModule, AssetUrlGlobePipe],
    providers: [AssetUrlGlobePipe],
})
export class GlobeConfirmPopupWindowComponent {
  diplayModal: boolean = false;
  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();

  handleModalDisplay(diplayModal: boolean){
    this.diplayModal = diplayModal;
  }

  handleModeOfClick(modeOfClick: boolean){
    this.confirmAction.emit(modeOfClick);
  }

}
