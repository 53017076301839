// custom delete component

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig } from '../../../interfaces';

@Component({
  selector: 'app-component-delete-modal',
  templateUrl: './custom-component-delete-modal.component.html',
  styleUrl: './custom-component-delete-modal.component.scss',
})
export class CustomComponentDeleteModalComponent {
  displayModal = false;
  loadingInfo = false;

  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Input() confirmDialog: string = '';
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  @Output() confirmMarkerAction: EventEmitter<boolean> = new EventEmitter();

  openModal() {
    this.displayModal = true;
  }

  closeModal() {
    this.displayModal = false;
    this.loadingInfo = false;
  }

  onConfirmClick() {
    this.loadingInfo = true;
    this.handleConfirmation(true);
  }
  onCancelClick() {
    this.displayModal = false;
    this.handleConfirmation(false);
    this.loadingInfo = false;
  }

  handleConfirmation(action: boolean) {
    if (this.confirmDialog == 'ANNOTATION') {
      this.confirmAction.emit(action);
    }
    if (this.confirmDialog == 'MARKER') {
      this.confirmMarkerAction.emit(action);
    }
  }
}
