import { Injectable } from '@angular/core';
import { Line } from 'konva/lib/shapes/Line';
import { IAnnotationHistory } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UndoAnnotationService {
  undoHistory: IAnnotationHistory[] = [];
  getPreviousPointsFromHistory(drawBox: Line) {
    //get last poly points of selected box
    let lastValue = null;
    for (let i = this.undoHistory.length - 1; i >= 0; i--) {
      const historyObj = this.undoHistory[i];
      if (Object.keys(historyObj)[0] === drawBox.id()) {
        lastValue = Object.values(historyObj)[0];
        this.undoHistory.splice(i, 1);
        break;
      }
    }
    return lastValue;
  }

  updateUndoHistory(drawBox: Line) {
    this.undoHistory.push({ [drawBox.id()]: drawBox.getAttr('newRefPoint') });
  }

  updateUndoButtonStatus(boxId: string) {
    return this.undoHistory.some(
      (historyObj: IAnnotationHistory) => Object.keys(historyObj)[0] === boxId,
    );
  }

  removeRecordsFromHistory(boxId: string) {
    this.undoHistory = this.undoHistory.filter(
      (historyObj: IAnnotationHistory) => Object.keys(historyObj)[0] !== boxId,
    );
  }
}
