import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() modalTitle: string;
  @Input() blur: number;
  @Output() closeModal = new EventEmitter<boolean>();

  onClose(): void { 
    this.closeModal.emit(false);
  }
}