import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom, take } from 'rxjs';

import {
  EventTypes,
  IAsset,
  IDataResourceListResponse,
  IDataResourceRecord,
  IFindings,
  IGetAllImages,
  IImageCoordinates,
  IImageSourceTag,
  IResourceImageRecord,
  IStageResourceRecord,
  IVideoSourceTag
} from '../interfaces';
import { Routes } from './routes';
import { API_MESSAGE_EVENTS, DIRECTION, IMAGE_TYPES, IRESOURCE_TAG, ORTHO_TYPE, STAGE_2D_CANVAS_COMMON_VIEW, VIDEO_TYPES } from '../constants';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationDataResourceService {
  private getAllDataResourceImageListSubject: BehaviorSubject<IImageSourceTag> = new BehaviorSubject<IImageSourceTag>({
    imageRGB: {
      originImages: [],
      rawThermalImages: [],
      compressedImages: [],
      thumbnailImages: [],
    },
    imageThermal: {
      originImages: [],
      rawThermalImages: [],
      compressedImages: [],
      thumbnailImages: [],
    }
  });
  private getAllDataResourceVideoListSubject: BehaviorSubject<IVideoSourceTag> = new BehaviorSubject<IVideoSourceTag>({
    videoRGB: {
      origFullResVideo:[],
      videoThumbnailImages:[]
    }
  });
  getAllDataResourceImageListObserv$ = this.getAllDataResourceImageListSubject.asObservable();
  getAllDataResourceVideoListObserv$ = this.getAllDataResourceVideoListSubject.asObservable();
  // Tower Tab list Active: tower/asset active
  private activeTowerAssetSubject: BehaviorSubject<IAsset | null> = new BehaviorSubject<IAsset| null>(null);
  activeTowerAssetObserv$ = this.activeTowerAssetSubject.asObservable();
  // Finding All:
  private getAllFindingSubject: BehaviorSubject<IFindings[]> = new BehaviorSubject<IFindings[]>([]);
  getAllFindingObserv$ = this.getAllFindingSubject.asObservable();
  // Finding :
  private activeFindingSelectSubject: BehaviorSubject<IFindings | null> = new BehaviorSubject<IFindings | null>(null);
  activeFindingSelectObserv$ = this.activeFindingSelectSubject.asObservable();
  // finding > annotated : tower canvas image select
  private activeAnnotatedCanvasImageSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeAnnotatedCanvasImageObserv$ = this.activeAnnotatedCanvasImageSubject.asObservable();
  // Image Gallery Tab List Active : click set active gallery image current
  private seActiveCurrentGalleryImageSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  seActiveCurrentGalleryImageObservable$: Observable<string> = this.seActiveCurrentGalleryImageSubject.asObservable();
  radiusValue: number = STAGE_2D_CANVAS_COMMON_VIEW.RADIUS_VALUE;

  updateDataResourceById ({
    dataResourceId,
    body,
  } : {
    dataResourceId: string,
    body: IDataResourceListResponse
  }) {
    return this.httpClient
    .patch<IDataResourceListResponse>(
      `${Routes.GET_DATA_RESOURCE}/${dataResourceId}`, body)
      .pipe(take(1));
  }

  constructor(private httpClient: HttpClient,private notificationService: NotificationService) { }

  getDataResourceById({
    id,
    preSignedUrl,
  }: {
    id: string;
    preSignedUrl?: boolean;
  }): Observable<IDataResourceRecord> {
    let queryParams = '?'
    if(preSignedUrl) queryParams += `pre_signed_url=${preSignedUrl}`
    return this.httpClient
      .get<IDataResourceRecord>(`${Routes.GET_DATA_RESOURCE}/${id}${queryParams}`)
      .pipe(take(1));
  }

  setAllImageDataResourceList(data: any) {
    this.getAllDataResourceImageListSubject.next(data);
  }
  // getAll image resource:
  getAllImageDataResourceList() {
    this.getAllDataResourceImageListSubject.getValue();
  }
  // tower tab:
  setActiveTowerAsset(data: any) {
    this.activeTowerAssetSubject.next(data)
  }

  clearActiveTowerAsset() {
    this.activeTowerAssetSubject.next(null);
  }
  // finding All:
  setAllFindingList(data: any) {
    this.getAllFindingSubject.next(data);
  }
  setActiveFindingSelected(data: any) {
    this.activeFindingSelectSubject.next(data);
  }

  // active annotated click:
  setActiveAnnotatedCanvas(data: any) {
    this.activeAnnotatedCanvasImageSubject.next(data)
  }

  clearActiveAnnotatedCanvas() {
    this.activeAnnotatedCanvasImageSubject.next('');
  }

  // gallery tab:
  seActiveCurrentGalleryImage(data: any) {
    this.seActiveCurrentGalleryImageSubject.next(data)
  }

  //Future Enhancement: combine getAllImagesView, videoResource and Ortho in one functionality
  //The only difference is in resourceType and resourceTags.
  async getAllImagesView({
    missionId,
    resourceType = IMAGE_TYPES.IMAGE_RGB,
    tags,
    pageLimit,
    nextCursor,
    isPreSignedUrl,
    resourceTag,
  }: IGetAllImages): Promise<void> {
    let url: string = `
		${Routes.GET_DATA_RESOURCE}?
		missionId=${missionId}&
		resourceType=${resourceType}&
		tags=${tags}&
		sortBy=metadata.exif.dateTimeOriginal&
		pageLimit=${pageLimit}&
		`;
    if (isPreSignedUrl) url += `pre_signed_url=${isPreSignedUrl}`;
    if (nextCursor) url += `&nextCursor=${nextCursor}`;

    let responseNextCursor = null;
    do {
      const response = await this.fetchImages(url)
      const imagesResp = response.records.map((obj: any) => ({ ...obj, src: obj.storage.files[0].preSignedUrl }))
      responseNextCursor = response.meta.nextCursor;
      url = url.includes('nextCursor=') ? url.replace(/nextCursor=[^&]+/, `nextCursor=${response.meta.nextCursor}`) : `${url}&nextCursor=${response.meta.nextCursor}`;
      const prevAllDataResourceImageList = this.getAllDataResourceImageListSubject.getValue();
      let resourceTagKey: "originImages" | "compressedImages" | "rawThermalImages" | "thumbnailImages";
      switch(resourceTag) {
        case IRESOURCE_TAG.ORIGINAL_TAG:
          resourceTagKey = "originImages";
          break;
        case IRESOURCE_TAG.COMPRESSED_FULL_RES:
          resourceTagKey = "compressedImages";
          break;
        case IRESOURCE_TAG.THERMAL_IMAGE_RAW:
          resourceTagKey = "rawThermalImages";
          break;
        default: //Thumbnail Tag
          resourceTagKey = "thumbnailImages";
      }
      this.getAllDataResourceImageListSubject.next({
        ...prevAllDataResourceImageList,
        [resourceType]: {
          ...prevAllDataResourceImageList[resourceType],
          [resourceTagKey]: [...prevAllDataResourceImageList[resourceType][resourceTagKey] || [], ...imagesResp]
        }
      })
    }
    while(responseNextCursor);
  
  }

  // get images from api

  private async fetchImages (url: string) {
    try {
      return lastValueFrom(this.httpClient.get<any>(url));
    } catch (error) {
      console.error('Error fetching images: ', error)
    }
  }

  // getAllDataResourceImages = async (missionId: string, pageLimit: string, resourceType: string, tags?: string) => {
  getAllDataResourceImages ({
    missionId,
    pageLimit,
    resourceType,
    tags,
  } : {
    missionId: string
    pageLimit: number
    resourceType: string
    tags?: string
  }) {
    const imageDataSourcePayload: any = { missionId, resourceType, pageLimit, isPreSignedUrl: true }
    let payloadTags = `${tags ? (tags + ",") : ""}`;

    const tagsList = [
      IRESOURCE_TAG.ORIGINAL_TAG,
      IRESOURCE_TAG.THERMAL_IMAGE_RAW,
      IRESOURCE_TAG.COMPRESSED_FULL_RES,
      IRESOURCE_TAG.THUMBNAIL_TAG,
    ]
    
    tagsList.forEach((tag) => {
      //intentionally avoided await so that we can get all the resources tags together
      this.getAllImagesView({
        ...imageDataSourcePayload,
        tags: `${payloadTags}${tag}`,
        resourceTag: tag,
      })
    })
  }

  chunkArray(array: any[], chunkSize: number) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  findObjectById(chunks: any[], dataResourceId: string) {
    for (const [index, chunk] of chunks.entries()) {
      const foundObject = chunk.find((obj: { dataResourceId: string; }) => obj.dataResourceId === dataResourceId);
      if (foundObject) {
        return { key: index, item: foundObject };
      }
    }
    return null; // Return null if the ID is not found in any chunk
  }
  // --------------------------------GET SHORTEST IMAGES FROM LAT,LNG ASSET----------------------------------------------

  // onSelect Asset Get shortest Images
  processSortImageResourceBySelectedAsset(asset: IAsset, dataOriginResourceList: IStageResourceRecord[]) {
    const assetImages: any = { asset: null, nearestImages: [] };
    const sortableDataResources = [...dataOriginResourceList]
    // Correct the assignment to add the nearbyAssert property to the current assert object
    const shortestDataImage = this.findDistanceBetweenTwoPoints(asset.assetLatitude, asset.assetLongitude, sortableDataResources)
    assetImages['asset'] = asset;
    assetImages['nearestImages'] = shortestDataImage;
    return assetImages;
  }

  sortDistanceOfDataImages(sortableDataResources: IResourceImageRecord[], sortableDistance: any[]) {
    return sortableDataResources.slice().sort((a: any, b: any) => {
      const distanceA: number = sortableDistance[sortableDataResources.indexOf(a)];
      const distanceB: number = sortableDistance[sortableDataResources.indexOf(b)];
      return distanceA - distanceB;
    });
  }

  findDistanceBetweenTwoPoints(
    targetLatitude: number,
    targetLongitude: number,
    resourceList: IStageResourceRecord[]
  ): IStageResourceRecord[] {
    const resourceListDistance = resourceList.map((resource) => {
      const { gpsLatitude, gpsLongitude } = resource;
      const distance = this.calculateHaversineDistance({
        lat1: Number(gpsLatitude),
        lon1: Number(gpsLongitude),
        lat2: targetLatitude,
        lon2: targetLongitude,
      })
      return {
        distance,
        resource,
      }
    })
    resourceListDistance.sort((a, b) => a.distance - b.distance);
    return resourceListDistance.map(({ resource }) => resource);
  }

  calculateHaversineDistance({
    lat1,
    lon1,
    lat2,
    lon2,
  } : {
    lat1: number
    lon1: number
    lat2: number
    lon2: number
  }) {
    // Convert latitude and longitude from degrees to radians
    const toRadians = (angle: number) => angle * (Math.PI / 180);
    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);
    // Haversine formula
    const dlat = lat2 - lat1;
    const dlon = lon2 - lon1;
    const a =
      Math.sin(dlat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Radius of Earth in meters (mean value)
    const R = STAGE_2D_CANVAS_COMMON_VIEW.EARTH_METER;
    // Calculate distance
    const distance = R * c;
    return distance;
  }

  getAllDataResourceVideo({
    missionId,
    pageLimit,
    resourceType,
    tags,
  }: {
    missionId: string
    pageLimit: number
    resourceType: string
    tags?: string
  }) {
    const videoRespDataSourcePayload: any = { missionId, resourceType, pageLimit, isPreSignedUrl: true }
    let payloadTags = `${tags ? (tags + ",") : ""}`;

    const tagsList = [
      IRESOURCE_TAG.VIDEO_TAG,
      IRESOURCE_TAG.VIDEO_THUMBNAIL_TAG
    ]

    tagsList.forEach((tag) => {
      if(tag === IRESOURCE_TAG.VIDEO_THUMBNAIL_TAG) videoRespDataSourcePayload.resourceType=IMAGE_TYPES.IMAGE_RGB;
      //intentionally avoided await so that we can get all the resources tags together
      this.getAllVideoResources({
        ...videoRespDataSourcePayload,
        tags: `${payloadTags}${tag}`,
        resourceTag: tag,
      })
    })
  }

  getAllOrthoResource({
    missionId,
    resourceType = ORTHO_TYPE,
    isPreSignedUrl,
  }: {
    missionId: string,
    resourceType?: string,
    isPreSignedUrl?: boolean,
  }) {
    let queryParams = `missionId=${missionId}&resourceType=${resourceType}&`;
    if(isPreSignedUrl) queryParams += `pre_signed_url=${isPreSignedUrl}`;
    return this.httpClient.get<IDataResourceListResponse>(`${Routes.GET_DATA_RESOURCE}?${queryParams}`).pipe(take(1));
  }

  async getAllVideoResources({
    missionId,
    resourceType = VIDEO_TYPES.RESOURCE_TYPE,
    tags,
    pageLimit,
    nextCursor,
    isPreSignedUrl,
    resourceTag,
  }: IDataResourceRecord): Promise<void> { //IDataResourceRecord is type for a response please change
    let url: string = `
		${Routes.GET_DATA_RESOURCE}?
		missionId=${missionId}&
		resourceType=${resourceType}&
		tags=${tags}&
		sortBy=createdAt&
		pageLimit=${pageLimit}&
		`;
    if (isPreSignedUrl) url += `pre_signed_url=${isPreSignedUrl}`;
    if (nextCursor) url += `&nextCursor=${nextCursor}`;
    let responseNextCursor = null;
    do {
      const response = await this.fetchImages(url)
      const videoResp = response.records.map((obj: any) => ({ ...obj, src: obj.storage.files[0].preSignedUrl }))
      responseNextCursor = response.meta.nextCursor;
      url = url.includes('nextCursor=') ? url.replace(/nextCursor=[^&]+/, `nextCursor=${response.meta.nextCursor}`) : `${url}&nextCursor=${response.meta.nextCursor}`;
      const prevAllDataResourceVideoList = this.getAllDataResourceVideoListSubject.getValue();
      let resourceTagKey: "origFullResVideo" | "videoThumbnailImages";
      switch (resourceTag) {
        case IRESOURCE_TAG.VIDEO_TAG:
          resourceTagKey = "origFullResVideo";
          break;
        case IRESOURCE_TAG.VIDEO_THUMBNAIL_TAG:
          resourceTagKey = "videoThumbnailImages";
          break;
        default: //Original Tag
          resourceTagKey = "origFullResVideo";
      }
      if (resourceTag === IRESOURCE_TAG.VIDEO_THUMBNAIL_TAG) resourceType = VIDEO_TYPES.RESOURCE_TYPE;
      this.getAllDataResourceVideoListSubject.next({
        ...prevAllDataResourceVideoList,
        [resourceType]: {
          ...prevAllDataResourceVideoList[resourceType],
          [resourceTagKey]: [...prevAllDataResourceVideoList[resourceType][resourceTagKey] || [], ...videoResp]
        }
      })
    }
    while (responseNextCursor);
  }

  getCameraDegreeDirection(gimbalYawDegree: number) {
    const degree = (gimbalYawDegree || 0) % 360;
    return this.getDirectionByDegree(degree);
  }

  getDirectionByDegree(deg: number) {
    if (deg >= 0 && deg < 90) return DIRECTION.NE;
    if (deg >= 90 && deg < 180) return DIRECTION.SE;
    if (deg >= 180 && deg < 270) return DIRECTION.SW;
    if (deg >= 270 && deg < 360) return DIRECTION.NW;
    if (deg === 0 || deg === 360) return DIRECTION.N;
    if (deg === 90) return DIRECTION.E;
    if (deg === 180) return DIRECTION.S;
    if (deg === 270) return DIRECTION.W;
    return DIRECTION.N; // Default case
  }

  // get detail by given lat,lng info:
  findClosestPairOfVisualImageByGivenThermal(imageData:IImageCoordinates, dataOriginResourceList: IResourceImageRecord[]) {
    let sortableDataResources = [...dataOriginResourceList]
    // Calculate distances and filter within the given radius
    const sortableDistance = sortableDataResources.map((location: IResourceImageRecord) => this.calculateHaversineDistance({
      lat1: parseFloat(location.gpsLatitude),
      lon1: parseFloat(location.gpsLongitude),
      lat2: parseFloat(imageData.gpsLatitude),
      lon2: parseFloat(imageData.gpsLongitude)
    }));
    // II.Combine sortableDataResources with their respective distances
    const dataWithDistances = sortableDataResources.map((location: IResourceImageRecord, index: number) => ({ ...location, distance: sortableDistance[index] }));
    // III.[distance] Sort the array based on distances
    const sortedDistanceData = dataWithDistances.slice().sort((a, b) => a.distance - b.distance);
    // IV.[radius meter] Filter the array based on the specified cover radius default 100m
    sortableDataResources = sortedDistanceData.filter((item) => item.distance <= STAGE_2D_CANVAS_COMMON_VIEW.RADIUS_VALUE);
    // V.[camera heading] Sort direction By camera gimbal degree :
    sortableDataResources = this.sortBetweenByGimbalYawDegreeDirection(imageData, sortableDataResources);
    // VI.[altitude] Sort the By absolute meters:
    return this.sortAbsoluteAltitudeMetersDataList(imageData, sortableDataResources);
  }

  sortBetweenByGimbalYawDegreeDirection(imageData:IImageCoordinates, dataImageList: IResourceImageRecord[]) {
    return dataImageList.sort((a: IResourceImageRecord, b: IResourceImageRecord) => {
      const distanceA = Math.abs(Number(a.gimbalYawDegreeDirection) - Number(imageData.gimbalYawDegreeDirection));
      const distanceB = Math.abs(Number(b.gimbalYawDegreeDirection) - Number(imageData.gimbalYawDegreeDirection));
      return distanceA - distanceB;
    });
  }

  // Sort the array based on the absoluteAltitudeMeters to the given absoluteAltitudeMeters
  sortAbsoluteAltitudeMetersDataList(imageData:IImageCoordinates, dataImageList: IResourceImageRecord[]) {
    return dataImageList.sort((a: IResourceImageRecord, b: IResourceImageRecord) => Math.abs(Number(a.absoluteAltitudeMeters) - Number(imageData.absoluteAltitudeMeters)) - Math.abs(Number(b.absoluteAltitudeMeters) - Number(imageData.absoluteAltitudeMeters)));
  }
 
  getDataResourceByParentId({
    parentResourceId,
    preSignedUrl,
    }: {
    parentResourceId: string;
    preSignedUrl?: boolean;
    }): Observable<IDataResourceListResponse> {
    let queryParams = '';
    if (preSignedUrl) {
    queryParams = `?parentResourceId=${parentResourceId}&pre_signed_url=${preSignedUrl}`;
    } else {
    queryParams = `?parentResourceId=${parentResourceId}`;
    }
    return this.httpClient
    .get<IDataResourceListResponse>(
    `${Routes.GET_DATA_RESOURCE}${queryParams}`
    )
    .pipe(take(1));
    }
    
    updateReviewedStatusToDataResourceApi(dataObject: { _id: string, body: IResourceImageRecord | IStageResourceRecord}) {
      const subscribeUpdateDataSource = this.updateDataResourceById({
         dataResourceId: dataObject._id,
         body: dataObject.body,
       }).subscribe({
         next: (response) => {
           if (response['_id']) {
             this.notificationService.showToast({
               type: EventTypes.success,
               message: response?.['message'] || `${API_MESSAGE_EVENTS.REVIEW_UPDATE_MESSAGE} ${API_MESSAGE_EVENTS.GENERIC_UPDATE_SUCCESS_MESSAGE.toLowerCase()}`,
             });
           }
         },
         error: (error) => {
           console.error(error);
           this.notificationService.showToast({
             type: EventTypes.error,
             message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
           });
         },
       });
       return subscribeUpdateDataSource;
     } 
}
