<div class="content-wrap">
  <ng-container
    *permissionGuard="[
      userRolePermissions.CANVAS_SEGMENTATION_COMPONENT_LISTING
    ]"
  >
    @if (!loadedSegementationList) {
      <div class="spin-loader-list">
        <div
          class="spinner-border spin-loader text-light"
          title="loading.."
        ></div>
      </div>
    }
    @if (filterSegmentationList) {
      <div class="component-accordion">
        <div id="componentAcc" class="accordion">
          @for (
            item of filterSegmentationList;
            track item?.accId;
            let i = $index
          ) {
            <app-annotation-card
              [item]="item"
              [accordionShowIndex]="accordionShowIndex"
              [accordionIndex]="i"
              [selectedSegmentId]="selectedSegmentId"
              [isIframeMode]="isIframeMode"
              (openImageGalaryTrigger)="openImageGalary($event)"
              (showOrHideAccordionTrigger)="showOrHideAccordion($event)"
              (openAssociationTrigger)="openAssociation($event)"
              (deleteMeasurementTrigger)="onDeleteMeasurementClick($event)"
              (closeAssociationTrigger)="closeAssociation()"
              (toggleComponentVisibility)="toggleComponentVisibility($event)"
            ></app-annotation-card>
          }
          @if (
            aiProposedComponentGroupFilter &&
            aiProposedComponentGroupFilter.childrenProperties &&
            aiProposedComponentGroupFilter.childrenProperties.length
          ) {
            <app-annotation-card
              [item]="aiProposedComponentGroupFilter"
              [accordionShowIndex]="accordionShowIndex"
              [accordionIndex]="filterSegmentationList.length"
              [selectedSegmentId]="selectedSegmentId"
              (openImageGalaryTrigger)="openImageGalary($event)"
              (showOrHideAccordionTrigger)="showOrHideAccordion($event)"
              (openAssociationTrigger)="openAssociationAiProposed($event)"
              (deleteMeasurementTrigger)="onDeleteMeasurementClick($event)"
              (closeAssociationTrigger)="closeAssociation()"
              (toggleComponentVisibility)="toggleComponentVisibility($event)"
            ></app-annotation-card>
          }
        </div>
      </div>
    } @else {
      <div class="d-flex justify-content-center align-items-center">
        No record found
      </div>
    }
  </ng-container>
</div>
