import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gpsCoordinate',
  standalone: true
})
export class GpsCoordinatePipe implements PipeTransform {
  transform(value?: number, opt?: {decimals?: number, showDirection?: boolean}): string | number | void {
    if (isNaN(value as number) || !value) return value;

    // Round the coordinate to the specified number of decimals
    const onDecimals = opt?.decimals ? opt.decimals : 6;
    const roundedValue = Number(value.toFixed(onDecimals));

    // Split the coordinate into degrees, minutes, and seconds
    const degrees = Math.floor(Math.abs(roundedValue));
    const minutes = Math.floor((Math.abs(roundedValue) - degrees) * 60);
    const seconds = ((Math.abs(roundedValue) - degrees - minutes / 60) * 3600).toFixed(2);

    // Determine the direction (N/S or E/W)
    let direction;
    if(value > 0) direction = "N";
    else if (value === 0) direction = "";
    else direction = "S";

    // Construct the formatted string
    let formattedCoordinate = `${degrees}° ${minutes}' ${seconds}"`;
    if (opt?.showDirection) {
        formattedCoordinate = `${formattedCoordinate} ${direction}`
    }

    return formattedCoordinate;
  }
}
