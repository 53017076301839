import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'projects/digital-twin/src/app/services';

@Component({
  selector: 'app-2d-annotation',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  config: any = {
    header: { show: false },
    sidebar: { show: false },
    footer: { show: false },
  }
constructor(private permissionService:PermissionService){}

ngOnInit(): void {
  this.permissionService.loadPermissions();
}
}
