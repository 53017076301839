import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CANVAS_COMPONENT_LABELS,
  COMPONENT_ASSOCIATION_STATUS_COMPARE,
  USER_ROLE_PERMISSIONS,
} from 'projects/digital-twin/src/app/constants';
import {
  EventTypes,
  ISegmentationAccordionList,
  ISegmentationProperties,
} from 'projects/digital-twin/src/app/interfaces';
import {
  CanvasDataService,
  NotificationService,
} from 'projects/digital-twin/src/app/services';
import { ANNOTATION_NAME_ERROR_MESSAGE } from '../../../constants';
import { IUserRolesAndPermissions } from 'projects/digital-twin/src/app/interfaces/permission.interface';

@Component({
  selector: 'app-annotation-card',
  templateUrl: './annotation-card.component.html',
  styleUrls: ['./annotation-card.component.scss'],
})
export class AnnotationCardComponent {
  @Input() item: ISegmentationAccordionList;
  @Input() accordionShowIndex: number;
  @Input() accordionIndex: number;
  @Input() selectedSegmentId: string;
  @Input() isIframeMode: boolean;
  @Output() openImageGalaryTrigger = new EventEmitter();
  @Output() openAssociationTrigger = new EventEmitter();
  @Output() closeAssociationTrigger = new EventEmitter();
  @Output() showOrHideAccordionTrigger = new EventEmitter();
  @Output() deleteMeasurementTrigger = new EventEmitter();
  @Output() toggleComponentVisibility = new EventEmitter();
  @ViewChild('inputElement') inputElement: ElementRef;
  showSelectedComponent: string | null = null;
  canvasComponentLabels = CANVAS_COMPONENT_LABELS;
  currentName: string;
  isEditId: string | null = null;
  componentStatus = COMPONENT_ASSOCIATION_STATUS_COMPARE;
  userRolePermissions: IUserRolesAndPermissions = USER_ROLE_PERMISSIONS;

   constructor(
    private canvasDataService: CanvasDataService,
    private notificationService: NotificationService,
  ) {}  
  showOrHideAccordion(indexValue: number) {
    this.showOrHideAccordionTrigger.emit(indexValue);
  }

  openAssociation(segmentationData: ISegmentationProperties) {
    this.openAssociationTrigger.emit(segmentationData);
  }

  showOrHideProperties(id: string) {
    if (this.showSelectedComponent !== id) {
      this.showSelectedComponent = id;
    } else {
      this.showSelectedComponent = null;
    }
  }

  openImageGalary(
    event: MouseEvent,
    segmentationData: ISegmentationProperties,
  ) {
    event.stopPropagation();
    this.openImageGalaryTrigger.emit(segmentationData);
  }

  onDeleteMeasurementClick(event: MouseEvent, measurementId: string) {
    event.stopPropagation();
    this.deleteMeasurementTrigger.emit(measurementId);
  }

  handleEditName(event: MouseEvent, item: ISegmentationProperties) {
    event.stopPropagation();
    this.currentName = item.name;
    this.isEditId = item.segmentId;
  }

  onClickOutside({ segmentId, name }: ISegmentationProperties) {
    this.isEditId = null;
    this.canvasDataService.updateSceneObjectById(segmentId, { name });
  }

  handleUpdateName(segmentId: string) {
    if (this.currentName) {
      this.closeAssociationTrigger.emit()
      this.canvasDataService.updateSceneObjectById(segmentId, {
        name: this.currentName,
      }).then(()=>{
        this.canvasDataService.updateSceneObjectOnNameChange(segmentId);
      });
    } else {
      this.notificationService.showToast({
        type: EventTypes.error,
        message: ANNOTATION_NAME_ERROR_MESSAGE,
      });
    }
    this.isEditId = null;
  }

  ngAfterViewChecked() {
    if (this.isEditId) {
      this.inputElement.nativeElement.focus();
    }
  }

  
  handleComponentVisibility(
    event: MouseEvent,
    component: ISegmentationAccordionList | ISegmentationProperties,
  ) {
    this.toggleComponentVisibility.emit(component);
    event.stopPropagation();
  }
}
