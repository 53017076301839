import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/visual-annotation/visual-annotation.module').then(module => module.Visual2dAnnotationModule),
  },
  {
    path: 'label-management', 
    loadChildren: () => import('./modules/label-management/label-management.module').then(module => module.LabelManagementModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{provide: APP_BASE_HREF, useValue: '/2d-annotation'}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
