import { EnvironmentService } from 'projects/shared-library/src/lib/services/environment.service';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

export class Routes {
  static baseAddress = apiUrl;

  // =============================== Data Resource =============================== //
  static GET_DATA_RESOURCE = Routes.baseAddress + '/data-resource/data-resource';
  
  // =============================== Inventory =============================== //
  static GET_ASSETS_LIST_BY_IDS = Routes.baseAddress + '/inventory/assets/list-by-ids';
  static GET_ASSETS_LIST = Routes.baseAddress + '/inventory/assets';


  // =============================== Missions =============================== //
  static GET_MISSIONS = Routes.baseAddress + '/mission/data-proc-only/missions';

  // =============================== Annotation 2d =============================== //
  static ANNOTATION_2D = Routes.baseAddress + '/canvas/annotation-2d';
  static GET_FINDINGS = Routes.baseAddress + '/canvas/inspection-findings';


  // =============================== Annotation 2d =============================== //
  static LABELS = Routes.baseAddress + '/canvas/labels';
  static FINDING_LABELS = Routes.baseAddress + '/canvas/finding-labels';
  static FINDING_SAVE = Routes.baseAddress + '/canvas/inspection-findings';

  // =============================== Label Management =============================== //

  static GET_LABEL_MANAGEMENT = Routes.baseAddress + "/canvas/finding-labels";
  static LABEL_TEMPLATE = Routes.baseAddress + "/canvas/label-templates";
  static LABEL_COMPONENTS = Routes.baseAddress + "/canvas/labels";
  static GET_INSTPECTIN_FINDING = Routes.baseAddress + "/canvas/inspection-findings";

  // =============================== Mission =============================== //
  static GET_MISSION = Routes.baseAddress + '/mission/data-proc-only/missions';

}
