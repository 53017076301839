<div class="property-container" *ngIf="diplayModal">
    <div class="property-container-layout" [ngStyle]="{'min-width': propertyWidth || ''}">
      <div class="property-container-layout-header">
        <div class="d-flex justify-content-between">
          <span class="property-container-layout-header-title">
           {{propertyModalConfig.title || 'Confirmation'}}
          </span>
          <span class="property-container-layout-header-close" (click)="onCancelClick()">
            <img [src]="'/icon/close.svg' | assetUrl" alt="round">
          </span>
        </div>
        <div class="property-container-layout-header-divider"></div>
      </div>
      <div class="property-container-layout-content">
       {{ propertyModalConfig.message || 'Are you sure ?'}}
      </div>
      <div class="property-container-layout-footer">
        <div class="property-container-layout-footer-buttons-groups d-flex align-items-end justify-content-between">
          <button type="button" class="btn custom-prop-btn" (click)="onCancelClick()">
            {{ propertyModalConfig.cancelBtnText || 'Cancel'}}
          </button>
          <button type="button" class="btn add-btn" (click)="onConfirmClick()" >
            {{propertyModalConfig.confirmBtnText || 'Confirm'}}
          </button>
        </div>
      </div>
    </div>
  </div>
  