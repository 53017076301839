import { Pipe, PipeTransform } from '@angular/core';
import { ThermalService } from '../services';
import { TemperatureType } from '../interfaces';

@Pipe({
  name: 'temperatureConversion'
})
export class TemperatureConversionPipe implements PipeTransform {
  constructor(private thermalService: ThermalService) {}
  transform(celsius?: string | number, opts?:{convertTo: TemperatureType, appendUnit?:boolean}): string | number | void {
    const temperatureValue = typeof celsius === 'string' ? parseFloat(celsius) : celsius;
    if (temperatureValue && !isNaN(temperatureValue) && opts) {
        const convertedValue = this.thermalService.temperatureConversion(temperatureValue, opts.convertTo);
        const unit = opts.appendUnit ? opts.convertTo === TemperatureType.Kelvin ? `${opts.convertTo}` : `°${opts.convertTo}` : '';
        return `${convertedValue}${unit}`;
    }
    return temperatureValue;
  }
}
