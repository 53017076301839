import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileNameFromUrl' })
export class FileNameFromUrllPipe implements PipeTransform {
  transform(value: string): string {
    let splitUrl = value.split('?');
    let splitName = splitUrl[0].split('/').pop();
    return splitName ? splitName : 'undefined';
  }
}
