import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GROUP_BY_OPTIONS, FINDINGS_GROUP, DATA_TYPES_TABS, NO_RECORD_FOUND, PAGE_LIMIT, API_MESSAGE_EVENTS, FINDINGS_MESSAGE, FINDING_MISS_MATCH_MESSAGE } from 'projects/annotation-2d/src/app/constants';
import { EventTypes, IAsset, IDataResourceRecord, IFindConfirmProperty, IFindRemoveData, IFindingsListing, IFindingsdata } from 'projects/annotation-2d/src/app/interfaces';
import { AnnotationDataResourceService, AnnotationVisualCanvasService, NotificationService } from 'projects/annotation-2d/src/app/services';
import { FindingsService } from 'projects/annotation-2d/src/app/services/findings.service';
import { forkJoin, lastValueFrom } from 'rxjs';
import { CustomConfirmPopupComponent } from '../custom-confirm-popup/custom-confirm-popup.component';

@Component({
  selector: 'app-findigs-listing',
  templateUrl: './findigs-listing.component.html',
  styleUrls: ['./findigs-listing.component.scss']
})
export class FindingsListingComponent implements OnInit,AfterViewInit {
  @Input() assetsList: IAsset[];
  @Output() findingAnnotations: EventEmitter<string[]> = new EventEmitter();
  @ViewChild('findingsDeleteConfirmDialog') findingsDeleteConfirmDialog: CustomConfirmPopupComponent;
  deleteFindingDialogConfig: IFindConfirmProperty = { // Delete confirmation
    title: FINDINGS_MESSAGE.DEL_CONFIRM_TITLE,
    message: FINDINGS_MESSAGE.DEL_CONFIRM_CONTENT,
    confirmBtnText: FINDINGS_MESSAGE.DEL_CONFIRM_DEL_BTN,
    cancelBtnText: FINDINGS_MESSAGE.DEL_CONFIRM_CANCEL_BTN
  }
  @Input() assetMissionId?: string
  noRecordFound = NO_RECORD_FOUND
  findingsListing: IFindingsdata[] = [];
  currentAssetTab!: string;
  FINDINGS_GROUP = FINDINGS_GROUP;
  DATA_TYPES_TABS = DATA_TYPES_TABS;
  GROUP_BY_OPTIONS = GROUP_BY_OPTIONS
  groupByDropdown:boolean = false;
  activeGroupByOption: string | null = null;
  listingGroup: any = [];
  missionId: any = this.activatedRoute.snapshot.paramMap.get('missionId');
  respNextCursor:string = '';
  currentMode: string = '';
  dataResourceMap: { [id: string]: IDataResourceRecord } = {};
  @ViewChild('findingsElement', { static: false }) findingsElement: ElementRef;
  currentFindings: IFindingsListing;
  constructor(
    private findingsService: FindingsService,
    private activatedRoute: ActivatedRoute,
    private annotationVisualCanvasService: AnnotationVisualCanvasService,
    private dataResourceService: AnnotationDataResourceService,
    private notificationService: NotificationService,
    ){}
  
  ngOnInit(){
    this.getFindingsbyMissionId();
    this.activatedRoute.params.subscribe((params) => {
      this.currentMode = params['annotationType'];
    });
    this.findingsService.onFindingsCreated().subscribe((data)=>{
      this.findingsListing.push(data);
      this.findingsService.findingsList.push(data);
    })
  }

  ngAfterViewInit() {
    this.findingsElement.nativeElement.addEventListener('scroll', () => {
      if(this.isScrollAtBottom(this.findingsElement.nativeElement) && this.respNextCursor)
        {
          this.getFindingsbyMissionId();
        }

    })
  }

  isScrollAtBottom(element: HTMLElement): boolean {
    return element.scrollHeight - Math.ceil(element.scrollTop) === element.clientHeight;
  }

  toggleGroupBy() {
    this.groupByDropdown = !this.groupByDropdown;
  }

  setActiveGroupBy(option: string) {
    this.activeGroupByOption = option;
    if(!option) {
      this.getFindingsbyMissionId();
    } else {
      this.groupListing(option);
    }
  }
  
  async getFindingsbyMissionId() {
    try {
        const { meta, records } = await lastValueFrom(
          this.findingsService.getFindingsAnnotationsoPopulated({
            missionId: this.missionId,
            pageLimit: PAGE_LIMIT.DEFAULT,
            nextCursor: this.respNextCursor,
          }),
        );
        
        this.respNextCursor = meta.nextCursor ?? '';
        records.forEach((finding) => {
          const findingAsset = this.getFindingAsset(finding.assetId ?? '');
          finding.assetName = findingAsset?.assetName;
        });
        this.findingsService.findingsList.push(...records)
        this.findingsListing.push(...records);
        if(!this.respNextCursor){
          this.findingsElement.nativeElement.removeEventListener('scroll');
        }
        const allDataResourceIds = this.extractDataResourceIds(
          this.findingsListing
        );
  
        const uniqueDataResourceIds = allDataResourceIds.filter(
          (id) => !this.dataResourceMap[id]
        );
  
        if (uniqueDataResourceIds.length) {
          const observables = uniqueDataResourceIds.map((dataResourceId) =>
            this.dataResourceService.getDataResourceById({ id: dataResourceId })
          );
  
          forkJoin(observables).subscribe((resourceRecords) => {
            resourceRecords.forEach((data) => {
              this.dataResourceMap[data._id] = data;
            });
            this.updateFindingsResourceTypes(resourceRecords);
          });
        } else {
          const resourceRecords = allDataResourceIds.map(
            (id) => this.dataResourceMap[id]
          );
          this.updateFindingsResourceTypes(resourceRecords);
        }
        } catch (error) {
      console.error('Error fetching findings by mission: ', error);
    }
  }

  groupListing(option: string): void {
    this.listingGroup = [];
    let groupBy = this.findingsListing.reduce((r:any, a:any) => {
      let sortingKey;
      switch(option) {
        case FINDINGS_GROUP.ASSET:
          sortingKey = a.assetId;
          break;
        case FINDINGS_GROUP.FINDINGS:
          sortingKey = a.findingLabel.name;
          break;
        default:
          sortingKey = a.severityLabel.name;
      }
      r[sortingKey] = r[sortingKey] || [];
      const tempFinding = {...a};
      const findingAsset = this.getFindingAsset(a.assetId);
      tempFinding.assetName = findingAsset?.assetName;
      tempFinding.assetLatitude = findingAsset?.assetLatitude;
      tempFinding.assetLongitude = findingAsset?.assetLongitude;
      r[sortingKey].push(tempFinding);
      return r;
    }, Object.create(null))
    for (let key in groupBy) {
      this.listingGroup.push(groupBy[key]);
    }
  }
  
  getFindingAsset(assetId: string) {
    const asset = this.assetsList.find(({_id}) => _id === assetId);
    return asset;
  }

  async handleFinding(findingsId: string) {
    const finding = this.findingsListing.find(({ _id }) => findingsId === _id);
    if (!finding) return;
    const matchingResourceType = finding.resourceType?.find(
      (type) =>
        typeof type === 'string' && type.toLowerCase() === this.currentMode
    );

    if (!matchingResourceType) {
      this.notificationService.showToast({
        type: EventTypes.warning,
        message: FINDING_MISS_MATCH_MESSAGE(this.currentMode),
      });
      return;
    }
    this.findingsService.selectedFindingId.next(findingsId);
    if(finding?.annotations.length) {
      const annotationId = finding.annotations[0]._id;
      this.annotationVisualCanvasService.setOpenImageviewer(true);
      this.dataResourceService.setActiveAnnotatedCanvas(annotationId);
    } else {
      this.annotationVisualCanvasService.setOpenImageviewer(false); //close gallery panel
      this.dataResourceService.setActiveAnnotatedCanvas(null); //reset findings
      this.annotationVisualCanvasService.setActiveFindingPanel(false); //close finding panel
      this.notificationService.showToast({
         type: EventTypes.error,
         message: `${API_MESSAGE_EVENTS.GENERIC_ERR_FINDING_IMAGE_MESSAGE.toLowerCase()}`,
       });
     }
  }

  // Delete Findings -----------------------
  onClickFindingToRemove(findingsData: IFindingsListing){
    this.currentFindings = {...findingsData , annotationId:findingsData.annotations.length > 0 ? findingsData.annotations[0] : '' };
    this.findingsDeleteConfirmDialog.openModal()
  }
  confirmDeleteFindings(confirm: boolean) {
    if (confirm) this.removeFindings(this.currentFindings);
      
    this.findingsDeleteConfirmDialog.closeModal();
    }
    
    removeFindingData:IFindRemoveData[] = [];
  // Remove Findings:
  async removeFindings(currentFindings: IFindingsListing) {
    this.findingsService.deleteFindingById(currentFindings._id).subscribe({
      next: (response) => {
        const findingIndex = this.findingsListing.findIndex(({ _id }) => currentFindings._id === _id);
        if(findingIndex > -1) this.findingsListing.splice(findingIndex, 1);
        this.notificationService.showToast({
          type: EventTypes.success,
          message: response?.['message'] || `${FINDINGS_MESSAGE.FINDINGS_DELETE_SUCCESS}`,
        });
      },
      error: (error) => {
        console.error(error);
        this.notificationService.showToast({
          type: EventTypes.error,
          message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
        });
      },
      complete: () => {
       if(currentFindings?.annotationId) this.removeFindingData.push({findingId:currentFindings._id, annotationId:currentFindings?.annotationId})
       this.findingsDeleteConfirmDialog.closeModal() //close popup
       this.annotationVisualCanvasService.setOpenImageviewer(false); //close gallery panel
       this.dataResourceService.setActiveAnnotatedCanvas(null); //reset findings
       this.annotationVisualCanvasService.setActiveFindingPanel(false); //close finding panel
       this.annotationVisualCanvasService.setRemovedFindings(this.removeFindingData);
       this.findingsService.findingsList = this.findingsService.findingsList.filter((finding)=>(!this.removeFindingData.some(({findingId})=>(findingId === finding._id))))
      },
    });
  }

  updateFindingsResourceTypes(resourceRecords: IDataResourceRecord[]) {
    this.findingsListing.forEach((finding: IFindingsdata) => {
      resourceRecords.forEach((data) => {
        const resourceType = this.mapResourceType(data.resourceType);
        finding.resourceType = finding.resourceType ?? [];
        const hasAnnotation = finding.annotations.some(
          (annotation) => annotation.dataResourceId === data._id
        );
        if (!finding.resourceType.includes(resourceType) && hasAnnotation) {
          finding.resourceType.push(resourceType);
        }
      });
    });
  }

  extractDataResourceIds(findings: IFindingsdata[]) {
    const dataResourceIds: string[] = [];
    findings.forEach((finding) => {
      finding.annotations.forEach((annotation: { dataResourceId: string }) => {
        dataResourceIds.push(annotation.dataResourceId);
      });
    });
    return [...new Set(dataResourceIds)];
  }

  private mapResourceType(type: string): string {
    switch (type) {
      case 'imageRGB':
        return 'Visual';
      case 'orthoRGBTiles':
        return 'Ortho';
      case 'imageThermal':
        return 'Thermal';
      case 'videoRGB':
        return 'Video';
      case 'meshNxz':
      case 'meshNxzTile':
        return '3D';
      default:
        return 'None';
    }
  }
  isCardDisabled(finding: IFindingsdata): boolean {
    return !finding.resourceType?.some(
      (type: string) => type?.toLowerCase() === this.currentMode?.toLowerCase()
    );
  }
}
