<div class="version-list-wrap">
  <div class="header-wrap">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <span class="title-wrap">
          <h6 class="header-title">View Versions</h6>
          <p class="header-sub-title">{{currentVersionObject?.sceneDefinitionDocName || ''}}</p>
        </span>
        <span class="close-btn-wrap" (click)="onCloseVersionList()">
          <img class="close-img" [src]="'/icon/close.svg' | assetUrl" alt="close">
        </span>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="version-list-content">
    <div class="level-drop-list mt-4">
      <div class="form-group">
        <div class="col-xs-12">
          <div class="dropdown">
            <button id="dLabel" class="dropdown-select" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{currentVersionObject ? currentVersionObject.sceneDefinitionDocName : 'Versions'}}
              <span class="caret">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </button>
            <ul class="dropdown-menu show" aria-labelledby="dLabel">
              @for (item of sceneDefDocVersionList; track item._id) {
              <li [ngClass]="item?._id === currentVersionObject?.sceneDefinitionDocId ? 'active' : ''"
                (click)="getSelectedVersionItem(item)">
                <div class="version-items">
                  <span class="v-content1"> {{item.name | trimFilter:45}} </span>
                  <span class="v-content2">{{item.updatedAt | date:'dd-MM-yyyy hh:mm'}}</span>
                </div>
              </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>