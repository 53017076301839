<div class="component-accordion">
  <div class="card">
    <div id="assetDetailAcc" class="card-header accordion-collapse-wrap d-flex"
      [class.collapsed]="accCollapsedStatus" (click)="accCollapsedStatus = !accCollapsedStatus">
        <span class="acc-img">
          <img class="accordion-arrow" [src]="'/icon/arrow-right.svg' | assetUrl" alt="accordion-arrow">
        </span>
        <span class="accordion-name-wrap">
          <div class="label">{{label}}</div>
        </span>
    </div>
    @if(heirarchy) {
      <div class="hierarchy">{{heirarchyString}}</div>
    }
    <div id="assetDetailAccTrt" [ngClass]="{'show' : !accCollapsedStatus,
            'collapsing': accCollapsedStatus}" aria-labelledby="assetDetailAcc">
      <div class="card-body">
        <ul>
          @for (item of data; track $index) {
          <li>
            <div class="shape-text">
              <span class="key-property">
                {{ item?.displayName }}
              </span>
            </div>
            <div class="value-property"> {{ item.actualValue }}</div>
          </li>
          }
        </ul>
      </div>
    </div>
  </div>
</div>