import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, of, take } from 'rxjs';
import { Routes } from './routes'
import { Annotation2DControlAction, EventTypes, IAiObjectProps, ICanvasImageProperty, ICanvasRectangleBox, IFindRemoveData, IGeometryAnnotationDto, IGeometryAnnotationListResponse, IMeasurementDetail, IRectangleProperty, IResourceImageRecord, IStageResourceRecord, IThumbnailInstance, IThumbnailRenderInstance } from '../interfaces';
import Konva from 'konva';
import { ANNOTATION_DRAWBOX_INIT_PROPERTY, API_MESSAGE_EVENTS, CLICK_THRESHOLD, POINTER_POSITION_DRAWBOX, STAGE_2D_CANVAS_COMMON_VIEW, THUMBNAIL_2D_VIEW_EVENTS, THUMB_STAGE_HORIZONTAL_VIEW, TRANSFORM } from '../constants';
import { Stage } from 'konva/lib/Stage';
import { Rect } from 'konva/lib/shapes/Rect';
import { Line, LineConfig } from 'konva/lib/shapes/Line';
import { Group } from 'konva/lib/Group';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { NotificationService } from './notification.service';
import { ICanvasStageProperties } from 'projects/digital-twin/src/app/interfaces/canvas.interface';
import { VisualCanvasAnnotationComponent } from '../shared/components/visual-canvas-annotation/visual-canvas-annotation.component';
import { Layer } from 'konva/lib/Layer';
@Injectable({
  providedIn: 'root',
})
export class AnnotationVisualCanvasService {
  viewer_stage_name: string = STAGE_2D_CANVAS_COMMON_VIEW.THUMB_STAGENAME;
  // get all annotation list:
  private getAllVisualAnnotationListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  getAllVisualAnnotationListObservable$: Observable<any> =
    this.getAllVisualAnnotationListSubject.asObservable();
  // finding panel open status:
  private findingPanelOpenStatusSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  findingPanelOpenStatusObservable$: Observable<boolean> =
    this.findingPanelOpenStatusSubject.asObservable();
  // save finding activation:
  private saveFindingActivateSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  saveFindingActivateObservable$: Observable<any> =
    this.saveFindingActivateSubject.asObservable();
  // Image gallery expand:
  private imageViewerExpandStatusSubject: Subject<boolean> =
    new Subject<boolean>();
  imageViewerExpandObservable$ =
    this.imageViewerExpandStatusSubject.asObservable();
  // active marked Annotation:
  private activeAnnotationMarkedSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  activeAnnotationMarkedObservable$: Observable<any> =
    this.activeAnnotationMarkedSubject.asObservable();
  // Image gallery close:
  private imageViewerOpenStatusSubject: Subject<boolean> =
    new Subject<boolean>();
  imageViewerOpenObservable$ = this.imageViewerOpenStatusSubject.asObservable();
  // setActive backgroundImgGroupSubject
  private activebackgroundImgGroupSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  activebackgroundImgGroupObservable$: Observable<any> =
    this.activebackgroundImgGroupSubject.asObservable();
  // set measurement values:
  private activeMeasurementListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  activeMeasurementListSubjectObservable$: Observable<any> =
    this.activeMeasurementListSubject.asObservable();
  // remove measurement values:
  private removePlusMarkerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  removePlusMarkerSubjectObservable$: Observable<any> = this.removePlusMarkerSubject.asObservable();

  // Active thumbnail viewer mode:
  imageThumbnailViewerModeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  imageThumbnailViewerModeObservable$: Observable<any> = this.imageThumbnailViewerModeSubject.asObservable();

  private removedFindsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  removedFindsSubjectObservable$: Observable<IFindRemoveData[]> = this.removedFindsSubject.asObservable();
  constructor(private httpClient: HttpClient, private notificationService: NotificationService) { }

  getAnnotation2dAll = (
    missionId: string = '',
    dataResourceId: string = '',
    tag: string = '',
    collectionId: string[] = []
  ): Observable<IGeometryAnnotationListResponse> => {
    const queryParams: string = `missionId=${missionId}&pageLimit=1000`;
    return this.httpClient
      .get<IGeometryAnnotationListResponse>(
        `${Routes.ANNOTATION_2D}?${queryParams}`
      )
      .pipe(take(1));
  };

  saveAnnotation2d = (
    body: IGeometryAnnotationDto
  ): Observable<IGeometryAnnotationDto> => {
    return this.httpClient
      .post<IGeometryAnnotationDto>(Routes.ANNOTATION_2D, body)
      .pipe(take(1));
  };

  getAnnotation2dById = (
    annotateId: string = ''
  ): Observable<IGeometryAnnotationDto> => {
    return this.httpClient
      .get<IGeometryAnnotationDto>(`${Routes.ANNOTATION_2D}/${annotateId}`)
      .pipe(take(1));
  };

  updateAnnotation2d = (
    annotateId: string = '',
    body: IGeometryAnnotationDto
  ): Observable<IGeometryAnnotationDto> => {
    return this.httpClient
      .patch<IGeometryAnnotationDto>(
        `${Routes.ANNOTATION_2D}/${annotateId}`,
        body
      )
      .pipe(take(1));
  };

  deleteAnnotation2dById = (
    annotateId: string = ''
  ): Observable<IGeometryAnnotationDto> => {
    return this.httpClient
      .delete<IGeometryAnnotationDto>(`${Routes.ANNOTATION_2D}/${annotateId}`)
      .pipe(take(1));
  };

  // ************************* Recursive api call **************************

  setAllVisualGalleryAnnotationList(data: any[]) {
    this.getAllVisualAnnotationListSubject.next(data);
  }

  setActiveFindingPanel(openStatus: boolean) {
    this.findingPanelOpenStatusSubject.next(openStatus);
  }

  getActiveFindingPanelStatus() {
    return this.findingPanelOpenStatusSubject.getValue();
  }
  setRemovedFindings(data: IFindRemoveData[]) {
    this.removedFindsSubject.next(data);
  }
  getActiveMarkedAnnotation() {
    return this.activeAnnotationMarkedSubject.getValue();
  }

  setActiveSavedFindingPanel(data: any) {
    this.saveFindingActivateSubject.next(data);
  }

  setExpandImageviewer(data: any) {
    this.imageViewerExpandStatusSubject.next(data);
  }

  setActiveMarkedAnnotation(data: any) {
    this.activeAnnotationMarkedSubject.next(data);
  }

  setOpenImageviewer(data: any) {
    this.imageViewerOpenStatusSubject.next(data);
  }

  setActivebackgroundImgGroup(data: any) {
    this.activebackgroundImgGroupSubject.next(data);
  }

  setActiveMeasurementList(data: IMeasurementDetail[]) {
    this.activeMeasurementListSubject.next(data);
  }

  setRemovePlusMarkerThermal(data: IMeasurementDetail) {
    this.removePlusMarkerSubject.next(data);
  }

  setImageThumbnailViewerMode(data: any) {
    this.imageThumbnailViewerModeSubject.next(data);
  }
  // --------------------------------------------------------------------------
  // ************************* Tool tip configuration **************************
  //----------------------------------------------------------------------------
  currentActivetoolTipLabelGroup: any = '';
  createToolTipLabelForShape(
    drawBox: any,
    backgroundImgGroup: any,
    shapeGroup: any
  ) {
    // 1. Create rect bg ,label and group
    const toolTipLabelGroup = this.drawToolTipCreateConfigSetting(drawBox);
    // 2.top of shape textname update current shape
    this.toolTipLabelNameUpdatedShape(drawBox, toolTipLabelGroup);
    // 3.top of shape position update current shape
    this.toolTipPlacedPositionTopOfShape(drawBox, toolTipLabelGroup);
    // 4.tooltip group added into shapGroup group:
    shapeGroup.add(toolTipLabelGroup);
    this.currentActivetoolTipLabelGroup = toolTipLabelGroup;
  }

  // Draw text label name:
  drawToolTipCreateConfigSetting(drawBox: any) {
    const tooltipLabel = new Konva.Text({
      text: '',
      fontFamily: 'Calibri',
      fontSize: 40,
      padding: 15,
      textFill: 'white',
      fill: 'white',
      visible: true,
      opacity: 0.8,
      name: 'toolTipText',
      id: drawBox.id() || this.generateRandomId(5),
    });
    const tooltipRect = new Konva.Rect({
      width: tooltipLabel.width(),
      height: tooltipLabel.height(),
      padding: 1,
      stroke: 'black',
      fill: 'black',
      strokeWidth: 1,
      visible: true,
      opacity: 0.5,
      cornerRadius: 10,
      name: 'toolTipRect',
      id: drawBox.id() || this.generateRandomId(5),
    });
    const toolTipLabelGroup = new Konva.Group({
      draggable: false,
      name: 'toolTipLabelGroup', // Set a name for the group
      id: drawBox.id() || this.generateRandomId(5),
    });
    toolTipLabelGroup.add(tooltipRect, tooltipLabel);
    return toolTipLabelGroup;
  }

  // -------------------- get Current ToolTip Group: -----------------------------------
  currentActiveToolTipGroup(drawBoxId: string, backgroundImgGroup: any) {
    let currentToolTipGroup = '';
    const toolTipLabelGroups: any[] =
      this.findAllTooltipGroupBySelectedBackgroundGroupImage(
        backgroundImgGroup
      );
    if (toolTipLabelGroups?.length > 0) {
      toolTipLabelGroups.forEach((toolTipGrp) => {
        if (toolTipGrp.id() === drawBoxId) currentToolTipGroup = toolTipGrp;
      });
    }
    return currentToolTipGroup;
  }

  // tooltip [name Updated] By : selected drawbox
  toolTipLabelNameUpdatedShape(drawBox: any, toolTipLabelGroup: any) {
    const { Rect: tooltipRect, Text: tooltipLabel } =
      this.getCurrentToolTipGroupChildern(toolTipLabelGroup);
    tooltipLabel.text(drawBox.getAttr('componentName')); // Math.random()
    tooltipRect.width(tooltipLabel.width());
  }

  // tooltip [postion Updated] By : selected drawbox
  toolTipPlacedPositionTopOfShape(drawBox: any, toolTipLabelGroup: any) {
    const { Rect: tooltipRect, Text: tooltipLabel } =
      this.getCurrentToolTipGroupChildern(toolTipLabelGroup);
    const shapeName = drawBox.name();
    let drawBoxCoorinates: IRectangleProperty = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    switch (shapeName) {
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME:
        drawBoxCoorinates = {
          x: drawBox.x(),
          y: drawBox.y(),
          width: drawBox.width(),
          height: drawBox.height(),
        };
        break;
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME:
        const latestPoints = drawBox.getAttr('newRefPoint') || drawBox.points();
        drawBoxCoorinates =
          this.convertPolygonToRectangleCoordinate(latestPoints);
        break;
    }
    const toolTipParams = {
      drawBoxProp: drawBoxCoorinates,
      tooltipLabel,
      tooltipRect,
    };
    this.updateToolTipPosition(toolTipParams);
  }

  updateToolTipPosition(toolTipParams: {
    drawBoxProp: IRectangleProperty;
    tooltipLabel: Konva.Text;
    tooltipRect: Konva.Rect;
  }): void {
    // Access parameters using the object properties
    const { drawBoxProp, tooltipLabel, tooltipRect } = toolTipParams;
    const defaultPaddingAdjust = 8;
    const textX = drawBoxProp.x + (drawBoxProp.width - tooltipLabel.width()); //start text right alignment:
    const textY = drawBoxProp.y - tooltipLabel.height() - defaultPaddingAdjust;
    tooltipLabel.position({ x: textX, y: textY });
    tooltipRect.position({ x: textX, y: textY });
  }

  // [label, rectLabel] props by Current ToolTip Group:
  getCurrentToolTipGroupChildern(toolTipLabelGroup: any) {
    const children = toolTipLabelGroup?.hasChildren()
      ? toolTipLabelGroup.getChildren()
      : []; // Get all the tool tip children in the group
    const toolTipChild: any = {};
    if (children?.length > 0) {
      children.forEach(
        (child: {
          id(): any;
          getClassName: () => string;
          destroy: () => void;
        }) => {
          if (child.getClassName()) toolTipChild[child.getClassName()] = child;
        }
      );
    }
    return toolTipChild;
  }

  // --------------------------[Id updation ]-----------------------------------------------------
  updateIdToCurrentActiveToolTip(annotate_id: string) {
    if (this.currentActivetoolTipLabelGroup) {
      const { Rect: tooltipRect, Text: tooltipLabel } =
        this.getCurrentToolTipGroupChildern(
          this.currentActivetoolTipLabelGroup
        );
      this.currentActivetoolTipLabelGroup.id(annotate_id);
      tooltipRect.id(annotate_id);
      tooltipLabel.id(annotate_id);
    }
  }

  // -------------------------- show / Hide tool tip -----------------------------------
  toolTipGroupShowHideFindByDrawBox(
    drawBox: any,
    visible: boolean,
    backgroundImgGroup: any
  ) {
    const findId = drawBox.id();
    const toolTipLabelGroups: any[] =
      this.findAllTooltipGroupBySelectedBackgroundGroupImage(
        backgroundImgGroup
      );
    toolTipLabelGroups.forEach((toolTipGrp) => {
      if (toolTipGrp.id() === findId)
        this.toolTipGroupShowHideByGroup(toolTipGrp, visible);
    });
  }

  toolTipGroupShowHideAll(backgroundImgGroup: any, visible: boolean) {
    const toolTipLabelGroups: any[] =
      this.findAllTooltipGroupBySelectedBackgroundGroupImage(
        backgroundImgGroup
      );
    toolTipLabelGroups.forEach((toolTipGrp) => {
      this.toolTipGroupShowHideByGroup(toolTipGrp, visible);
    });
  }

  // get visible hide/show by group
  toolTipGroupShowHideByGroup(toolTipLabelGroup: any, visible: boolean) {
    const { Rect: tooltipRect, Text: tooltipLabel } =
      this.getCurrentToolTipGroupChildern(toolTipLabelGroup);
    tooltipRect.visible(visible);
    tooltipLabel.visible(visible);
  }

  // fetch All toolTip groups from background images:
  findAllTooltipGroupBySelectedBackgroundGroupImage(backgroundImgGroup: any) {
    const toolTipChildGroups: any[] = [];
    if (backgroundImgGroup) {
      const grpImageChild: any = backgroundImgGroup.children || [];
      if (grpImageChild?.length > 0) {
        grpImageChild.forEach((shapeGroup: any) => {
          if (
            shapeGroup.getClassName() === 'Group' &&
            shapeGroup.name() === 'shapeGroup'
          ) {
            const shapeGrpChild: any = shapeGroup.getChildren() || []; //[Line,Group]
            shapeGrpChild?.length > 0 &&
              shapeGrpChild.forEach((toolTipGrp: any) => {
                if (toolTipGrp.name() === 'toolTipLabelGroup') {
                  //find tooltip group
                  toolTipChildGroups.push(toolTipGrp);
                }
              });
          }
        });
      }
    }
    return toolTipChildGroups;
  }
  // ************************* End Tool tip configuration ********************
  // --------------------------------------------------------------------------
  convertPolygonToRectangleCoordinate(polygonPoints: any[]) {
    let rectCoordinate = { x: 0, y: 0, width: 0, height: 0 };
    if (polygonPoints?.length > 0) {
      // Extract x and y coordinates from the points
      const xCoordinates = polygonPoints.filter((_, index) => index % 2 === 0);
      const yCoordinates = polygonPoints.filter((_, index) => index % 2 !== 0);
      // Find the minimum and maximum x and y coordinates
      const minX = Math.min(...xCoordinates);
      const minY = Math.min(...yCoordinates);
      const maxX = Math.max(...xCoordinates);
      const maxY = Math.max(...yCoordinates);
      // Calculate the width and height of the rectangle
      const width = maxX - minX;
      const height = maxY - minY;
      rectCoordinate = { x: minX, y: minY, width, height };
    }
    return rectCoordinate;
  }

  // Convert rectangle coordinates to polygon coordinates
  convertRectangleToPolygonCoordinate(geometricRectangle: ICanvasRectangleBox) {
    const polygonX = Number(geometricRectangle.x);
    const polygonY = Number(geometricRectangle.y);
    const polygonWidth = Number(geometricRectangle.width);
    const polygonHeight = Number(geometricRectangle.height);
    const x1 = polygonX;
    const y1 = polygonY;
    const x2 = polygonX + polygonWidth;
    const y2 = polygonY;
    const x3 = polygonX + polygonWidth;
    const y3 = polygonY + polygonHeight;
    const x4 = polygonX;
    const y4 = polygonY + polygonHeight;
    // Calculate polygon coordinates
    const polygonPoints: number[] = [
      x1,
      y1, // Top-left corner
      x2,
      y2, // Top-right corner
      x3,
      y3, // Bottom-right corner
      x4,
      y4, // Bottom-left corner
    ];
    return polygonPoints;
  }

  generateRandomId(length: number) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  // -----------------------------------------------------------
  // ****************** animation play fade in out ---------------
  getFadeInAnimatedBackgroundImage(backgroundImageData: any) {
    // Animate the fade-in effect
    const fadeInDuration = STAGE_2D_CANVAS_COMMON_VIEW.BG_FADE_IN_DURATION_T1; // Duration of the fade-in effect in seconds
    const fadeInTween = new Konva.Tween({
      node: backgroundImageData,
      opacity: STAGE_2D_CANVAS_COMMON_VIEW.BG_FADE_IN_OPACITY, // Target opacity for the fade-in effect
      duration: fadeInDuration,
    });
    fadeInTween.play();
  }

  getFadeInBgThumbnail(bgImgGroupInstance: any) {
    // Animate the fade-in effect
    const fadeInDuration =
      ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FADE_IN_DURATION_T1; // Duration of the fade-in effect in seconds
    const fadeInTween = new Konva.Tween({
      node: bgImgGroupInstance,
      opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FADE_IN_OPACITY, // Target opacity for the fade-in effect
      duration: fadeInDuration,
    });
    fadeInTween.play();
  }

  getFadeInAnimatedRectBox(drawBox: Rect | Konva.Line) {
    // Animate the fade-in effect
    const fadeInDuration =
      ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FADE_IN_DURATION; // Duration of the fade-in effect in seconds
    const fadeInTween = new Konva.Tween({
      node: drawBox,
      opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FADE_IN_OPACITY, // Target opacity for the fade-in effect
      duration: fadeInDuration,
      easing: Konva.Easings.EaseInOut,
    });
    fadeInTween.play();
  }

  expandedOrShrinkBgGroupImage(backgroundImgGroup: any) {
    const zoomFactorValue = 1.2;
    const newScaleX = backgroundImgGroup.scaleX() * zoomFactorValue;
    const newScaleY = backgroundImgGroup.scaleY() * zoomFactorValue;
    const animation = new Konva.Tween({
      node: backgroundImgGroup,
      scaleX: newScaleX,
      scaleY: newScaleY,
      duration: 1,
      easing: Konva.Easings.EaseInOut,
    });
    // Start the animation
    animation.play();
  }

  // ----------------------------------------------------------------------------------------
  // ****************** OnChange draw or update to image viewer/thumnail list ---------------
  async invokeMainInstance() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (
          targetStage.getAttr('name') ===
          STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_CONTAINER
        ) {
          targetStage.draw();
        }
      }
    });
  }
  // Image Viewer:
  async destroyAllExpandViewStageCanvas() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (
          targetStage.getAttr('name') ===
          STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_CONTAINER
        ) {
          targetStage.destroy();
        }
      }
    });
  }

  // 2.Remove canvas view rectangle cooridnates before init
  async removeAllAnnotateStageViewer() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (
          targetStage.getAttr('name') ===
          STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_CONTAINER
        ) {
          const rectangles = targetStage.find(
            STAGE_2D_CANVAS_COMMON_VIEW.DRAW_LINE
          );
          if (rectangles?.length > 0) {
            rectangles.forEach((rect) => {
              rect.remove();
            });
            targetStage.draw();
          }
        }
      }
    });
  }

  // Remove draw box by stage Id:
  removeSelectedStageAnnotation(annotateEvent: IStageResourceRecord) {
    const stageId = annotateEvent.stageProperty.id;
    const targetStage: Stage | undefined = Konva.stages.find(
      (stage) => stage.attrs.id === stageId
    );
    if (targetStage) {
      const rectangles = targetStage.find('Rect');
      if (rectangles?.length > 0) {
        rectangles.forEach((rect) => {
          rect.remove();
        });
        targetStage.draw();
      }
    }
  }

  // -------------------------------------------------------
  // 2. Dynamic updation or add to thumbnail view: not use
  async onUpdateCanvasChildPropertyByItem(
    annotateEvent: IStageResourceRecord,
    currentShape: string
  ) {
    const stageId = annotateEvent.stageProperty.id;
    const annotationRecords = annotateEvent.annotationRecords;
    const targetStage: Stage | undefined = Konva.stages.find(
      (stage) => stage.attrs.id === stageId
    );
    if (targetStage) {
      const stageRectIds: string[] = [];
      if (currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME) {
        const rectangleData = targetStage.find('Rect');
        if (rectangleData?.length > 0) {
          rectangleData.forEach((rect) => {
            if (rect.id()) stageRectIds.push(rect.id());
          });
        }
        this.getUpdateOrAddShape(
          targetStage,
          stageRectIds,
          annotationRecords,
          currentShape
        );
      } else if (currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME) {
        const polygonData = this.findPolygonInStage(targetStage);
        if (polygonData) stageRectIds.push(polygonData.id());
        this.getUpdateOrAddShape(
          targetStage,
          stageRectIds,
          annotationRecords,
          currentShape
        );
      }
    }
  }

  async getUpdateOrAddShape(
    targetStage: any,
    stageRectIds: string[],
    annotationRecords: any[],
    currentShape: string
  ) {
    if (stageRectIds?.length > 0) {
      if (annotationRecords?.length > 0) {
        for (const recordVal of annotationRecords) {
          const geometricRect =
            currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME
              ? recordVal.geometry.coordinates
              : recordVal['rectCoordinate'];
          const shapeId =
            currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME
              ? recordVal._id
              : String(recordVal['rectCoordinate'].id);
          await this.targetStageUpdateBox(
            targetStage,
            stageRectIds,
            geometricRect,
            currentShape,
            shapeId
          );
        }
      }
    } else if (annotationRecords?.length > 0) {
      for (const recordVal of annotationRecords) {
        const geometricRect =
          currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME
            ? recordVal.geometry.coordinates
            : recordVal['rectCoordinate'];
        const shapeId =
          currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME
            ? recordVal._id
            : String(recordVal['rectCoordinate'].id);
        await this.targetStageAddBox(
          targetStage,
          geometricRect,
          currentShape,
          shapeId
        );
      }
    }
  }

  // Function to find the first polygon in the stage
  findPolygonInStage(stageNode: any): any {
    if (stageNode instanceof Konva.Line) {
      return stageNode; // Found the polygon, return it
    }
    if (stageNode.hasChildren()) {
      const children = stageNode.getChildren();
      for (const child of children) {
        const foundPolygon = this.findPolygonInStage(child);
        if (foundPolygon) {
          return foundPolygon; // Return the polygon if found in child node
        }
      }
    }
  }

  // 1. on stage update box in thumbnail view:
  async targetStageUpdateBox(
    targetStage: Stage | Konva.Stage,
    rectangleIds: string[],
    geometricValue: ICanvasRectangleBox | any,
    currentShape: string,
    shapeId: string
  ) {
    if (rectangleIds.includes(String(shapeId))) {
      const layer = targetStage.children.find(
        (data: any) => data instanceof Konva.Layer
      ) as Konva.Layer;
      const shape: any = targetStage.findOne(`#${shapeId}`);
      if (shape.id()) {
        if (currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME) {
          const attr = {
            x: Number(geometricValue.x).toFixed(1),
            y: Number(geometricValue.y).toFixed(1),
            width: Number(geometricValue.width).toFixed(1),
            height: Number(geometricValue.height).toFixed(1),
          };
          shape.setAttr(attr);
        } else if (
          currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME
        ) {
          const imageNode = targetStage.findOne('Image');
          const thumbImagObj: any = {};
          if (imageNode) {
            thumbImagObj.width = imageNode.width();
            thumbImagObj.height = imageNode.height();
            const pointsCoordinate: [number, number][] = geometricValue;
            const calculatedPoints: [number, number][] =
              this.getConvertPolygonImageBasedXY(
                thumbImagObj,
                pointsCoordinate
              );
            // Flatten the nested points into a single array points:
            const singleArPolyPoints: number[] =
              this.getSingleFlatArray(calculatedPoints); //original points
            shape.points(singleArPolyPoints);
          }
        }
        layer.batchDraw();
        targetStage.batchDraw();
      }
    } else {
      this.targetStageAddBox(
        targetStage,
        geometricValue,
        currentShape,
        shapeId
      );
    }
  }

  // 2. on stage Add box in thumbnail view:
  async targetStageAddBox(
    targetStage: any,
    geometricValue: ICanvasRectangleBox | any,
    currentShape: string,
    shapeId: string
  ) {
    // Find the layer within the target stage
    const layer = targetStage.children.find(
      (data: any) => data instanceof Konva.Layer
    );
    if (layer) {
      // Perform operations on the layer or add shapes to it
      let rectBox;
      const imageNode = targetStage.findOne('Image');
      const thumbImagObj: any = {};
      if (currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME) {
        const rectangleProperties: ICanvasRectangleBox = {
          x: geometricValue.x,
          y: geometricValue.y,
          width: geometricValue.width,
          height: geometricValue.height,
          fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
          stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
          strokeWidth: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKEWIDTH_T2,
          shadowBlur: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_SHADOWBLUR,
          id: shapeId,
          draggable: false,
          name: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME,
        };
        rectBox = new Konva.Rect(rectangleProperties);
      } else if (currentShape === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME) {
        if (imageNode) {
          thumbImagObj.width = imageNode.width();
          thumbImagObj.height = imageNode.height();
          const pointsCoordinate: [number, number][] = geometricValue;
          const calculatedPoints: [number, number][] =
            this.getConvertPolygonImageBasedXY(thumbImagObj, pointsCoordinate);
          const singleArPolyPoints: number[] =
            this.getSingleFlatArray(calculatedPoints); //original points
          rectBox = new Konva.Line({
            points: singleArPolyPoints,
            fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
            stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
            strokeWidth:
              ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
            draggable: false,
            id: shapeId,
            closed: true,
            hitStrokeWidth:
              ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
            name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME,
          });
        }
      }
      layer.add(rectBox);
      targetStage.add(layer);
      layer.batchDraw();
      targetStage.batchDraw();
    }
  }

  // ----------------------------------------------------------------------------------
  // ************************* scale 0 to 1 store to database **************************
  // ----------------------------------------------------------------------------------
  setConvertRectangleImageBasedXY(
    imageProperty: ICanvasImageProperty,
    dataCooridnates: ICanvasRectangleBox
  ) {
    const displayedImageWidth = imageProperty.width;
    const displayedImageHeight = imageProperty.height;
    const rectScaleX = Number(dataCooridnates.x) * displayedImageWidth;
    const rectScaleY = Number(dataCooridnates.y) * displayedImageHeight;
    const rectScaleWidth = Number(dataCooridnates.width) * displayedImageWidth;
    const rectScaleHight =
      Number(dataCooridnates.height) * displayedImageHeight;
    return { rectScaleX, rectScaleY, rectScaleWidth, rectScaleHight };
  }

  setConvertPolygonImageBasedXY(linePoints: number[], imageObj: any) {
    const originImgWidth = imageObj.width;
    const originImgHeight = imageObj.height;
    const polygonPoints2dAr: [number, number][] = [];
    for (let i = 0; i < linePoints.length; i += 2) {
      const x = Number(linePoints[i] / originImgWidth).toFixed(4);
      const y = Number(linePoints[i + 1] / originImgHeight).toFixed(4);
      polygonPoints2dAr.push([Number(x), Number(y)]);
    }
    return polygonPoints2dAr;
  }

  // getCalulation poly cooridinates by image properties:
  getConvertPolygonImageBasedXY(
    imageProperty: ICanvasImageProperty,
    pointCoordinate: any
  ) {
    const displayedImageWidth = imageProperty.width;
    const displayedImageHeight = imageProperty.height;
    // Flatten the nested array into a single array
    const listPoints = pointCoordinate
      .reduce((acc: string | any[], val: string) => acc.concat(val), [])
      .flat();
    const polygonPoints2dAr: [number, number][] = [];
    for (let i = 0; i < listPoints.length; i += 2) {
      const x = Number(listPoints[i] * displayedImageWidth);
      const y = Number(listPoints[i + 1] * displayedImageHeight);
      polygonPoints2dAr.push([Number(x), Number(y)]);
    }
    return polygonPoints2dAr;
  }

  getSingleFlatArray(points: any) {
    return points
      .reduce((acc: string | any[], val: string) => acc.concat(val), [])
      .flat();
  }

  updateRectValueAndConvertToPolygonPoints(rect: Rect | Konva.Line) {
    const scaleX = rect.scaleX();
    const scaleY = rect.scaleY();
    const newHeight = rect.height() * scaleY;
    const newWidth = rect.width() * scaleX;
    rect.height(newHeight);
    rect.width(newWidth);
    rect.scaleX(1);
    rect.scaleY(1);
    const geometricRectangle: ICanvasRectangleBox = {
      x: rect.x(),
      y: rect.y(),
      width: rect.width(),
      height: rect.height(),
      id: rect.id(),
    };
    // Convert rectangle coordinates to polygon coordinates // ICanvasCooridnates;
    const polygonPoints: number[] =
      this.convertRectangleToPolygonCoordinate(geometricRectangle);
    return polygonPoints;
  }

  // -------------------------[AI Tag Annotation configuration ]------------------------------------------------
  // add stroke as red
  // add tooltip over shape
  aiTagRelatedAnnotationConfiguration(aiObjectProps: IAiObjectProps) {
    const drawBox = aiObjectProps.drawBox;
    // #mark as red:
    this.aiAnnotationInitialMarkedRedStroke(drawBox);
  }

  findingSavedAfterSelectiveAnnotationConfigure(
    backgroundImgGroup: any,
    drawBox: any
  ) {
    // hide all tooltip:
    this.toolTipGroupShowHideFindByDrawBox(drawBox, false, backgroundImgGroup); //#toolTip
    // Mark as stroke primary:
    this.changeStrokePrimaryColorViewerCurrentShapes(
      backgroundImgGroup,
      drawBox.id()
    );
  }

  changeStrokePrimaryColorViewerCurrentShapes(
    backgroundImgGroup: any,
    drawBoxId: string
  ) {
    if (backgroundImgGroup) {
      const shapeAllGroups: any[] =
        this.findAllShapeGroupBySelectedBackgroundGroupImage(
          backgroundImgGroup
        );
      if (shapeAllGroups?.length > 0) {
        //All shapeGroups
        shapeAllGroups.forEach((shapeGrp) => {
          // [Group, Group...]
          if (shapeGrp.name() === 'shapeGroup') {
            // each shapeGroup
            if (shapeGrp.hasChildren()) {
              // [Line,Group]
              shapeGrp.getChildren().forEach((drawBox: Line | Rect | Group) => {
                if (
                  drawBox.getClassName() === 'Line' ||
                  drawBox.getClassName() === 'Rect'
                ) {
                  if (drawBox.id() === drawBoxId) {
                    drawBox.setAttr('aiTag', false);
                    this.aiAnnotationMarkedPrimaryStroke(drawBox);
                  }
                }
              });
            }
          }
        });
      }
    }
  }

  changeAllStrokePrimaryColorViewerShapes(backgroundImgGroup: any) {
    if (backgroundImgGroup) {
      const shapeAllGroups: any[] =
        this.findAllShapeGroupBySelectedBackgroundGroupImage(
          backgroundImgGroup
        );
      if (shapeAllGroups?.length > 0) {
        //All shapeGroups
        shapeAllGroups.forEach((shapeGrp) => {
          // [Group, Group...]
          if (shapeGrp.name() === 'shapeGroup') {
            // each shapeGroup
            if (shapeGrp.hasChildren()) {
              // [Line,Group]
              shapeGrp.getChildren().forEach((drawBox: Line | Rect | Group) => {
                if (
                  drawBox.getClassName() === 'Line' ||
                  drawBox.getClassName() === 'Rect'
                ) {
                  this.aiAnnotationMarkedPrimaryStroke(drawBox);
                }
              });
            }
          }
        });
      }
    }
  }

  //ai annotaition stroke updation:
  aiAnnotationInitialMarkedRedStroke(drawBox: any) {
    drawBox.stroke(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE_RED); //border
    drawBox.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL_RED); //bg fill
  }

  aiAnnotationMarkedPrimaryStroke(drawBox: any) {
    drawBox.stroke(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE);
    drawBox.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL);
  }
  // -------------------------[END AI Tag Annotation configuration ]----------------------------------------------
  // --------------------------[Shape Group - Rectangle & polygon]--------------------
  getCurrentStageBackgroundImageGroup(stageId: string) {
    const targetStage: Stage = Konva.stages.find(
      (stage) => stage.attrs.id === stageId
    ) as Konva.Stage;
    const layer =
      (targetStage
        .getChildren()
        .find((data: any) => data instanceof Konva.Layer) as Konva.Layer) || [];
    const bgGroup =
      layer.getChildren().find((data: any) => data instanceof Konva.Group) ||
      null;
    return { targetStage, layer, bgGroup };
  }

  // get current ShapeGroup:
  currentActiveShapeGroup(drawBoxId: string, backgroundImgGroup: any) {
    let currentActiveShapeGroupData: any;
    const shapeAllGroups: any[] =
      this.findAllShapeGroupBySelectedBackgroundGroupImage(backgroundImgGroup);
    if (shapeAllGroups?.length > 0) {
      shapeAllGroups.forEach((shapeGrp) => {
        if (shapeGrp.id() === drawBoxId) currentActiveShapeGroupData = shapeGrp;
      });
    }
    return currentActiveShapeGroupData;
  }

  getCurrentDrawBoxProperty(drawBoxId: string, backgroundImgGroup: any) {
    const shapeGrp: any = this.currentActiveShapeGroup(drawBoxId, backgroundImgGroup);
    return this.getDrawBoxPropertyByShapeGroup(drawBoxId, shapeGrp)
  }

  getDrawBoxPropertyByShapeGroup(drawBoxId: string, shapeGrp: any) {
    let drawBox: any = '';
    if (
      shapeGrp &&
      shapeGrp.getClassName() === 'Group' &&
      shapeGrp.name() === 'shapeGroup'
    ) {
      // each shapeGroup
      if (shapeGrp.hasChildren()) {
        //[Line,Group]
        shapeGrp
          .getChildren()
          .forEach((shape: Konva.Line | Konva.Rect | Konva.Group) => {
            if (
              shape.getClassName() === 'Line' ||
              shape.getClassName() === 'Rect'
            ) {
              if (shape.id() === drawBoxId) drawBox = shape;
            }
          });
      }
    }
    return drawBox;
  }

  // findAll ShapeGroup
  findAllShapeGroupBySelectedBackgroundGroupImage(backgroundImgGroup: any) {
    let getAllshapeGroups: any[] = [];
    if (backgroundImgGroup) {
      const grpImageChild: any = backgroundImgGroup.getChildren() || [];
      if (grpImageChild?.length > 0) {
        grpImageChild.forEach((group: any) => {
          if (group.getClassName() === 'Group') {
            if (group.name() === 'shapeGroup') {
              //find shape group
              getAllshapeGroups.push(group);
            }
          }
        });
      }
    }
    return getAllshapeGroups;
  }

  // set active/inactive stroke
  setDefaultStrokeAllShapeInGroup(backgroundImgGroup: any) {
    if (backgroundImgGroup) {
      const shapeAllGroups: any[] =
        this.findAllShapeGroupBySelectedBackgroundGroupImage(
          backgroundImgGroup
        );
      if (shapeAllGroups?.length > 0) {
        shapeAllGroups.forEach((shapGrp) => {
          // [Group, Group...]
          if (
            shapGrp.getClassName() === 'Group' &&
            shapGrp.name() === 'shapeGroup'
          ) {
            // each shapeGroup
            if (shapGrp.hasChildren()) {
              //[Line,Group]
              shapGrp
                .getChildren()
                .forEach((drawBox: Konva.Line | Konva.Rect) => {
                  if (
                    drawBox.getClassName() === 'Line' ||
                    drawBox.getClassName() === 'Rect'
                  ) {
                    if (drawBox.getAttr('aiTag'))
                      drawBox.stroke(
                        ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE_RED
                      );
                    else
                      drawBox.stroke(
                        ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE
                      );
                  }
                });
            }
          }
        });
      }
    }
  }

  // strokeWidth adjust For All Shape:
  strokeWidthAdjustViewerShapes(backgroundImgGroup: any, zoomPoint: number) {
    if (backgroundImgGroup) {
      const shapeAllGroups: any[] =
        this.findAllShapeGroupBySelectedBackgroundGroupImage(
          backgroundImgGroup
        );
      if (shapeAllGroups?.length > 0) {
        shapeAllGroups.forEach((shapeGrp) => {
          // [Group, Group...]
          if (
            shapeGrp.getClassName() === 'Group' &&
            shapeGrp.name() === 'shapeGroup'
          ) {
            // each shapeGroup
            if (shapeGrp.hasChildren()) {
              //[Line,Group]
              shapeGrp.getChildren().forEach((shape: Line | Rect) => {
                if (
                  shape.getClassName() === 'Line' ||
                  shape.getClassName() === 'Rect'
                ) {
                  shape.strokeWidth(this.calculateShapeStrokeWidth(zoomPoint));
                }
              });
            }
          }
        });
      }
    }
  }

  // on drag circle create new line: before remove
  removeLinesFromShapeGroup(drawBoxId: string, backgroundImgGroup: any) {
    const removeShape = (shape:Line | Rect | Group)=>{
      if (shape.id() === drawBoxId) shape.destroy(); // Remove the line
    }
    this.getShapesFromBackgroundGroupImage(backgroundImgGroup, removeShape)
  }

  removeAllShapesFromShapeGroup(backgroundImgGroup: any){
    const removeShapes = (shape:Line | Rect | Group)=>{
      shape.destroy()
    }
    this.getShapesFromBackgroundGroupImage(backgroundImgGroup, removeShapes)
  }

  getShapesFromBackgroundGroupImage(backgroundImgGroup: any, callback:(shape: Line | Rect | Group)=>void) {
    if (backgroundImgGroup) {
      const shapeAllGroups: any[] =
        this.findAllShapeGroupBySelectedBackgroundGroupImage(
          backgroundImgGroup
        );
      if (shapeAllGroups?.length > 0) {
        shapeAllGroups.forEach((shapeGrp) => {
          // [Group, Group...]
          if (
            shapeGrp.getClassName() === 'Group' &&
            shapeGrp.name() === 'shapeGroup'
          ) {
            // each shapeGroup
            if (shapeGrp.hasChildren()) {
              //[Line,Group]
              shapeGrp.getChildren().forEach((shape: Line | Rect | Group) => {
                if (
                  shape.getClassName() === 'Line' ||
                  shape.getClassName() === 'Rect'
                ) {
                  callback(shape);
                }
              });
            }
          }
        });
      }
    }
  }

  calculateShapeStrokeWidth(isZoomed: number) {
    let strkWidthNew = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKEWIDTH_CLK;
    if (isZoomed >= 1)
      strkWidthNew =
        ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKEWIDTH_CLK / isZoomed;
    return strkWidthNew;
  }
  // -----------------------------------------------------------------------
  // default create polygon:
  getInitDefalutCreatePolygonPoints(backgroundImgGroup: any) {
    const pointerPos: any = backgroundImgGroup.getRelativePointerPosition();
    const x = pointerPos.x;
    const y = pointerPos.y;
    // Define the width and height of the rectangle
    const width = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_WIDTH;
    const height = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_HEIGHT;
    const rectPoints = [
      { x: x, y: y }, // Top-left corner
      { x: x + width, y: y }, // Top-right corner
      { x: x + width, y: y + height }, // Bottom-right corner
      { x: x, y: y + height }, // Bottom-left corner
    ];
    return rectPoints;
  }
  // ---------------------------------------------------------------------------
  //**  ---------------- [RENDER ANNOTATION]  TO THUBNAIL CANVAS----------------  */
  async destroyAllThumbViewStageCanvas() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (targetStage.getAttr('name') === this.viewer_stage_name) {
          targetStage.destroy();
        }
      }
    });
  }

  // 1.Remove thumbnail view rectangle cooridnates before init
  async removeAllThumbnailBox() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (targetStage.getAttr('name') === this.viewer_stage_name) {
          const rectangles = targetStage.find(
            STAGE_2D_CANVAS_COMMON_VIEW.DRAW_RECT
          );
          const lines = targetStage.find(STAGE_2D_CANVAS_COMMON_VIEW.DRAW_LINE);
          if (rectangles?.length > 0)
            rectangles.forEach((rect) => {
              rect.remove();
            });
          if (lines?.length > 0)
            lines.forEach((line) => {
              line.remove();
            });
          targetStage.draw();
        }
      }
    });
  }

  async invokeCurrentThumbnailInstance() {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (targetStage.getAttr('name') === this.viewer_stage_name) {
          targetStage.draw();
        }
      }
    });
  }

  updateThumbnailStage(stageProp: ICanvasStageProperties) {
    Konva.stages.forEach((stage) => {
      const targetStage: Stage = stage;
      if (targetStage) {
        if (targetStage.getAttr('name') === this.viewer_stage_name) {
          if (stageProp.scale && targetStage.id() === stageProp.id && stageProp) {
            targetStage.width(stageProp.width);
            targetStage.height(stageProp.height);
            targetStage.batchDraw();
          }
        }
      }
    });
  }
  
  async renderRemovedAnnotationToThumbnailCanvas(
    thumbnailStageProps: {targetStage: Stage; layer: Layer; bgGroup: null | Group | Shape<ShapeConfig>;},
    drawBoxId: string,
    canvasVisualAnnotationViewerRef: VisualCanvasAnnotationComponent
  ) {
    // Main view - remove annotation:
    const backgroundImgGroup =
      canvasVisualAnnotationViewerRef.backgroundImgGroup; //[Image, Line, Group, Line, Group]
    const drawShapeGroupMain = await this.removeThumbnailAndMainViewBox(drawBoxId, backgroundImgGroup);
    if (drawShapeGroupMain) canvasVisualAnnotationViewerRef.backgroundImgGroup = backgroundImgGroup;
    // thumbnail View - remove annotation:
    if(thumbnailStageProps.bgGroup){
    const drawShapeGroupThumbnail = await this.removeThumbnailAndMainViewBox(drawBoxId, thumbnailStageProps.bgGroup);
    if (drawShapeGroupThumbnail) thumbnailStageProps.targetStage.draw();
    }
  }

  async removeThumbnailAndMainViewBox(drawBoxId: string, backgroundImgGroup:  never[] | Group | Shape<ShapeConfig>) {
    const drawShapGroup = this.currentActiveShapeGroup(
      drawBoxId,
      backgroundImgGroup
    );
    if (drawShapGroup) drawShapGroup.destroy();
    return drawShapGroup;
  }

  // thumbnail viewer property control setting:
  initThumbnailDrawBoxPropertySetup(thumbnailDrawBox: any) {
    if (thumbnailDrawBox) {
      thumbnailDrawBox.setAttr('draggable', false);
      thumbnailDrawBox.stroke(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE);
      thumbnailDrawBox.strokeWidth(1);
    }
    return thumbnailDrawBox;
  }

  shapeGroupIdSetUpByCurrentActiveShapeGroupFromMainView(
    canvasVisualAnnotationViewerRef:  VisualCanvasAnnotationComponent,
    drawBoxId: string
  ) {
    const genIdCreation =
      canvasVisualAnnotationViewerRef?.drawBox?.getAttr('tempId');
    //  main viewer backgroundGroup images:
    const backgroundImgGroup =
      canvasVisualAnnotationViewerRef.backgroundImgGroup; //[Image, Line, Group, Line, Group]
    const drawShapGroupMain = this.currentActiveShapeGroup(
      genIdCreation,
      backgroundImgGroup
    );
    drawShapGroupMain.id(drawBoxId); //remove  genId update To Id
    return drawShapGroupMain;
  }

  // Add Method:
  renderAddPointsToThumbnailCanvas(
    {
      dbCoordinates,
      drawBoxId,
      thumbnailStageProps,
      newAddShapeGroup,
      thumbnailBgGroup
    }:
      {
        dbCoordinates: string[];
        drawBoxId: string;
        thumbnailStageProps: IThumbnailRenderInstance;
        newAddShapeGroup: { setAttr: (arg0: string, arg1: boolean) => void; };
        thumbnailBgGroup: any;
      }) {
    // thumbnail viewer shape group property control:
    newAddShapeGroup.setAttr('draggable', false);
    let thumbnailDrawBox = this.getDrawBoxPropertyByShapeGroup(
      drawBoxId,
      newAddShapeGroup
    );
    // thumbnail viewer property control setting:
    thumbnailDrawBox = this.initThumbnailDrawBoxPropertySetup(thumbnailDrawBox);
    thumbnailDrawBox = this.setScaleAndUpdateDrawBox(
      dbCoordinates,
      thumbnailStageProps,
      thumbnailDrawBox
    );
    if (thumbnailDrawBox) {
      thumbnailBgGroup.add(newAddShapeGroup);
      thumbnailStageProps.targetStage.draw();
    }
    return thumbnailDrawBox;
  }

  // conversion scale 0 1 conversion:
  setScalePointsUpdateToThumnail(dbCoordinates: any, thumbnailStageProps: any) {
    const thumbImageProps = this.getCurrentThumbnailStageBgImage(
      thumbnailStageProps.bgGroup
    );
    // thumbnail 01 conversion >>
    const imgProperties: ICanvasImageProperty = {
      image: thumbImageProps,
      width: thumbImageProps.width(),
      height: thumbImageProps.height(),
    };
    const pointsCoordinate: [number, number][] = dbCoordinates;
    const calculatedPoints: [number, number][] =
      this.getConvertPolygonImageBasedXY(imgProperties, pointsCoordinate);
    // Flatten the nested points into a single array points:
    const singleArPolyPoints: number[] =
      this.getSingleFlatArray(calculatedPoints); //original points
    return singleArPolyPoints;
  }

  getCurrentThumbnailStageBgImage(backgroundGroup: any) {
    const image =
      backgroundGroup
        .getChildren()
        .find((data: any) => data instanceof Konva.Image) || [];
    return image;
  }

  // update Method
  renderUpdatePointsToThumbnailCanvas(
    dbCoordinates: string[],
    drawBoxId: string,
    thumbnailStageProps: IThumbnailRenderInstance
  ) {
    let thumbnailDrawBox = this.getCurrentDrawBoxProperty(
      drawBoxId,
      thumbnailStageProps.bgGroup
    ); // Get Selected/Current Thumbnail DrawBox:
    thumbnailDrawBox = this.setScaleAndUpdateDrawBox(
      dbCoordinates,
      thumbnailStageProps,
      thumbnailDrawBox
    );
    return thumbnailDrawBox;
  }

  setScaleAndUpdateDrawBox(
    dbCoordinates: string[],
    thumbnailStageProps: IThumbnailRenderInstance,
    thumbnailDrawBox: Konva.Line | Line<LineConfig>
  ) {
    const singleArPolyPoints = this.setScalePointsUpdateToThumnail(
      dbCoordinates,
      thumbnailStageProps
    );
    if (thumbnailDrawBox && thumbnailDrawBox.name() === 'poly') {
      // update points to drawBox
      thumbnailDrawBox.points(singleArPolyPoints);
      thumbnailDrawBox.setAttr('newRefPoint', singleArPolyPoints);
      thumbnailStageProps.targetStage.draw();
    } else if (thumbnailDrawBox.name() === 'rect') {
      //not in use (future use)
      const rectangleProperty =
        this.convertPolygonToRectangleCoordinate(singleArPolyPoints);
      if (thumbnailDrawBox) {
        thumbnailDrawBox.height(rectangleProperty.height);
        thumbnailDrawBox.width(rectangleProperty.height);
        thumbnailDrawBox.x(rectangleProperty.x).y(rectangleProperty.y);
        thumbnailStageProps.targetStage.draw();
      }
    }
    return thumbnailDrawBox;
  }

  aiAnnotationMakedAsValidateInThumbnailCanvas(currentSelectedCanvas: any) {
    const thumbnailStageId = currentSelectedCanvas.stageProperty.id;
    const drawBoxId = currentSelectedCanvas.annotationId;
    const thumbnailStageProps =
      this.getCurrentStageBackgroundImageGroup(thumbnailStageId);
    const thumbnailDrawBox = this.getCurrentDrawBoxProperty(
      drawBoxId,
      thumbnailStageProps.bgGroup
    );
    this.aiAnnotationMarkedPrimaryStroke(thumbnailDrawBox);
  }

  centerScalingGroup(
    backgroundImgGroup: any,
    zoomFactorValue: number,
    backgroundImage: any,
    stage: any
  ) {
    backgroundImgGroup.offset({
      x: backgroundImage.width() / 2,
      y: backgroundImage.height() / 2,
    });
    backgroundImgGroup.position({
      x: stage.width() / 2,
      y: stage.height() / 2,
    });
    backgroundImage.draw();
    // Calculate the new scale for the group
    const newScaleX = backgroundImgGroup.scaleX() * zoomFactorValue;
    const newScaleY = backgroundImgGroup.scaleY() * zoomFactorValue;
    // Apply the new scale to the group
    backgroundImgGroup.scaleX(newScaleX);
    backgroundImgGroup.scaleY(newScaleY);
  }

  getMeasurementProperty(
    pointGroup: Konva.Group,
    measurementType: string
  ): IMeasurementDetail[] {
    const getAllMeasurments: IMeasurementDetail[] = [];
    if (pointGroup) {
      const pointGroupChild: (Group | Shape<ShapeConfig>)[] =
        pointGroup.getChildren() || [];
      if (pointGroupChild?.length > 0) {
        pointGroupChild.forEach((group: any) => {
          if (group.getClassName() === 'Group') {
            const groupChild: (Group | Shape<ShapeConfig>)[] =
              group.getChildren() || [];
            if (groupChild?.length > 0) {
              groupChild.forEach((shape: any) => {
                if (
                  shape.getClassName() === 'Text' &&
                  shape.name() === 'tempratureText'
                ) {
                  getAllMeasurments.push({
                    measurementName: shape.getAttr('tempratureLabel'),
                    measurementValue: shape.text(),
                    measurementType,
                  });
                }
              });
            }
          }
        });
      }
    }
    return (
      getAllMeasurments.filter(
        (obj, index, self) =>
          index ===
          self.findIndex((item) => item.measurementName === obj.measurementName)
      ) || []
    );
  }
  // get plusMarker property from canvas group
  getPlusMarkerPropertyBySelectedPointer(pointMarker: Konva.Group) {
    let pointMarkerResp: Group | Shape<ShapeConfig> | undefined;
    const groupChild: (Group | Shape<ShapeConfig>)[] =
      pointMarker.getChildren() || [];
    if (groupChild?.length > 0) {
      groupChild.forEach((shape: any) => {
        if (
          shape.getClassName() === 'Text' &&
          shape.name() === 'tempratureText'
        ) {
          pointMarkerResp = shape;
        }
      });
    }
    return pointMarkerResp;
  }
  // Destory plus marker from delta measurement canvas
  getDestroyMeasurementPropertyByLabelName(
    pointGroup: Konva.Group,
    spotName: string
  ) {
    if (pointGroup) {
      const pointGroupChild: (Group | Shape<ShapeConfig>)[] =
        pointGroup.getChildren() || [];
      if (pointGroupChild?.length > 0) {
        pointGroupChild.forEach((group: any) => {
          if (
            group.getClassName() === 'Group' &&
            group.getAttr('tempratureLabel') === spotName
          ) {
            group.destroyChildren();
          }
        });
      }
    }
  }

  // General :
  updateAiTagCanvas(annotateId: string, payload: IGeometryAnnotationDto) {
    return this.updateAnnotation2d(annotateId, payload).pipe(
      catchError(error => {
        console.error(error);
        this.notificationService.showToast({
          type: EventTypes.error,
          message: error?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
        });
        return of(null); // Return an observable to prevent the error from propagating further
      })
    );
  }

  detectOnVerticePosition(drawBox: Konva.Rect | Konva.Line) {
    const relativePosition = drawBox.getRelativePointerPosition();
    if (relativePosition) {
      const { x, y } = relativePosition;
      const drawBoxPoints = drawBox.getAttr('points');
      const [x1, y1, , , x2, y2] = drawBoxPoints;
      return this.detectCorners(x, x1, x2, y, y1, y2) || this.detectVertices(x, x1, x2, y, y1, y2) || POINTER_POSITION_DRAWBOX.NONE;
    }
    return POINTER_POSITION_DRAWBOX.NONE;
  }

  detectCorners(x: number, x1: number, x2: number, y: number, y1: number, y2: number): string {
    const withinX1Range = x >= x1 - CLICK_THRESHOLD && x <= x1 + CLICK_THRESHOLD;
    const withinX2Range = x >= x2 - CLICK_THRESHOLD && x <= x2 + CLICK_THRESHOLD;
    const withinY1Range = y >= y1 - CLICK_THRESHOLD && y <= y1 + CLICK_THRESHOLD;
    const withinY2Range = y >= y2 - CLICK_THRESHOLD && y <= y2 + CLICK_THRESHOLD;
    return this.withX1Y1Range(withinX1Range, withinY1Range, withinX2Range, withinY2Range)
  }

  withX1Y1Range(withinX1Range: boolean, withinY1Range: boolean, withinX2Range: boolean, withinY2Range: boolean) {
    if (withinX1Range) {
      if (withinY1Range) return POINTER_POSITION_DRAWBOX.TOP_LEFT;
      if (withinY2Range) return POINTER_POSITION_DRAWBOX.BOTTOM_LEFT;
    }
    if (withinX2Range) {
      if (withinY1Range) return POINTER_POSITION_DRAWBOX.TOP_RIGHT;
      if (withinY2Range) return POINTER_POSITION_DRAWBOX.BOTTOM_RIGHT;
    }
    return POINTER_POSITION_DRAWBOX.NONE;
  }

  detectVertices(x: number, x1: number, x2: number, y: number, y1: number, y2: number) {
    if (y >= y1 - CLICK_THRESHOLD && y <= y1 + CLICK_THRESHOLD) return POINTER_POSITION_DRAWBOX.TOP;
    if (y >= y2 - CLICK_THRESHOLD && y <= y2 + CLICK_THRESHOLD) return POINTER_POSITION_DRAWBOX.BOTTOM;
    if (x >= x1 - CLICK_THRESHOLD && x <= x1 + CLICK_THRESHOLD) return POINTER_POSITION_DRAWBOX.LEFT;
    if (x >= x2 - CLICK_THRESHOLD && x <= x2 + CLICK_THRESHOLD) return POINTER_POSITION_DRAWBOX.RIGHT;
    return POINTER_POSITION_DRAWBOX.NONE;
  }

  onDrawBoxMouseMoveHandlerServ(drawBox: Line) {
    const pos = this.detectOnVerticePosition(drawBox);
    switch (pos) {
      case POINTER_POSITION_DRAWBOX.TOP:
      case POINTER_POSITION_DRAWBOX.BOTTOM:
        document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_RESIZE_TOP_BOTTOM;
        break;
      case POINTER_POSITION_DRAWBOX.LEFT:
      case POINTER_POSITION_DRAWBOX.RIGHT:
        document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_RESIZE_LEFT_RIGHT;
        break;
      case POINTER_POSITION_DRAWBOX.TOP_LEFT:
      case POINTER_POSITION_DRAWBOX.BOTTOM_RIGHT:
        document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_RESIZE_TOPLEFT_BOTTOMRIGHT;
        break;
      case POINTER_POSITION_DRAWBOX.TOP_RIGHT:
      case POINTER_POSITION_DRAWBOX.BOTTOM_LEFT:
        document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_RESIZE_TOPRIGHT_BOTTOMLEFT;
        break;
      default:
        document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_MOVE;
    }
  }

  onDrawBoxDragResizeRotateHandlerServ(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line, originalPoints: number[]) {
    const rotationAngle = drawBox.rotation() % 360; // Degrees
    drawBox.rotation(rotationAngle);
    // Calculate the rotated points
    const rotationRad = Konva.Util.degToRad(rotationAngle); // Convert to radians
    const centerX = drawBox.x(); // Assuming the polygon's center is at (x, y)
    const centerY = drawBox.y();
    const scaleX = drawBox.scaleX();
    const scaleY = drawBox.scaleY();
    return originalPoints.reduce((acc: number[], _, index) => {
      if (index % 2 === 0) {
        const x = originalPoints[index] * scaleX + parseFloat(String(e.target.x()));
        const y = originalPoints[index + 1] * scaleY + parseFloat(String(e.target.y()));
        const rotatedX = centerX + (x - centerX) * Math.cos(rotationRad) - (y - centerY) * Math.sin(rotationRad);
        const rotatedY = centerY + (x - centerX) * Math.sin(rotationRad) + (y - centerY) * Math.cos(rotationRad);
        acc.push(rotatedX, rotatedY);
      }
      return acc;
    }, []);
  }

  onDrawBoxDragResizeHandlerServ(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line, originalPoints: number[]) {
    return originalPoints.map((point, index) => {
      const additionalScale = index % 2 === 0 ? e.target.x() : e.target.y();
      const scale = index % 2 === 0 ? drawBox.scaleX() : drawBox.scaleY();
      return point * scale + parseFloat(String(additionalScale));
    })
  }

  updateRectPropertyServ(rect: Rect | Konva.Line, imageObj: Konva.Image) {
    rect.height(rect.height() * rect.scaleY());
    rect.width(rect.width() * rect.scaleX());
    rect.scaleX(1);
    rect.scaleY(1);
    const geometricRectangle: ICanvasRectangleBox = {
      x: rect.x(),
      y: rect.y(),
      width: rect.width(),
      height: rect.height(),
      id: rect.id(),
    };
    // Convert rectangle coordinates to polygon coordinates // ICanvasCooridnates;
    const polygonPoints: number[] = this.convertRectangleToPolygonCoordinate(geometricRectangle);
    const polygonPoints2d: [number, number][] = this.setConvertPolygonImageBasedXY(polygonPoints, imageObj);
    return { polygonPoints2d, geometricRectangle };
  }

  // Create Canvas Thumbnail:
  createVisualStagePropertyByThumbnail(thumbnailResourceRecords: IResourceImageRecord[], originFullResourceRecords: IResourceImageRecord[], compressedFullResourceRecords: IResourceImageRecord[]): IStageResourceRecord[] {
    const imageHorizontalResourceList: IStageResourceRecord[] = [];
    for (const record of thumbnailResourceRecords) {
      const originFullDataValue = originFullResourceRecords.find(elem => elem._id === record.parentResourceId) ?? null;
      const compressedFullUrl = originFullDataValue?._id ? compressedFullResourceRecords?.find(elm => elm.parentResourceId === originFullDataValue._id)?.url : '';
      if(compressedFullUrl && originFullDataValue){
        const resourceStageData: IStageResourceRecord = this.createResourceStageData(record, originFullDataValue, compressedFullUrl);
        this.horizontalImageViewStageSet(resourceStageData, imageHorizontalResourceList);
      } 
    }
    return imageHorizontalResourceList;
  }

  createResourceStageData(record: IResourceImageRecord, originFullDataValue: IResourceImageRecord, compressedFullUrl: string): IStageResourceRecord {
    return {
      _id: record._id,
      thumbUrl: record.url,
      dataResourceId: originFullDataValue?.dataResourceId ?? "",
      type: record.resourceTag,
      originUrl: originFullDataValue?.url ?? "",
      compressedUrl: compressedFullUrl ?? (originFullDataValue?.url as string),
      isCompressed: !!compressedFullUrl,
      originFulId: originFullDataValue?._id ?? "",
      gpsLatitude: originFullDataValue?.gpsLatitude ?? "",
      gpsLongitude: record?.gpsLongitude ?? "",
      updatedAt: originFullDataValue?.updatedAt ?? "",
      fileName: originFullDataValue?.fileName ?? "",
      originRecord: originFullDataValue?.record,
      stageProperty: null,
      annotationRecords: [],
      isAnnotated: false,
      imageName: record.imageName || '',
      thumbnailTags: originFullDataValue?.thumbnailTags ?? [],
      isReviewed: originFullDataValue?.isReviewed,
      isLoaded: false,
      validate: false,
      showAll: true,
      isThumbnailMode: true,
      diskCache: false,
      actualTag: 'showAll',
      displayTag: 'showAll',
      showTag: true,
    };
  }

  horizontalImageViewStageSet(record: IStageResourceRecord, imageHorizontalResourceList: IStageResourceRecord[]) {
    const stageProperty = {
      id: THUMB_STAGE_HORIZONTAL_VIEW._PREFIX + record.dataResourceId,
      width: THUMB_STAGE_HORIZONTAL_VIEW.WIDTH,
      height: THUMB_STAGE_HORIZONTAL_VIEW.HEIGHT,
    };
    const cloneRecordData = { ...record, thumbnailView: THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW, stageProperty };
    imageHorizontalResourceList.push(cloneRecordData);
  }

  handleWheelZoom(event: { evt: { preventDefault: () => void; deltaY: number } }, stage: Konva.Stage) {
    const scaleBy = 1.05;
    event.evt.preventDefault();
    const oldScale = stage.scaleX();
    const pointer = {
      x: stage.getPointerPosition()?.x,
      y: stage.getPointerPosition()?.y
    };
    const mousePointTo = {
      x: (Number(pointer.x) - stage.x()) / oldScale,
      y: (Number(pointer.y) - stage.y()) / oldScale,
    };
    let newScale = event.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    // Ensure the new scale is within the specified range (0 to 1000%)
    newScale = Math.max(0, Math.min(newScale, STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_IN_MAX_LEVEL));
    if (newScale !== oldScale) {
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: Number(pointer.x) - mousePointTo.x * newScale,
        y: Number(pointer.y) - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();

    }
  }

  createStageAndLayerForThumb(stageProperty: ICanvasStageProperties, viewer_stage_name: string) {
    const containerElement = document.getElementById(stageProperty.id);
    if (containerElement) {
      // Create a stage
      const stage = new Konva.Stage({
        container: stageProperty.id,
        width: stageProperty.width,
        height: stageProperty.height,
      });
      // Set attributes
      stage.setAttr('id', stageProperty.id);
      stage.setAttr('name', viewer_stage_name);
      // Create a layer
      const layer = new Konva.Layer();
      return { stage, layer };
    } else {
      return null; // Container element not found
    }
  }

  setBackgroundImageAsThumbnail(canvasInstance: IThumbnailInstance, imageUrl: string, thumbnailView: string, thumbnailMode: boolean): Promise<{ canvasInstance: IThumbnailInstance, thumbBgImageProperties: any }> {
    return new Promise((resolve, reject) => {
      let stage = canvasInstance.stage;
      let layer = canvasInstance.layer;
      // Create an image object
      let imageObj = new Image();
      imageObj.src = imageUrl;
      // Once the image is loaded, set it as the background image
      imageObj.onload = () => {
        let aspectRatio = imageObj.width / imageObj.height;
        let stageHeight = stage.width() / aspectRatio;
        stage.height(stageHeight);
        // Calculate the image size to fit the stage while maintaining aspect ratio
        let thumbBgImageProperties = {
          image: imageObj,
          width: stage.width(),
          height: stageHeight,
          opacity: thumbnailView === THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW
            ? ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY_INIT
            : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY, // Start with opacity set to 0 for animated layer
        };
        // Create a background image
        let thumbBgImage = new Konva.Image(thumbBgImageProperties);
        let bgImgGroupInstance = new Konva.Group({
          draggable: !thumbnailMode,
        });
        if (bgImgGroupInstance) {
          bgImgGroupInstance.add(thumbBgImage);
          layer.add(bgImgGroupInstance);
          stage.add(layer);
          stage.draw();
          canvasInstance['bgGroup'] = bgImgGroupInstance;

          // Animated shown Image
          if (thumbnailView !== THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW) {
            this.getFadeInBgThumbnail(thumbBgImage);
          }
          resolve({ canvasInstance, thumbBgImageProperties });
        } else {
          reject(new Error("Failed to create background image group"));
        }
      };
      // Error handling for image loading failure
      imageObj.onerror = (error) => {
        reject(error);
      };
    });
  }

  getTransformerRotateValue(shapeName: string, drawBox: Konva.Line, controlActionClicked: Annotation2DControlAction, ACTION_ROTATE: Annotation2DControlAction, ACTION_RESIZE: Annotation2DControlAction,) {
    let transformer;
    if (shapeName === ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME) {
      transformer = new Konva.Transformer({
        nodes: [drawBox],
        rotateEnabled: false
      });
    } else if (shapeName === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME) {
      transformer = new Konva.Transformer({
        nodes: [drawBox],
        enabledAnchors: [TRANSFORM.TOP_LEFT, TRANSFORM.TOP_RIGHT, TRANSFORM.BOTTOM_LEFT, TRANSFORM.BOTTOM_RIGHT, TRANSFORM.MIDDLE_LEFT, TRANSFORM.MIDDLE_RIGHT, TRANSFORM.TOP_CENTER, TRANSFORM.BOTTOM_CENTER],
        keepRatio: false,
        rotateEnabled: controlActionClicked === ACTION_ROTATE
      });
    }
    return transformer
  }


  onSelectDrawBoxRotateOrResizeShape(transformer: Konva.Transformer | null, currentClicked: Annotation2DControlAction) {
    // if transform applied remove
    if (transformer) {
      transformer.destroy();
      transformer = null;
    }
    return currentClicked;
  }

  drawPolygon(stage: Konva.Stage, layer: Konva.Layer, backgroundImgGroup: Konva.Group, points: number[]) {
    let isZoomed = stage.scaleX();
    const polygon = new Konva.Line({
      points: points,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
      stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
      strokeWidth: (isZoomed >= 1) ? this.calculateStrokeWidth(stage) : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      draggable: false,
      name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME_TEMP,
    });
    backgroundImgGroup.add(polygon);
    layer.add(backgroundImgGroup);
    stage.add(layer);
    layer.batchDraw();
    return polygon;
  }

  calculateStrokeWidth(stage: Konva.Stage) {
    let isZoomed = stage.scaleX();
    let strkWidthNew = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKEWIDTH_CLK;
    if (isZoomed >= 1) strkWidthNew = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKEWIDTH_CLK / isZoomed;
    return strkWidthNew;
  }

  calculateCircleRadius(stage: Konva.Stage) {
    let isZoomed = stage.scaleX();
    let circleRadius = ANNOTATION_DRAWBOX_INIT_PROPERTY.RADIUS;
    if (isZoomed >= 1) circleRadius = ANNOTATION_DRAWBOX_INIT_PROPERTY.RADIUS / isZoomed;
    return circleRadius;
  }

  updateVerifyToDataResourceList(imageRecord :IStageResourceRecord[], annotatedIds: string[],value: boolean) {
    return imageRecord.map((record: { _id: any; annotationRecords: IGeometryAnnotationDto[]; })=>( annotatedIds.includes(record._id) ? {
        ...record,
        verify_checked: value,
        verify_removed: !value,
        annotationRecords : record.annotationRecords.map((record: IGeometryAnnotationDto)=>({
          ...record,
          tags : value ? [...(record.tags || []), STAGE_2D_CANVAS_COMMON_VIEW.VALIDATE_TAG] : [STAGE_2D_CANVAS_COMMON_VIEW.MANUAL_TAG]
        }))
      } : {...record}));
    }
    
}
