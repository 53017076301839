import { Component, EventEmitter, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ISceneDefinitionDocumentVersionRecord } from 'projects/digital-twin/src/app/interfaces/api-response.interface';
import { CanvasDataService } from 'projects/digital-twin/src/app/services/canvas-data.service';
@Component({
  selector: 'app-version-info-list',
  templateUrl: './version-info-list.component.html',
  styleUrls: ['./version-info-list.component.scss']
})
export class VersionInfoListComponent {
  /* #region variable initialization/declaration */
  sceneDefDocVersionList: ISceneDefinitionDocumentVersionRecord[] = [];
  currentSceneDefinitionDoc!: ISceneDefinitionDocumentVersionRecord;
  currentVersionObject: any;
  /* #endregion variable initialization/declaration */

  /* #region input/ouput/viewChild decorators */
  @Output() closeVersionList = new EventEmitter();
  @Output() currentSceneDefinitionDocId = new EventEmitter();
  private unsubscribeSceneDefDocVersionList: Subject<void> = new Subject<void>();
  private unsubscribeSceneDef: Subject<void> = new Subject<void>();
  /* #endregion input/ouput/viewChild decorators */

  constructor(
    private canvasDataService: CanvasDataService
  ) {
    this.canvasDataService.sceneDefDocVersionListData$.pipe(takeUntil(this.unsubscribeSceneDefDocVersionList)).subscribe({
      next: (versionList) => {
        this.sceneDefDocVersionList = [...versionList];
      },
      error: (err: any) => { console.error(err); }
    });
    this.canvasDataService.sceneDefinitionData$.pipe(takeUntil(this.unsubscribeSceneDef)).subscribe({
      next: (data) => {
        this.currentSceneDefinitionDoc = { ...data };
        this.currentVersionObject = { sceneDefinitionDocId: data?._id, sceneDefinitionDocName: data?.name }
      },
      error: (err: any) => { console.error(err); }
    });
  }

  ngOnDestroy() {
    this.unsubscribeSceneDefDocVersionList.next();
    this.unsubscribeSceneDef.next();
    this.unsubscribeSceneDefDocVersionList.complete();
    this.unsubscribeSceneDef.complete();
  }

  // version selection box
  getSelectedVersionItem(event: any) {
    let message: string = 'You have unsaved changes. Are you sure you want to leave?';
    const initialSceneDoc = { ...this.canvasDataService.initialSceneDefinitionDocument };
    if (this.currentVersionObject?.sceneDefinitionDocId !== event?._id) {
      if (this.currentSceneDefinitionDoc && initialSceneDoc) {
        if (JSON.stringify(this.currentSceneDefinitionDoc) !== JSON.stringify(initialSceneDoc)) {
          if (confirm(message) === true) {
            this.onChangeVersion(event);
          }
        } else {
          this.onChangeVersion(event);
        }
      }
    }
  }

  onCloseVersionList() {
    this.closeVersionList.emit(true);
  }

  onChangeVersion(event: ISceneDefinitionDocumentVersionRecord) {
    this.currentVersionObject = { sceneDefinitionDocId: event?._id, sceneDefinitionDocName: event?.name };
    this.currentSceneDefinitionDocId.emit(event?._id);
    this.onCloseVersionList();
  }
}
