export interface ITemplateField {
  formControlName: string;
  label: string;
  placeholder: string;
  dropdown?: boolean;
  required: boolean;
  dropdownValues?: string[];
};

export type ITemplateFields = ITemplateField[];

export interface ITemplateResponseData {
  templateName: string;
  industry: string;
  typeOfFindings: string;
  selection: string;
  severity: string;
}