import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AMPLITEL_CUSTOMER_PORTAL,
  CANVAS_COMPONENT,
  CANVAS_PANELS,
  COMPONENT_ASSOCIATION_STATUS_COMPARE,
  COMPONENT_EQUIPMENT_TYPES,
  USER_ROLE_PERMISSIONS,
  VERSION_SELECTOR,
} from 'projects/digital-twin/src/app/constants';
import {
  IComponentRecord,
  IEquipmentSegmentation,
  INewSceneObject,
  ISceneDefinitionDocumentSingleVersionResponse,
  ISceneObject,
  ISegmentationAccordionList,
  ISegmentationProperties,
} from 'projects/digital-twin/src/app/interfaces';
import { IUserRolesAndPermissions } from 'projects/digital-twin/src/app/interfaces/permission.interface';
import {
  CanvasDataService,
  CommonService,
  IframeViewerService,
  PermissionService,
} from 'projects/digital-twin/src/app/services';
import { UserSettingsService } from 'projects/digital-twin/src/app/shared/services';
import { Subject, Subscription, combineLatest, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-annotation-tab',
  templateUrl: './annotation-tab.component.html',
  styleUrls: ['./annotation-tab.component.scss'],
})
export class AnnotationTabComponent {
  @Input() isIframeMode: boolean;
  @Output() openOrCloseComponentPanelTrigger = new EventEmitter();
  @Output() saveButtonState = new EventEmitter();
  filterSegmentationList: ISegmentationAccordionList[] = [];
  isViewVersionPermission = false;
  userRolePermissions: IUserRolesAndPermissions = USER_ROLE_PERMISSIONS;
  isCommonPermissionAvailable = false;
  versionsList: string[] = [];
  selectedSegmentId: string = '';
  componentList: IComponentRecord[] = [];
  mappedSegmentationList: ISegmentationAccordionList[] = [];
  loadedSegementationList = false;
  private unsubscribeActiveSceneObject: Subject<void> = new Subject<void>();
  accordionShowIndex = -1;
  currentSceneDefinitionDoc: ISceneDefinitionDocumentSingleVersionResponse;
  updateButtonDisableStatus = true;
  aiProposedComponent: INewSceneObject[] = [];
  aiProposedComponentGroup: ISegmentationAccordionList;
  aiProposedComponentGroupFilter: ISegmentationAccordionList;
  openingAccordionName: string;

  private combinedSubscription: Subscription;

  constructor(
    private canvasDataService: CanvasDataService,
    private commonService: CommonService,
    private permissionService: PermissionService,
    private userSettingsService: UserSettingsService,
    private iframeViewerService: IframeViewerService
  ) {
    this.isViewVersionPermission = this.permissionService.hasPermissions([
      this.userRolePermissions.CANVAS_SCENE_DEFINITION_VIEW_VERSIONS,
    ]);
    this.isCommonPermissionAvailable = this.permissionService.hasPermissions([
      this.userRolePermissions.CANVAS_3DSEGMENTCOMPONENT_ASSOCIATE,
      this.userRolePermissions.CANVAS_3DSEGMENTCOMPONENT_DISASSOCIATE,
      this.userRolePermissions.CANVAS_2DANNOTATION_ANNOTATION_CREATE,
      this.userRolePermissions.CANVAS_2DANNOTATION_ANNOTATION_DELETE,
      this.userRolePermissions.CANVAS_3DSEGMENTATION_CREATE,
      this.userRolePermissions.CANVAS_3DSEGMENTATION_EDIT,
      this.userRolePermissions.CANVAS_3DSEGMENTATION_DELETE,
      this.userRolePermissions.CANVAS_SCENE_DEFINITION_CREATE_PROPERTIES,
      this.userRolePermissions.CANVAS_SCENE_DEFINITION_UPDATE_PROPERTIES,
      this.userRolePermissions.CANVAS_SCENE_DEFINITION_DELETE_PROPERTIES,
    ]);
    if (this.isCommonPermissionAvailable)
      this.versionsList.push(VERSION_SELECTOR.SAVE_NEW_VERSION);
    if (this.isViewVersionPermission)
      this.versionsList.push(VERSION_SELECTOR.VIEW_VERSIONS);
    }

  ngAfterViewInit() {
    this.combineLatestSubscription();
  }
  ngOnDestroy() {
    if (this.combinedSubscription) this.combinedSubscription.unsubscribe();
    this.unsubscribeActiveSceneObject.complete();
  }
  private combineLatestSubscription() {
    this.combinedSubscription = combineLatest([
      this.canvasDataService.componentListData$,
      this.canvasDataService.sceneDefinitionData$,
      this.canvasDataService.telcoEquipmentBboxEstimationObservable$,
      this.canvasDataService.boundingBoxFilters$,
    ])
      .pipe(filter(([...values]) => values.every((value) => value !== null)))
      .subscribe(([componentList, sceneDefDoc, aiProposedComponent, filterStatus]) => {
        if (!this.canvasDataService.activeSceneObject?._id)
          this.selectedSegmentId = '';
        this.accordionShowIndex = -1;
        this.componentList = [...componentList];
        //@ts-ignore
        this.aiProposedComponent = aiProposedComponent;
        this.currentSceneDefinitionDoc = { ...sceneDefDoc };
        const initialSceneDoc = {
          ...this.canvasDataService.initialSceneDefinitionDocument,
        };
        if (this.currentSceneDefinitionDoc && initialSceneDoc) {
          let stringifiedInitialSceneDoc: string =
            JSON.stringify(initialSceneDoc);
          let stringifiedSceneDoc: string = JSON.stringify(
            this.currentSceneDefinitionDoc,
          );
          this.updateButtonDisableStatus = !(
            stringifiedSceneDoc !== stringifiedInitialSceneDoc &&
            this.currentSceneDefinitionDoc._id
          );
          this.saveButtonState.emit(this.updateButtonDisableStatus);
        }
        this.dynamicAccordionGenerationBySceneDefDoc({ ...sceneDefDoc });
        this.accordionGenerateAiProposedComponent();
        if (filterStatus) this.onFilterApply(filterStatus);
      });
  }

  accordionGenerateAiProposedComponent() {
    // create child properties from ai proposed component
    const childProperties = this.aiProposedComponent.map((component, index) => {
      return {
        segmentId: component.id,
        componentId: component.id,
        component_reference: component.id,
        id: component.id,
        name: component['name'],
        component,
        visible: this.userSettingsService.getComponentVisibility(component.id),
      };
    });
    // Accordion group
    this.aiProposedComponentGroup = {
      accId: 'proposed',
      accLabel: 'Proposed',
      accTargetName: `accTrt-comp-proposed`,
      //@ts-ignore
      childrenProperties: childProperties,
      childrenPropertyCount: childProperties.length,
      visible: this.userSettingsService.getComponentVisibility('proposed'),
    };
    this.aiProposedComponentGroupFilter = { ...this.aiProposedComponentGroup };
  }

  private dynamicAccordionGenerationBySceneDefDoc = (
    sceneObj: ISceneDefinitionDocumentSingleVersionResponse,
  ) => {
    const iframeAuthToken = this.iframeViewerService.authToken;
    let openingAccordionName: string = '';
    const sceneObjectList: ISceneObject[] = sceneObj['sceneObjects'] || [];
    // Check if scene property update permission is available
    const isUpdatePermissionAvailable: boolean =
      this.permissionService.hasPermissions([
        this.userRolePermissions.CANVAS_SCENE_DEFINITION_UPDATE_PROPERTIES,
      ]);
    // Create a filtered list of scene objects based on permission availability
    let finalSceneObjectList: ISceneObject[] = isUpdatePermissionAvailable
      ? [...sceneObjectList]
      : sceneObjectList?.filter(
          (ele) => !ele['properties']['telco_tower_landmark'],
        ) ?? [];
    if (this.isIframeMode) {
      finalSceneObjectList = finalSceneObjectList.filter(
        (component) =>
          !component['status'] ||
          this.commonService.digitalTwinSettings[component['status']]?.isRender,
      );
    }
        
    const groupedComponentList = finalSceneObjectList.reduce(
      (result: IEquipmentSegmentation, obj) => {
        const {
          dims_order,
          telco_equipment_type,
          telco_tower_landmark,
          confidence,
          telco_equipment_reference_point,
          telco_equipment_reference_vector,
          annotation_2d_references,
          component_reference,
        } = obj.properties;
        // Check if the current object is a tower landmark
        const isTowerLandMarkObj = !!obj['properties']['telco_tower_landmark'];
        // Check if the current object is a measurement
        const isMeasurementObj =
          obj['properties']['telco_equipment_type'] ===
          COMPONENT_EQUIPMENT_TYPES.MEASUREMENT;
        // Determine the equipment type based on the object's properties
        const equipmentType = isTowerLandMarkObj
          ? CANVAS_COMPONENT.SCENEOBJ_COMPONENT_REFERENCE_TYPE.toLowerCase()
          : obj['properties']['telco_equipment_type'] ?? '';
        // Create an entry in the result for the equipment type if it doesn't exist
        const sceneGroupVisibilty =
          this.userSettingsService.getComponentVisibility(
            obj['properties']['telco_equipment_type'] ?? '',
          );
        result[equipmentType] = result[equipmentType] || {
          accId:
            obj['properties']['telco_equipment_type'] ??
            obj['properties']['telco_tower_landmark'],
          accLabel:
            CANVAS_COMPONENT.EQUIPMENT_SEGMENTATION_TYPE[equipmentType] ||
            equipmentType,
          accTargetName: `accTrt-comp-${equipmentType}`,
          childrenProperties: [],
          visible: sceneGroupVisibilty,
        };
        // Check if the current object is the selected segment and set the openingAccordionName
        if (this.selectedSegmentId === obj?.id)
          openingAccordionName = equipmentType;
        // Calculate the length of children properties
        const childrenPropertiesLength =
          result[equipmentType].childrenProperties.length;
        // Update group component count by type
        this.canvasDataService.updateGroupCompCountByType(
          equipmentType,
          childrenPropertiesLength,
        );
        // Find the filtered component object for the current scene object
        const filteredComponentObject = this.componentList?.find(
          (item) => item?._id === obj['properties']['component_reference'],
        );
        // Push information about the current scene object into the childrenProperties of the equipment type
        const sceneObjectVisibility =
          this.userSettingsService.getComponentVisibility(obj.id);
        const objToPush: ISegmentationProperties = {
          segmentId: obj?.id,
          componentId: obj['properties']['component_reference'] ?? '',
          name:
            obj['properties']['component_reference'] && filteredComponentObject
              ? filteredComponentObject?.externalClientComponentId ||
                (obj['name'] ?? '')
              : obj['name'] ?? `Segmentation ${childrenPropertiesLength + 1}`,
          value: obj['properties']['confidence']
            ? Math.round(
                obj['properties']['confidence'] *
                  CANVAS_COMPONENT.CONFIDENCE_ROUNDER,
              )
            : 0,
          isTowerLandMarkObj,
          isMeasurementObj,
          component: filteredComponentObject ?? {
            _id: '',
            assetId: '',
            workspaceId: '',
            componentName: '',
            componentType: '',
            xPosition: '',
            yPosition: '',
            zPosition: '',
            installationDate: '',
            externalClientSiteId: '',
            externalClientAssetId: '',
            externalClientComponentId: '',
            isMigratedData: '',
            createdAt: '',
            updatedAt: '',
            componentStatus: this.commonService.showUncategorized()
              ? COMPONENT_ASSOCIATION_STATUS_COMPARE.UNCATEGORISED
              : COMPONENT_ASSOCIATION_STATUS_COMPARE.ORPHANED,
          },
          hasReferencePoint:
            (obj.properties?.telco_equipment_reference_point ?? []).length > 0,
          hasReferenceVector:
            (obj.properties?.telco_equipment_reference_vector ?? []).length > 0,
          visible: sceneObjectVisibility,
          properties: {
            dims_order,
            telco_equipment_type: telco_equipment_type ?? '',
            telco_tower_landmark: telco_tower_landmark ?? '',
            component_reference: component_reference ?? '',
            confidence: confidence ?? 0,
            telco_equipment_reference_point:
              telco_equipment_reference_point ?? [],
            telco_equipment_reference_vector:
              telco_equipment_reference_vector ?? [],
            annotation_2d_references,
          },
        };
        // Renaming annotations to Structure for customer portal
        if (this.iframeViewerService.isCustomerPortal(iframeAuthToken)) {
          const prefix = this.iframeViewerService.getSceneObjectPrefixForCustomerPortal();
          const regex = new RegExp(AMPLITEL_CUSTOMER_PORTAL.DEFAULT_PREFIX, 'i'); // 'i' flag makes the regex case-insensitive
          obj.name = obj.name ? obj.name.replace(regex, prefix) : obj.name;
        }
        result[equipmentType].childrenProperties.push(objToPush);
        // Update the count of children properties for the equipment type
        result[equipmentType].childrenPropertyCount =
          result[equipmentType].childrenProperties.length;
        result[equipmentType].visible = result[
          equipmentType
        ].childrenProperties.some((x: ISegmentationProperties) => x.visible);
        return result;
      },
      {},
    );
    this.canvasDataService.updateGroupCompByType(groupedComponentList);
    // Set the accordionShowIndex based on the openingAccordionName
    Object.keys(groupedComponentList).forEach((key: any, index) => {
      if (key === openingAccordionName) this.accordionShowIndex = index;
    });
    this.mappedSegmentationList = Object.values(groupedComponentList);
    this.filterSegmentationList = JSON.parse(
      JSON.stringify(this.mappedSegmentationList),
    );
    this.loadedSegementationList = true;
    this.activeSceneObjectSubscribe(this.mappedSegmentationList);
  };


  private activeSceneObjectSubscribe(
    groupedComponentList: ISegmentationAccordionList[] = [],
  ) {
    this.canvasDataService.activeSceneObject$
      .pipe(takeUntil(this.unsubscribeActiveSceneObject))
      .subscribe({
        next: (sceneObj) => {
          if (sceneObj?.id) {
            this.selectedSegmentId = sceneObj?.id;
            let openingAccordionName: string =
              sceneObj?.properties?.telco_equipment_type || '';
            Object.values(groupedComponentList).forEach((val, index) => {
              if (val.accId === openingAccordionName) { 
                this.accordionShowIndex = index;
              }
            });
            const component = this.aiProposedComponent.find((component) => component.id === sceneObj.id)
            if (component) {
              this.accordionShowIndex = groupedComponentList.length;
            }
          } else {
            this.selectedSegmentId = '';
            this.accordionShowIndex = -1;
          }
        },
        error: (err: any) => {
          console.error(err);
        },
      });
  }

  toggleComponentVisibility(
    component: ISegmentationAccordionList | ISegmentationProperties,
  ) {
    component.visible = !component.visible;
    type ComponentType = ISegmentationAccordionList | ISegmentationProperties;
    function isAccordion(
      component: ComponentType,
    ): component is ISegmentationAccordionList {
      return (<ISegmentationAccordionList>component).accId !== undefined;
    }
    if (isAccordion(component)) {
      this.toggleComponentGroupVisibility(
        component.childrenProperties,
        component.visible,
      );
    } else {
      this.emitComponentToggleChanges(component, component.visible);
    }
  }

  toggleComponentGroupVisibility(
    components: ISegmentationProperties[],
    isVisible: boolean,
  ) {
    components.forEach((component) => {
      component.visible = isVisible;
      this.emitComponentToggleChanges(component, isVisible);
    });
  }

  emitComponentToggleChanges(
    component: ISegmentationProperties,
    isVisible: boolean,
  ) {
    const selectedSceneObject = [
      ...this.currentSceneDefinitionDoc.sceneObjects,
      ...this.aiProposedComponentGroup.childrenProperties,
      //@ts-ignore
    ].filter((x) => x.id === component.segmentId)?.[0];
    if (!selectedSceneObject) return;
    this.userSettingsService.toggleComponentVisibility(
      component.segmentId,
      isVisible,
    );
    this.canvasDataService.toggleSceneObject$.next({
      //@ts-ignore
      selectedSceneObjectId: selectedSceneObject.id,
      isVisible,
    });
  }

  showOrHideAccordion(indexValue: number = 0) {
    this.accordionShowIndex =
      this.accordionShowIndex === indexValue ? -1 : indexValue;
  }

  openAssociation(segmentationData: ISegmentationProperties) {
   this.toOpenAssociatePanel(segmentationData ,false);
  }

  toOpenAssociatePanel(segmentationData: ISegmentationProperties, isOpenVisualGallery = false){
    this.canvasDataService.isImageGalleryOpen = isOpenVisualGallery;
    this.canvasDataService.showFilterPanelSignal.set(false);
    const hasPermission: boolean = this.permissionService.hasPermissions([
      this.userRolePermissions.CANVAS_3DSEGMENTATION_VIEW,
    ]);
    if (hasPermission) {
      this.selectedSegmentId = segmentationData?.segmentId || '';
      let selectedSceneObject =
        this.currentSceneDefinitionDoc.sceneObjects.find(
          ({ id }) => id === this.selectedSegmentId,
        );
        selectedSceneObject  = { ...selectedSceneObject } as ISceneObject;
        this.canvasDataService.setActiveSceneObject(selectedSceneObject);
    }
    if (!segmentationData?.isTowerLandMarkObj)
      this.openOrCloseComponentPanelTrigger.emit(
        segmentationData?.componentId
          ? CANVAS_PANELS?.COMPONENT_DETIALS
          : CANVAS_PANELS?.COMPONENT_ASSOCIATION,
    );
    if (segmentationData?.isMeasurementObj) this.onEditMeasurementClick(segmentationData.segmentId);
  }

  openAssociationAiProposed(segmentationData: ISegmentationProperties) {
    this.canvasDataService.showFilterPanelSignal.set(false);
    const hasPermission: boolean = this.permissionService.hasPermissions([
      this.userRolePermissions.CANVAS_3DSEGMENTATION_VIEW,
    ]);
    if (hasPermission) {
      let selectedSceneObject = this.aiProposedComponent.find(
        (x) => x.id === segmentationData?.segmentId,
      );
      selectedSceneObject  = { ...selectedSceneObject , isOpenVisualGallery: false } as ISceneObject;
      this.canvasDataService.setActiveSceneObject(selectedSceneObject!);
    }
    if (!segmentationData?.isTowerLandMarkObj)
      this.openOrCloseComponentPanelTrigger.emit(
        segmentationData?.componentId
          ? CANVAS_PANELS?.COMPONENT_DETIALS
          : CANVAS_PANELS?.COMPONENT_ASSOCIATION,
    );
  }

  closeAssociation(){
    this.openOrCloseComponentPanelTrigger.emit();
  }

  openImageGalary(segmentationData: ISegmentationProperties) {
    //open image gallery by using this function
   this.toOpenAssociatePanel(segmentationData, true);
  }

  async onEditMeasurementClick(measurementId: string) {
    const measurement = await this.canvasDataService.getSceneObjectById(measurementId, 'id');
    this.canvasDataService.setSelectedMeasurement(measurement);
  }
  onDeleteMeasurementClick(measurementId: string) {
    this.canvasDataService.deleteMeasurement(measurementId);
  }

  onFilterApply(filteredStatus: string[]) {
    if (this.mappedSegmentationList.length) {
      this.filterSegmentationList = JSON.parse(JSON.stringify(this.mappedSegmentationList));
      for (const element of this.filterSegmentationList) {
        if (element.childrenProperties[0].isTowerLandMarkObj || element.childrenProperties[0].isMeasurementObj) continue;
        element.childrenProperties = element.childrenProperties.filter(property => (property.component && filteredStatus.includes(property.component.componentStatus)))
        element.childrenPropertyCount = element.childrenProperties.length;
      }
    }
  }
}
